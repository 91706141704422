import React, { useEffect, useState, useRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useMediaQuery } from 'react-responsive'
import { Routes, Route, useNavigate, useParams } from "react-router-dom";
import { Button, TextField, Autocomplete, Box, IconButton, Paper } from '@mui/material';
import { sk } from './globals.js';
import { Debug, PlusMinus, ShowOK, Sections, Loading, SearchTextfield, AgentChoose } from './items.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp, faCircleCheck, faEdit, faFolderTree, faHeart, faHome, faPlus, faRotateLeft, faSearch, faShoppingCart, faTurnUp, faXmark, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme2-6.css";
import { styles } from './styles.js';
import { Product } from './product.js';
import { BookingOrder } from './booking_order.js';
import Pagination from '@mui/material/Pagination';

export const Booking = (props) => {
    //const navigate = useNavigate();

    const [isBusy, setBusy] = useState(false);
    const [showShoppingcard, setShowShoppingcard] = useState(false);
    const [items, setItems] = useState([]);
    const [itemsFiltered, setItemsFiltered] = useState([]);
    const [redraw, setRedraw] = useState(false);
    const [showNew, setShowNew] = useState(false);
    const [error, setError] = useState('');
    const [showOK, setShowOK] = useState(false);
    const [showEnabled, setShowEnabled] = useState(false); // zobrazovat len aktívne

    // Detail polozky
    const [selectedItem, setSelectedItem] = useState([]);
    const [showSelected, setShowSelected] = useState(false);

    // SEARCH
    const [search, setSearch] = useState('');
    //const [searchArr, setSearchArr] = useState([]);

    // PAGINATION
    const [currentPage, setCurrentPage] = useState(1);
    const [pageMax, setPageMax] = useState(0);

    // NAZVY SEKCII
    const [sectionID, setSectionID] = useState(0);
    const [sections, setSections] = useState([[], [], []]);
    const [section4, setSection4] = useState('');
    const [section5, setSection5] = useState('');

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });
    /*
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    */

    const boxWidth = global.max_width;
    const boxHeight = 100;

    var lang = props.language;
    let params = useParams();
    const homeRef = useRef(null)

    //const logo = require('./react/app/logo.png');

    useEffect(() => {

        db_items();

        return () => {
        };

    }, []);

    const db_items = async () => {
        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + 'booking', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    language: props.language.lang_id,
                    salon_id: props.user.id
                })
            })

            const json = await response.json();

            if (json.ok > 0) {

                // zoznam produktov
                var tmp = json.products;

                // sort
                tmp.sort((a, b) => {
                    if (a.label < b.label) {
                        return -1;
                    } else {
                        return 1;
                    }
                })
                // reindexing
                tmp = tmp.map(function (item, index) {
                    item.index = index;
                    return item;
                });

                setItems(tmp);
                setItemsFiltered(tmp);

                // SEKCIE
                setSections([json.section1, json.section2, json.section3]);
                setSection4(lang.section_cabin);
                setSection5(lang.section_action);

                setCurrentPage(1);
                setSectionID(0);
                Redraw(tmp, [json.section1, json.section2, json.section3], 0, 1, '');
                GoHome();
            }
            setBusy(false);

        } catch (error) {
            Debug(error);
            setBusy(false);
        }
    }

    const db_booking_update = async (id, quantity, cost) => {

        var data = {
            salon_id: props.user.id,
            product_id: id,
            quantity: quantity,
            sended: false,
            booking_id: 0,
            quantity_original: quantity,
            cost: cost
        }

        try {
            const response = await fetch(
                global.db_url + 'booking_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data: data
                })
            })

            const json = await response.json();            

            if (json.ok > 0) {
            }
        } catch (error) {
            Debug(error);
        }
    }


    const db_favourites_update = async (id, status) => {

        var data = {
            salon_id: props.user.id,
            product_id: id,
        }

        try {
            const response = await fetch(
                global.db_url + 'favourites_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data: data,
                    add: status
                })
            })

            const json = await response.json();            

            if (json.ok > 0) {
            }
        } catch (error) {
            Debug(error);
        }
    }


    const PageChange = (event, page) => {
        // PAGINATION
        setCurrentPage(page);
        Redraw(items, sections, sectionID, page, search);
        GoHome();
    };


    const ShowOKPress = (value) => {
        setShowOK(false);
    }

    const Reload = () => {
        db_items();
    }

    const PlusMinusPress = (id, quantity) => {

        var tmp = items.find(x => x.id == id);
        if (tmp != '') {
            tmp.quantity = quantity;
        }
        db_booking_update(id, quantity, tmp.cost_price);
    }


    const SectionsResult = (section_) => {
        setSectionID(section_);
        setCurrentPage(1);
        Redraw(items, sections, section_, 1, search);
    }


    const GoHome = () => {
        homeRef.current.scrollIntoView({ behavior: 'smooth' });
    }

    const SearchTextfieldResult = (value) => {
        if (value.length > 2) {
            setSearch(value);
            setCurrentPage(1);
            Redraw(items, sections, sectionID, 1, value);
        } else {
            setSearch('');
            setCurrentPage(1);
            Redraw(items, sections, sectionID, 1, '');
        }

    }

    const Redraw = (data, sections, section_, page, search_) => {

        var page_ = page - 1;
        var tmp = [];
        var section_1 = sections[0];
        var section_2 = sections[1];
        var section_3 = sections[2];

        if (search_ == '') {
            if (section_ == 0) {
                // STAROSTLIVOST O TVAR            
                tmp = data.filter(x => x.category == section_1.id);
            }
            if (section_ == 1) {
                // STAROSTLIVOST O TELO
                tmp = data.filter(x => x.category == section_2.id);
            }
            if (section_ == 2) {
                // OPALOVANIE
                tmp = data.filter(x => x.category == section_3.id);
            }
            if (section_ == 3) {
                // KABINA
                tmp = data.filter(x => x.care == 1);
            }
            if (section_ == 4) {
                // AKCIA
                tmp = data.filter(x => x.action == true);
            }
            if (section_ == 10) {
                // OBLUBENE
                tmp = data.filter(x => x.favourites == true);
            }
        }
        else {
            tmp = data.filter(x => (x.label.toLowerCase().includes(search_.toLowerCase()) || x.code.toLowerCase().includes(search_.toLowerCase())));
        }
        // POCET STRAN
        var count = Math.ceil(tmp.length / global.items_max);
        setPageMax(count);

        // ROZDELENIE VYSLEDKU
        tmp = tmp.slice(global.items_max * page_, (global.items_max * page_) + global.items_max)
        setItemsFiltered(tmp);
    }


    const ShowDetails = (item) => {        
        setSelectedItem(item);
        setShowSelected(true);
    }

    const ProductResult = (typ, item, product_, quantity_) => {        
        var tmp = items.find(x => x.id == product_.id);
        var old = 0;
        if (tmp != '') {
            old = tmp.quantity;
            tmp.quantity = quantity_;
            tmp.favourites = product_.favourites;
        }
        if (old != quantity_) {
            db_booking_update(product_.id, quantity_, tmp.cost_price);
        }
        setShowSelected(false);
    }

    const FavouritesAdd = (item) => {
        var tmp = items.find(x => x.id == item.id);
        var status = false;
        if (tmp != '') {
            status = !item.favourites;
            tmp.favourites = status;
        }
        setRedraw(!redraw);
        db_favourites_update(item.id, status);
    }

    const ShoppingCard = () => {
        // NAKUPNY KOSIK        
        setShowShoppingcard(true);
    }

    const BookingOrderResult = (typ) => {
        setShowShoppingcard(false);
        Reload();
        GoHome();
    }

    /* *****************************************************************
     
        RENDER - HLAVNE OKNO
     
    ***************************************************************** */
    return (
        <div ref={homeRef} style={{ ...styles.BlockCenter, height: '100%', marginTop: 20 }}>
            {showShoppingcard == false ?
                <Paper elevation={isSmall ? 0 : 3} style={{ ...styles.Block, maxWidth: boxWidth, backgroundColor: global.theme_white, border: '1px solid ' + global.theme_light_gray }}>
                    <div style={{ ...isSmall ? styles.Block : styles.BlockRow, width: '95%', marginTop: 20, marginBottom: 20 }}>

                        {/* SEARCH */}
                        <div style={{ ...styles.BlockLeft, width: isSmall ? '100%' : '50%' }}>
                            <SearchTextfield language={lang} search={search} func={SearchTextfieldResult.bind(this)} />
                        </div>

                        <div style={{ ...styles.BlockRight, flexDirection: 'row', width: isSmall ? '100%' : '50%', marginTop: isSmall ? 10 : 0 }}>
                            <Button onClick={() => ShoppingCard()} style={{ ...styles.ButtonRaw }}>
                                <p style={{ ...styles.TextSmall, marginRight: 10 }}>{lang.order_preview}</p>
                                <div style={{ ...styles.Block, width: 50, height: 50, borderRadius: 10, backgroundColor: global.theme_green }}>
                                    <FontAwesomeIcon style={{ width: 16, height: 16, color: global.theme_white }} icon={faShoppingCart} />
                                </div>
                            </Button>
                        </div>
                    </div>

                    <div style={{ ...styles.Block, width: '95%', paddingTop: 20, paddingBottom: 20 }}>
                        {isBusy == false ?
                            <Sections section_id={sectionID} sections={[sections[0].label, sections[1].label, sections[2].label, section4, section5]} backcolor={global.theme_white} func={SectionsResult.bind(this)} />
                            : null}
                    </div>

                    <div style={{ ...styles.Block, width: '95%', paddingTop: 20, paddingBottom: 20 }}>

                        {isSmall ? null :
                            <div style={{ ...styles.BlockRow, marginTop: 10, backgroundColor: global.theme_white }}>
                                <div style={{ ...styles.BlockLeft, width: '50%' }}>
                                    <p style={{ ...styles.TextTiny, marginLeft: boxHeight + 10, color: global.theme_medium_gray }}>{lang.product}</p>
                                </div>
                                <div style={{ ...styles.BlockRight, width: '50%' }}>
                                    <p style={{ ...styles.TextTiny, marginRight: boxHeight / 2, color: global.theme_medium_gray }}>{lang.order_pieces}</p>
                                </div>
                            </div>
                        }

                        {/* ZOZNAM */}
                        {
                            itemsFiltered.map(item => (
                                isSmall ?
                                    <Paper key={item.id} elevation={0} style={{ ...styles.BlockLeft, marginTop: 10, marginBottom: 10, backgroundColor: global.theme_lighter, borderRadius: 5 }}>
                                        <div style={{ ...styles.BlockRow, paddingTop: 10 }}>
                                            <div onClick={() => ShowDetails(item)} style={{ ...styles.Block, width: boxHeight, marginLeft: 5, cursor: 'pointer' }}>
                                                <img src={item.image == '' ? global.images + 'app/no-image.jpg' : global.products + '/' + item.image} style={{ width: boxHeight - 30, height: boxHeight - 30, borderRadius: 5 }}></img>
                                            </div>

                                            <div onClick={() => ShowDetails(item)} style={{ ...styles.BlockLeft, justifyContent: 'center', cursor: 'pointer' }}>
                                                <p style={{ ...styles.TextSmall, textAlign: 'left', fontWeight: 'bold', color: global.theme_black, marginLeft: 10 }}>{item.label}</p>
                                                <p style={{ ...styles.TextTiny, textAlign: 'left', color: global.theme_black, marginLeft: 10, marginTop: 5 }}>{item.code} {lang.order_price}: {item.price} {lang.money}</p>
                                                <p style={{ ...styles.TextTiny, textAlign: 'left', color: global.theme_black, marginLeft: 10, marginTop: 2 }}>{item.details}</p>
                                            </div>
                                        </div>
                                        <div style={{ ...styles.BlockLeft }}>
                                            <p style={{ ...styles.TextTiny, textAlign: 'left', color: global.theme_black, marginLeft: 10, marginTop: 2 }}>{item.note_short}</p>
                                        </div>
                                        <div style={{ ...styles.BlockRight }}>
                                            <div style={{ ...styles.BlockRow, paddingTop: 20, paddingBottom: 10 }}>
                                                <div style={{ ...styles.BlockLeft, width: '30%' }}>
                                                    <IconButton onClick={() => FavouritesAdd(item)} style={{ margin: 0, width: 35, height: 35, marginLeft: 10 }}>
                                                        <FontAwesomeIcon style={{ width: 16, height: 16, color: item.favourites == true ? global.theme_red : global.theme_light }} icon={faHeart} />
                                                    </IconButton>
                                                </div>
                                                <div style={{ ...styles.BlockRight, width: '70%', marginRight: 10 }}>
                                                    <PlusMinus language={props.language} quantity={item.quantity} editable={false} id={item.id} delay={true} pieces={item.unit} func={PlusMinusPress.bind(this)} />
                                                </div>
                                            </div>
                                        </div>
                                    </Paper>
                                    :
                                    <Paper key={item.id} elevation={0} style={{ ...styles.BlockLeft, height: boxHeight, marginTop: 10, marginBottom: 10, backgroundColor: global.theme_lighter, borderRadius: boxHeight / 2 }}>
                                        <div style={{ ...styles.BlockRow }}>
                                            <div onClick={() => ShowDetails(item)} style={{ ...styles.Block, width: boxHeight + 10, height: boxHeight, cursor: 'pointer' }}>
                                                <img src={item.image == '' ? global.images + 'app/no-image.jpg' : global.products + '/' + item.image} style={{ width: boxHeight - 10, height: boxHeight - 10, borderRadius: (boxHeight - 10) / 2, marginLeft: 5 }}></img>
                                            </div>
                                            <div style={{ ...styles.BlockRow }}>
                                                <div onClick={() => ShowDetails(item)} style={{ ...styles.BlockLeft, width: '70%', justifyContent: 'center', height: boxHeight, cursor: 'pointer' }}>
                                                    <p style={{ ...styles.TextSmall, textAlign: 'left', fontWeight: 'bold', color: global.theme_black, marginLeft: 10 }}>{item.label}</p>
                                                    <p style={{ ...styles.TextTiny, textAlign: 'left', color: global.theme_black, marginLeft: 10, marginTop: 5 }}>{item.code} {lang.order_price}: {item.price} {lang.money}</p>
                                                    <p style={{ ...styles.TextTiny, textAlign: 'left', color: global.theme_black, marginLeft: 10, marginTop: 2 }}>{item.note_short}</p>
                                                    <p style={{ ...styles.TextTiny, textAlign: 'left', color: global.theme_black, marginLeft: 10, marginTop: 2 }}>{item.details}</p>
                                                </div>
                                                <div style={{ ...styles.Block, width: 50, height: boxHeight }}>
                                                    <IconButton onClick={() => FavouritesAdd(item)} style={{ margin: 0, width: 35, height: 35 }}>
                                                        <FontAwesomeIcon style={{ width: 16, height: 16, color: item.favourites == true ? global.theme_red : global.theme_light }} icon={faHeart} />
                                                    </IconButton>
                                                </div>
                                                <div style={{ ...styles.Block, width: 130, height: boxHeight }}>
                                                    <PlusMinus language={props.language} quantity={item.quantity} editable={false} id={item.id} delay={true} pieces={item.unit} func={PlusMinusPress.bind(this)} />
                                                </div>
                                            </div>
                                        </div>
                                    </Paper>
                            ))
                        }
                        {pageMax > 1 ?
                            <div style={{ ...styles.Block, maxWidth: 900, paddingTop: 10, marginTop: 20, paddingBottom: 20, borderTop: '1px solid ' + global.theme_light_gray }}>
                                <Pagination count={pageMax} size={'medium'} page={currentPage} onChange={PageChange} showFirstButton showLastButton />
                            </div>
                            : null}

                        <div style={{ ...styles.BlockRow, paddingTop: 40 }}>
                            <div style={{ ...styles.BlockLeft }}>
                            </div>
                            <div style={{ ...styles.BlockRight, width: 60 }}>
                                <IconButton onClick={() => Reload()} style={{ ...styles.BlockRound, backgroundColor: global.theme_white, marginTop: 20 }}>
                                    <FontAwesomeIcon style={{ width: 16, height: 16, color: global.theme_medium_gray }} icon={faRotateLeft} />
                                </IconButton>
                            </div>
                        </div>

                    </div>
                </Paper >
                :
                // NAKUPNY KOSIK
                <BookingOrder language={lang} user={props.user} func={BookingOrderResult.bind(this)} />
            }
            {showOK == true ?
                <ShowOK func={ShowOKPress.bind(this)} />
                : null}

            {showSelected == true ?
                <Product product={selectedItem} show_cost={true} user={props.user} booking={true} booking_quantity={selectedItem.quantity} language={lang} func={ProductResult.bind(this)} />
                : null}

            {isBusy == true ?
                <Loading></Loading>
                : null}
        </div >
    )
};

