import React, { useEffect, useState } from 'react';

import './App.css';
import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { sk } from './globals.js';
import { Main } from './main.js';
import { Login } from './login.js';
import { Init } from './items';
import { Register } from './register.js';
import { LogoutUser } from './logout';
import { Rules } from './rules';


function App() {
    var lang = sk;

    useEffect(() => {
        Init();
    }, [])


    return (
        <div className="App" style={{ backgroundColor: global.theme_white, fontFamily: 'roboto' }}>
            <link
                rel="stylesheet"
                href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
            />

            <Helmet>
                <title>{lang.title}</title>
                <meta name="description" content={lang.description} />
            </Helmet>
            <Routes>
                <Route path="/" element={<Login />} />                
                <Route path="/login" element={<Login />} />
                <Route path="/logout" element={<LogoutUser />} />
                <Route path="/main" element={<Main />} />
                <Route path="/register" element={<Register />} />
                <Route path="/rules" element={<Rules />} />
            </Routes>
        </div>
    );
}

export default App;
