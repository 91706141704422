/*
*
* =================================================================================================================
* RULES -> VSEOBECNE OBCH. PODMIENKY, GDPR, COOKIES
* =================================================================================================================
*
*/

import React, { useEffect, useState, useRef } from 'react';

//import logo from '/react_images/logo.png';
import { Alert, Autocomplete, Button, IconButton, MenuItem, Select, TextField, Paper } from '@mui/material';
import { Routes, Route, Link, useNavigate, useParams } from "react-router-dom";
import { useMediaQuery } from 'react-responsive'
import { styles } from './styles.js';
import { Loading } from './items.js';
import Dialog from '@mui/material/Dialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons';


export const Rules = (props) => {
    /*

        <Rules title={lang.gdpr} lang={lang} typ={1} salon_id={2} language={language} func={RulesResult.bind(this)} />

    */
    const [language, setLanguage] = useState(0);
    const [isBusy, setBusy] = useState(true);
    const [text, setText] = useState('');

    var lang = props.lang

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    let { func } = props;
    var lang = props.lang;

    useEffect(() => {
        document.body.style.zoom = "100%";
        db_get_text();

        return () => {
        };

    }, []);

    const db_get_text = async () => {
        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + 'rules', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: props.salon_id,
                    typ: props.typ
                })
            })

            const json = await response.json();
            console.log(json);
            if (json.ok > 0) {
                setText(json.text);
            }
            setBusy(false);
        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    };

    const Press = () => {
        func(false);
    }

    return (
        <Dialog open={true} fullScreen={isSmall ? true : false} maxWidth={'lg'} style={{ marginLeft: isSmall ? 0 : global.menu_width - 40 }}>
            <div style={{ ...styles.BlockRow, background: global.theme_dialog_back, height: 50, justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ ...styles.BlockLeft, height: 40, justifyContent: 'center' }}>
                    <p style={{ ...styles.TextLarge, color: global.theme_dialog_label, marginLeft: 10 }}>{props.title}</p>
                </div>
                <div style={{ width: 50 }}>
                    <IconButton onClick={() => Press(false, false)} style={{ ...styles.BlockRound, width: 30, height: 30, backgroundColor: global.theme_black, alignSelf: 'flex-end' }}>
                        <FontAwesomeIcon style={{ width: 20, height: 20, color: global.theme_white }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>

            <div style={{ ...styles.Block, width: isSmall ? '100%' : 800, paddingTop: 20, paddingBottom: 10 }}>
                <div style={{ ...styles.BlockLeft, maxWidth: '98%', minHeight: 200 }}>
                    <div style={{ ...styles.BlockLeft, textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: text }} />
                </div>
            </div>

            <div style={{ ...styles.Block, paddingBottom: 10 }}>
                <div style={{ ...isSmall ? styles.Block : styles.BlockRowRaw }}>
                    {isBusy == false ?
                        <Button onClick={() => Press()} style={{ ...styles.ButtonBack, margin: 10 }}>{lang.close}</Button>
                        : null}
                </div>
            </div>

            {isBusy == true ?
                <Loading />
                : null}

        </Dialog >

    );

}
