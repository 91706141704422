import React, { useEffect, useState, useRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useMediaQuery } from 'react-responsive'
import { Routes, Route, useNavigate, useParams } from "react-router-dom";
import { Button, TextField, Alert, Select, IconButton, Paper } from '@mui/material';
import { sk, cz } from './globals.js';
import { Debug, LoadLogin, LoadUser, SaveUser, SaveLogin, Empty, Languages, SaveToken, LoadToken } from './items.js';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleUp, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { InputLabel } from '@mui/material';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme2-6.css";
import { styles } from './styles.js';
import { ForgotPasswordDialog } from './forgot_password.js';
import CircularProgress from '@mui/material/CircularProgress';


export const Login = (props) => {
    const navigate = useNavigate();
    // LANGUAGE
    const [language, setLanguage] = useState(sk);

    //APP
    const [isBusy, setBusy] = useState(true);

    const [username, setUsername] = useState(global.testing == true ? 'salon@vgvstudio.sk' : '');
    const [password, setPassword] = useState(global.testing == true ? '12345678' : '');
    const [error, setError] = useState('');
    const [showForgot, setShowForgot] = useState(false);

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    var lang = language;

    let params = useParams();

    const homeRef = useRef(null)

    /*
    const logo = require('./react/app/logo_white.png');
    const logo_color = require('./react/app/logo.png');
    */

    useEffect(() => {


        // KONTROLA PRIHLASENIA
        var data = LoadToken();
        if (data != null) {
            navigate('/main');
        }

        setBusy(false);

        return () => {
        };

    }, []);



    const db_login = async () => {
        setError('');
        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + 'login', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    language: language,
                    username: username,
                    password: password,
                })
            })

            const json = await response.json();
            
            if (json.ok > 0) {
                setBusy(false);
                /*
                setError('');
                var today = new Date();
                var data = { logged: true, date: today };
                SaveLogin(data);

                var user = json.user;
                SaveUser(user);
                SaveLanguage(user.language);
                */
                if (json.login == true) {
                    SaveToken(json.token);
                    navigate('/main');
                } else {
                    setError(lang.login_error);
                }

            } else {
                setError(lang.login_error);
            }


        } catch (error) {
            Debug(error);
            setBusy(false);
        }
    }

    const LoginProccess = () => {
        setError('');
        db_login();
    }

    const ForgotPassword = () => {
        setShowForgot(true);
    }

    const ForgotPasswordPress = (typ, value) => {
        if (typ == false) {
            setShowForgot(false);
        }
    }

    const LanguagesResult = (value) => {
        setLanguage(value);
    }

    return (
        <div style={{ ...styles.Block, backgroundColor: global.theme_white, height: isSmall ? undefined : '100vh', minHeight: isSmall ? undefined : '100vh' }}>
            <div style={{ ...styles.Block, flexDirection: isSmall ? 'column' : 'row', height: isSmall ? undefined : '100vh' }}>
                <div style={{ ...styles.Block, width: isSmall ? '100%' : '40%', background: global.theme_gradient_dark, height: isSmall ? undefined : '100vh' }}>
                    <div style={{ ...styles.Block, height: 100 }}>
                        <p style={{ ...styles.TextXXLarge, marginTop: 40, color: global.theme_gray }}>{lang.title.toUpperCase()}</p>
                    </div>
                    <div style={{ ...styles.Block, alignItems: 'center', justifyContent: 'center', height: '70vh' }}>
                        <img src={global.images + 'app/login_banner.jpg'} style={{ minWidth: '80%', width: '80%' }} />
                        <a href={global.web} style={{ ...styles.TextSmall, marginTop: 20, color: global.theme_black, textDecoration: 'none' }}>{global.www}</a>
                    </div>
                </div>
                <div style={{ ...styles.Block, width: isSmall ? '100%' : '60%', backgroundColor: global.theme_white, height: isSmall ? undefined : '100vh' }}>

                    <Paper elevation={3} variant={'elevation'} style={{ ...styles.Block, backgroundColor: global.theme_gray, borderRadius: 20, width: isSmall ? '100%' : 350, paddingTop: 20, paddingBottom: 30 }}>
                        <div style={{ width: '90%', padding: 0 }}>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                                    <p style={{ ...styles.TextXXLarge, marginTop: 5 }}>{lang.login}</p>
                                </div>
                            </div>
                            <div style={{ display: 'flex', width: '100%', justifyContent: 'center', marginTop: 25, marginBottom: 25 }}>
                                <img src={global.images + 'app/logo_circle.png'} width={100} />
                            </div>
                            <Languages language={lang} func={LanguagesResult.bind(this)} />
                            <div style={{ padding: 0, marginTop: 40 }}>
                                <div style={{ padding: 10 }} >
                                    <TextField style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={lang.username + ' / ' + lang.email} variant="outlined" value={username} onInput={e => setUsername(e.target.value)} />
                                </div>
                                <div style={{ padding: 10 }} >
                                    <TextField style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={lang.password} variant="outlined" type="password" value={password} onInput={e => setPassword(e.target.value)} />
                                </div>
                            </div>
                            {error != '' ?
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 40 }}>
                                    <Alert severity="error">{error}</Alert>
                                </div>
                                : null}
                            {error == '' ?
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 0, height: 40 }}>
                                    {isBusy == true ?
                                        <CircularProgress />
                                        :
                                        <p style={{ ...styles.TextTiny }}>{error}</p>
                                    }
                                </div>
                                : null}
                            <div style={{ display: 'flex', width: '100%', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', marginTop: 20 }}>
                                <Button onClick={() => LoginProccess()} style={{ ...styles.ButtonSave, backgroundColor: global.theme_green, width: 240 }} variant="contained">{lang.login}</Button>
                                <Button onClick={() => ForgotPassword()} style={{ color: global.theme_dark_gray, marginTop: 30, textDecorationLine: 'underline', textTransform: 'none', fontWeight: 'normal' }} variant="text">{lang.lost_password_button}</Button>
                            </div>
                        </div>
                    </Paper>

                </div>
            </div>

            {
                showForgot == true ?
                    <ForgotPasswordDialog func={ForgotPasswordPress.bind(this)} />
                    : null
            }

        </div >
    )
};

