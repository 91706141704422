export function IsLarge() {
    var query = "(min-width: " + parseInt(global.device_medium + 1) + "px)";
    var result = window.matchMedia(query).matches;
    return result;
}

export function IsMedium() {
    var query1 = "(min-width: " + parseInt(global.device_small + 1) + "px)";
    var query2 = "(max-width: " + parseInt(global.device_medium) + "px)";
    var result1 = window.matchMedia(query1).matches;
    var result2 = window.matchMedia(query2).matches;
    if (result1 == true && result2 == true) {
        return true;
    } else {
        return false;
    }
}

export function IsSmall() {
    var query = "(max-width: " + parseInt(global.device_small) + "px)";
    var result = window.matchMedia(query).matches;
    return result;
}

export const styles = {
    Block: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%'
    },
    BlockPadding: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        paddingLeft: 10,
        paddingRight: 10
    },
    BlockRaw: {
        display: 'flex',
        //justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    BlockCenter: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%'
    },
    BlockCenterPadding: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexDirection: 'column',
        paddingLeft: 10,
        paddingRight: 10
    },
    BlockRow: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        flexDirection: 'row',
        width: '100%'
    },
    BlockRowRaw: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        flexDirection: 'row',
    },
    BlockLeft: {
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
        width: '100%'
    },
    BlockRight: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        flexDirection: 'column',
        width: '100%'
    },
    BlockMaxLarge: {
        // BLOK S MAX.PREDNASTAVENOU SIRKOU
        display: 'flex',
        flexDirection: 'column',
        maxWidth: global.screen_max,
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center'
    },
    BlockMaxSmall: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 320,
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center'
    },
    BlockRound: {
        // BOX okrúhly pre faCheck
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        borderRadius: '50%',
        width: 36,
        height: 36,

        backgroundColor: global.theme_green,
    },
    BlockMenu: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        flexDirection: 'column',
        width: global.menu_width,
        backgroundColor: global.theme_dark,
    },
    ButtonLink: {
        color: global.theme_dark_gray,
        textTransform: 'none',
        fontWeight: 'normal',
        margin: 0,
        padding: 0
    },
    ButtonBack: {
        color: global.theme_white,
        backgroundColor: global.theme_blue,
        width: 150,
        height: 36,
        textTransform: 'none',
        borderRadius: 18
    },
    ButtonSave: {
        color: global.theme_white,
        backgroundColor: global.theme_green,
        width: 150,
        height: 36,
        textTransform: 'none',
        borderRadius: 18
    },
    ButtonSelect: {
        color: global.theme_black,
        backgroundColor: global.theme_gray,
        width: 100,
        height: 30,
        textTransform: 'none',
        borderColor: global.theme_black,
        borderRadius: 18
    },
    ButtonNext: {
        color: global.theme_white,
        backgroundColor: global.theme_green,
        fontSize: global.font_tiny,
        width: 150,
        height: 36,
        textTransform: 'none',
        borderRadius: 18
    },
    ButtonDark: {
        color: global.theme_white,
        backgroundColor: global.theme_medium,
        fontSize: global.font_tiny,
        width: 150,
        height: 36,
        textTransform: 'none',
        borderRadius: 18
    },
    ButtonPrev: {
        color: global.theme_black,
        backgroundColor: undefined,
        width: 150,
        height: 36,
        textTransform: 'none',
        border: '1.5px solid ' + global.theme_dark,
        borderRadius: 18
    },
    ButtonGreen: {
        color: global.theme_white,
        backgroundColor: global.theme_dark_green,
        fontSize: global.font_tiny,
        width: 150,
        height: 36,
        textTransform: 'none',
        borderRadius: 18
    },
    ButtonDisabled: {
        // NEAKTIVNY BUTTON
        color: global.theme_white,
        backgroundColor: global.theme_lighter,
        fontSize: global.font_tiny,
        width: 150,
        height: 36,
        textTransform: 'none',
        borderRadius: 18
    },
    ButtonMenuBack: {
        color: global.theme_white,
        backgroundColor: global.theme_light,
        textTransform: 'none',
        fontWeight: 'normal',
        margin: 0,
        padding: 0,
        width: 150,
        height: 36,
        borderRadius: 18
    },
    ButtonRaw: {
        textTransform: 'none',
        fontWeight: 'normal',
        margin: 0,
        padding: 0,
    },
    ButtonRound: {
        color: global.theme_white,
        backgroundColor: global.theme_light,
        textTransform: 'none',
        fontWeight: 'normal',
        margin: 0,
        padding: 0,
        width: 36,
        height: 36,
        borderRadius: '50%'
    },
    ButtonRoundBorder: {
        color: global.theme_white,
        border: '2px solid ' + global.theme_light,
        textTransform: 'none',
        fontWeight: 'normal',
        margin: 0,
        padding: 0,
        width: 26,
        height: 26,
        borderRadius: '50%'
    },
    ButtonRoundOrder: {
        color: global.theme_white,
        border: '2px solid #BBBBBB',
        textTransform: 'none',
        fontWeight: 'normal',
        margin: 0,
        padding: 0,
        width: 30,
        height: 30,
        borderRadius: '50%'
    },
    ButtonMenu: {
        color: global.theme_gray,
        backgroundColor: global.theme_dark,
        textTransform: 'none',
        fontWeight: 'normal',
        justifyContent: 'left',
        margin: 0,
        padding: 0,
        paddingLeft: 20,
        width: global.menu_width - 60,
        height: 40,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        borderTopRightRadius: 20,
        borderBottomRightRadius: 20,
        fontSize: global.font_small,
    },
    ButtonDashboard: {
        color: global.theme_white,
        border: '0px solid ' + global.theme_dark,
        textTransform: 'none',
        fontWeight: 'normal',
        margin: 0,
        padding: 0,
        width: 160,
        height: 80,
        borderRadius: 4,
        display: 'flex',
        flexDirection: 'column',
    },
    ButtonSection: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        color: global.theme_black,
        backgroundColor: global.theme_white,
        width: 138,
        height: 36,
        textTransform: 'none',        
        borderRadius: 18,
        cursor: 'pointer',
    },
    Label: {
        fontSize: global.font_label,
        margin: 0,
        marginTop: 0,
        marginBottom: 0,
        color: global.theme_black,
        fontWeight: 'bold'
    },
    TextXXLarge: {
        margin: 0,
        marginTop: 0,
        marginBottom: 0,
        fontSize: global.font_xxlarge,
        color: global.theme_black,
    },
    TextXLarge: {
        margin: 0,
        marginTop: 0,
        marginBottom: 0,
        fontSize: global.font_xlarge,
        color: global.theme_black,
    },
    TextLarge: {
        margin: 0,
        marginTop: 0,
        marginBottom: 0,
        fontSize: global.font_large,
        color: global.theme_black,
    },
    TextNormal: {
        margin: 0,
        marginTop: 0,
        marginBottom: 0,
        fontSize: global.font_normal,
        color: global.theme_black,
    },
    TextMenu: {
        margin: 0,
        marginTop: 0,
        marginBottom: 0,
        fontSize: 17,
        color: global.theme_black,
    },
    TextBlog: {
        margin: 0,
        marginTop: 0,
        marginBottom: 0,
        fontSize: global.font_blog,
        color: global.theme_black,
    },
    TextSmall: {
        margin: 0,
        marginTop: 0,
        marginBottom: 0,
        fontSize: global.font_small,
        color: global.theme_black,
    },
    TextTiny: {
        margin: 0,
        marginTop: 0,
        marginBottom: 0,
        fontSize: global.font_tiny,
        color: global.theme_black,
    },
    TextXXTiny: {
        margin: 0,
        marginTop: 0,
        marginBottom: 0,
        fontSize: global.font_xxtiny,
        color: global.theme_black,
    },
    TextTitle: {
        margin: 0,
        marginTop: 0,
        marginBottom: 0,
        fontSize: global.font_xlarge,
        color: global.theme_black,
    },
    BorderTop:
    {
        borderTop: '1px solid ' + global.theme_gray
    },
    BorderBottom:
    {
        borderBottom: '1px solid ' + global.theme_gray
    },
    BorderTopBold:
    {
        borderTop: '3px solid ' + global.theme_gray
    },
    BorderBottomBold:
    {
        borderBottom: '3px solid ' + global.theme_gray
    },
    Border:
    {
        border: '1px solid ' + global.theme_gray
    },
    MenuLine:
    {
        marginTop: 0,
        marginBottom: 10,
        height: 1,
        justifySelf: 'center',
        alignSelf: 'center',
        width: global.menu_width - 70,
        borderTop: '1px solid ' + global.theme_dark_gray
    },
};

