import React, { useEffect, useState, useRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useMediaQuery } from 'react-responsive'
import { Routes, Route, useNavigate, useParams } from "react-router-dom";
import { Button, TextField, Autocomplete, Box, IconButton, Paper } from '@mui/material';
import { sk } from './globals.js';
import { Debug, Loading, ShowOK, AskDialog, ReklamaciaDialog } from './items.js';
import Link from '@mui/material/Link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRotateLeft, faCircleCheck, faEdit, faFolderTree, faHome, faPlus, faSearch, faTurnUp, faXmark, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme2-6.css";
import { styles } from './styles.js';
import Pagination from '@mui/material/Pagination';
import { Product } from './product.js';


export const BookingHistory = (props) => {
    //const navigate = useNavigate();

    const [isBusy, setBusy] = useState(false);
    const [items, setItems] = useState([]);
    const [redraw, setRedraw] = useState(false);
    const [showNew, setShowNew] = useState(false);
    const [error, setError] = useState('');
    const [showOK, setShowOK] = useState(false);
    const [showEnabled, setShowEnabled] = useState(false);
    const [showReklamacia, setShowReklamacia] = useState(false);

    // Detail objednavky
    const [showDetails, setShowDetails] = useState(false);
    const [selectedItem, setSelectedItem] = useState([]);
    const [showProduct, setShowProduct] = useState([]);
    const [product, setProduct] = useState([]);
    const [products, setProducts] = useState([]);
    const [sum, setSum] = useState(0);
    const [quantity, setQuantity] = useState(0);

    // SEARCH
    const [search, setSearch] = useState('');
    const [searchArr, setSearchArr] = useState([]);

    // ZRUSIT OBJEDNAVKU
    const [showAsk, setShowAsk] = useState(false);

    // PAGINATION
    const [currentPage, setCurrentPage] = useState(1);
    const [pageMax, setPageMax] = useState(0);

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    /*
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    */

    const boxWidth = 800;
    const boxHeight = 70;

    var lang = props.language;;

    let params = useParams();

    //const logo = require('./react/app/logo.png');

    useEffect(() => {

        db_items(0, [], false);

        return () => {
        };

    }, []);

    const db_items = async (start, arr, status) => {
        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + 'booking_history', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: props.user.id,
                    status: status,
                    start, start,
                    count: global.items_max,
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var tmp = json.orders;
                setItems(tmp);

                // pagination
                var tmp = json.count;
                tmp = Math.ceil(tmp / global.items_max);
                setPageMax(tmp);
            }
            setBusy(false);

        } catch (error) {
            Debug(error);
            setBusy(false);
        }
    }


    const db_booking_cancel = async () => {
        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + 'booking_cancel', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: props.user.id,
                    language: props.language.lang_id,
                    booking_id: selectedItem.id
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                selectedItem.status = global.booking_cancel_request = 3;

                var tmp = items.find(x => x.id == selectedItem.id);
                if (tmp != null) {
                    tmp.status = global.booking_cancel_request = 3;
                }

            }
            setBusy(false);

        } catch (error) {
            Debug(error);
            setBusy(false);
        }
    }


    const db_booking_order = async (id) => {
        setProducts([]);

        if (isBusy == false) {
            setBusy(true);

            try {
                const response = await fetch(
                    global.db_url + 'booking_order', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        id: id
                    })
                })

                const json = await response.json();

                if (json.ok > 0) {
                    var tmp = json.products
                    if (tmp != '' || tmp != null) {
                        // sort
                        tmp.sort((a, b) => {
                            if (a.label < b.label) {
                                return -1;
                            } else {
                                return 1;
                            }
                        })
                        // reindexing
                        tmp = tmp.map(function (item, index) {
                            item.index = index;
                            return item;
                        });

                        setProducts(tmp);
                        Redraw(tmp);
                    } else {
                        setProducts([]);
                    }
                }
                setBusy(false);

            } catch (error) {
                Debug(error);
                setBusy(false);
            }
        }
    }

    const Redraw = (data) => {
        var sum = 0.00;
        var quantity = 0;

        data.forEach(item => {
            let cost = parseFloat(item.price) / parseInt(item.unit);
            let q = parseInt(item.quantity);
            let cost_sum = cost * q;
            sum = sum + cost_sum;

            item.cost = cost.toFixed(2);
            item.cost_sum = cost_sum.toFixed(2);

            quantity = quantity + q;
        });

        setSum(sum.toFixed(2));
        setQuantity(quantity);
    }

    const Reload = () => {
        setCurrentPage(1);
        db_items(0, searchArr, showEnabled);
    }

    const PageChange = (event, value) => {
        // PAGINATION
        setCurrentPage(value);
        var page = (value - 1) * global.items_max;
        db_items(page, searchArr, showEnabled);
    };


    const Details = (item) => {
        setSelectedItem(item);
        setShowDetails(true);
        db_booking_order(item.id);
    }


    const ShowOKPress = (value) => {
        setShowOK(false);
    }


    const ShowProduct = (item) => {
        var data = {
            id: item.product_id
        }
        setProduct(data);
        setShowProduct(true);
    }

    const ProductResult = () => {
        setShowProduct(false);
    }

    const CancelOrder = () => {
        setShowAsk(true);
    }

    const AskDialogResult = (value) => {
        setShowAsk(false);
        if (value == true) {
            // ZRUSIT OBJEDNAVKU
            db_booking_cancel();
        }
    }

    const Reklamacia = () => {
        setShowReklamacia(true);
    }

    const ReklamaciaDialogPress = (value) => {
        setShowReklamacia(false);
    }

    /* *****************************************************************
    
        RENDER - HLAVNE OKNO
    
    ***************************************************************** */
    return (
        <div style={{ ...styles.BlockCenter, height: '100%', marginTop: 20 }}>

            {showDetails == true ?
                // ******************************************************************************************
                // DETAIL OBJEDNAVKY
                // ******************************************************************************************
                <Paper elevation={isSmall ? 0 : 3} style={{ ...styles.Block, maxWidth: boxWidth, backgroundColor: global.theme_white, border: '1px solid ' + global.theme_light_gray }}>
                    <div style={{ ...styles.BlockRow, width: '95%', marginTop: 20, marginBottom: 20 }}>
                        <div style={{ ...styles.BlockLeft }}>
                            <p style={{ ...styles.TextXXLarge, color: global.theme_dialog_label, textAlign: 'left' }}>{lang.booking}: {selectedItem.number}</p>
                            <p style={{ ...styles.TextNormal, fontWeight: 'bold', color: global.theme_black, marginTop: 10 }}>{lang.order_status}: {lang.order_status_name[selectedItem.status]}</p>
                            {selectedItem.status == 0 ?
                                <Button onClick={() => CancelOrder()} style={{ ...styles.ButtonLink, marginTop: 10, color: global.theme_red }}>{lang.order_cancel}</Button>
                                : null}
                            {selectedItem.status > 0 ?
                                <Button onClick={() => Reklamacia()} style={{ ...styles.ButtonLink, marginTop: 10, color: global.theme_blue }}>{lang.order_reklamovat}</Button>
                                : null}
                        </div>
                        <div style={{ ...styles.BlockRight, width: 50 }}>
                            <IconButton onClick={() => setShowDetails(false)} style={{ ...styles.BlockRound, width: 30, height: 30, backgroundColor: global.theme_black, alignSelf: 'flex-end' }}>
                                <FontAwesomeIcon style={{ width: 20, height: 20, color: global.theme_white }} icon={faXmark} />
                            </IconButton>
                        </div>
                    </div>


                    {products.length > 0 ?
                        <div style={{ ...styles.Block, width: isLarge ? '98%' : '100%', paddingTop: 20, paddingBottom: 20 }}>

                            <div style={{ ...styles.BlockLeft, width: '95%', paddingTop: 0, paddingBottom: 40 }}>
                                <p style={{ ...styles.TextSmall, color: global.theme_black }}>{lang.order_quantity}: {quantity}</p>
                                <p style={{ ...styles.TextSmall, color: global.theme_black }}>{lang.order_sum}: {sum} {lang.money}</p>
                            </div>

                            <p style={{ ...styles.TextTiny, color: global.theme_dark_gray }}>{lang.product_list}</p>

                            {isSmall ? null :
                                <div style={{ ...styles.BlockRow, marginTop: 10, backgroundColor: global.theme_white }}>
                                    <div style={{ ...styles.BlockLeft, width: '50%' }}>
                                        <p style={{ ...styles.TextTiny, marginLeft: boxHeight + 10, color: global.theme_medium_gray }}>{lang.product}</p>
                                    </div>
                                    <div style={{ ...styles.BlockRight, width: '50%' }}>
                                        <p style={{ ...styles.TextTiny, marginRight: boxHeight / 2, color: global.theme_medium_gray }}>{lang.order_product_sum}</p>
                                    </div>
                                </div>
                            }

                            {
                                products.map(item => (
                                    isSmall ?
                                        <Paper onClick={() => ShowProduct(item)} key={item.id} elevation={0} style={{ ...styles.Block, marginTop: 10, marginBottom: 10, backgroundColor: global.theme_lighter, borderRadius: 5, cursor: 'pointer' }}>
                                            <div style={{ ...styles.BlockRow, paddingTop: 10 }}>
                                                <div style={{ ...styles.BlockLeft, width: boxHeight, }}>
                                                    <img src={item.image == '' ? global.images + 'app/no-image.jpg' : global.products + '/' + item.image} style={{ width: boxHeight - 10, height: boxHeight - 10, borderRadius: (boxHeight - 10) / 2, marginLeft: 5 }}></img>
                                                </div>
                                                <div style={{ ...styles.BlockLeft, justifyContent: 'center' }}>
                                                    <p style={{ ...styles.TextSmall, textAlign: 'left', fontWeight: 'normal', color: global.theme_black, marginLeft: 10 }}>{item.label}</p>
                                                    <p style={{ ...styles.TextXXTiny, textAlign: 'left', color: global.theme_dark_gray, marginLeft: 10, marginTop: 2 }}>{item.code} {item.details}</p>
                                                </div>
                                            </div>
                                            <div style={{ ...styles.BlockRow, width: '95%', paddingTop: 20, paddingBottom: 10 }}>
                                                <div style={{ ...styles.BlockLeft, width: '30%', justifyContent: 'center' }}>
                                                    <p style={{ ...styles.TextTiny, textAlign: 'left', color: global.theme_black }}>{item.cost} {lang.money}</p>
                                                </div>
                                                <div style={{ ...styles.Block, width: '40%' }}>
                                                    <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', color: global.theme_black }}>{item.quantity} {lang.pieces}</p>
                                                </div>
                                                <div style={{ ...styles.BlockRight, width: '30%', justifyContent: 'center' }}>
                                                    <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', color: global.theme_black }}>{item.cost_sum} {lang.money}</p>
                                                </div>
                                            </div>

                                        </Paper>
                                        :
                                        <Paper onClick={() => ShowProduct(item)} key={item.id} elevation={0} style={{ ...styles.BlockLeft, height: boxHeight, marginTop: 10, marginBottom: 10, backgroundColor: global.theme_lighter, borderRadius: boxHeight / 2, cursor: 'pointer' }}>
                                            <div style={{ ...styles.BlockRow }}>
                                                <div style={{ ...styles.Block, width: boxHeight + 0, height: boxHeight }}>
                                                    <img src={item.image == '' ? global.images + 'app/no-image.jpg' : global.products + '/' + item.image} style={{ width: boxHeight - 10, height: boxHeight - 10, borderRadius: (boxHeight - 10) / 2, marginLeft: 5 }}></img>
                                                </div>
                                                <div style={{ ...styles.BlockRow }}>
                                                    <div style={{ ...styles.BlockLeft, width: '55%', justifyContent: 'center', height: boxHeight }}>
                                                        <p style={{ ...styles.TextSmall, textAlign: 'left', fontWeight: 'normal', color: global.theme_black, marginLeft: 10 }}>{item.label}</p>
                                                        <p style={{ ...styles.TextXXTiny, textAlign: 'left', color: global.theme_dark_gray, marginLeft: 10, marginTop: 2 }}>{item.code} {item.details}</p>
                                                    </div>
                                                    <div style={{ ...styles.BlockRight, width: 110, justifyContent: 'center', height: boxHeight }}>
                                                        <p style={{ ...styles.TextTiny, textAlign: 'left', color: global.theme_black, marginRight: 10 }}>{item.cost} {lang.money}</p>
                                                    </div>
                                                    <div style={{ ...styles.Block, width: 80, height: boxHeight }}>
                                                        <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', color: global.theme_black }}>{item.quantity} {lang.pieces}</p>
                                                    </div>
                                                    <div style={{ ...styles.BlockRight, width: 110, justifyContent: 'center', height: boxHeight }}>
                                                        <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', color: global.theme_black }}>{item.cost_sum} {lang.money}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Paper>
                                ))
                            }
                            < div style={{ ...styles.Block, paddingTop: 10, paddingBottom: 10 }}>
                                <div style={{ ...styles.BlockRight }}>
                                    <p style={{ ...styles.TextLarge, marginRight: 20 }}>{lang.order_total}: <b>{sum} {lang.money}</b></p>
                                    <p style={{ ...styles.TextTiny, marginRight: 20, color: global.theme_medium_gray }}>{lang.with_dph}</p>
                                </div>
                            </div>

                            {selectedItem.note != '' ?
                                <div style={{ ...styles.BlockLeft, width: isLarge ? '98%' : '100%', paddingTop: 20, paddingBottom: 30 }}>
                                    <p style={{ ...styles.TextTiny, fontWeight: 'bold' }}>{lang.note}:</p>
                                    <p style={{ ...styles.TextSmall, marginTop: 5, textAlign: 'left' }}>{selectedItem.note}</p>
                                </div>
                                : null}

                            <div style={{ ...styles.Block, width: isLarge ? '98%' : '100%', paddingTop: 20, paddingBottom: 0 }}>
                                <Button onClick={() => setShowDetails(false)} style={{ ...styles.ButtonBack, margin: 10 }}>{lang.close}</Button>
                            </div>

                        </div>
                        :
                        <div style={{ ...styles.Block, width: isLarge ? '98%' : '100%', paddingTop: 20, paddingBottom: 20 }}>
                            {isBusy == false ?
                                <p style={{ ...styles.TextNormal, fontWeight: 'bold', color: global.theme_black }}>{lang.order_no_products}!</p>
                                : null}
                        </div>
                    }
                </Paper>
                :
                // ******************************************************************************************
                // ZOZNAM OBJEDNAVOK
                // ******************************************************************************************
                <Paper elevation={isSmall ? 0 : 3} style={{ ...styles.Block, maxWidth: boxWidth, backgroundColor: global.theme_white, border: '1px solid ' + global.theme_light_gray }}>

                    {/* TOP MENU - HLADAT */}
                    {showNew == false ?
                        <div style={{ ...styles.BlockLeft, width: '95%', paddingTop: 20, paddingBottom: 20 }}>
                            <p style={{ ...styles.TextXXLarge }}>{lang.booking_list}</p>
                        </div>
                        : null}


                    <div style={{ ...styles.Block, width: '95%', paddingTop: 20, paddingBottom: 20 }}>

                        {/* ZOZNAM */}
                        {items.length > 0 ?
                            <div style={{ ...styles.BlockLeft }}>
                                <p style={{ ...styles.TextTiny, marginBottom: 20 }}>{lang.items_list}</p>
                            </div>
                            : null}
                        {
                            items.map(item => (
                                isSmall ?
                                    <div onClick={() => Details(item)} key={item.id} style={{ ...styles.Block, width: '100%', marginTop: 10, marginBottom: 10, paddingTop: 10, paddingBottom: 10, backgroundColor: lang.order_status_colors[item.status], borderRadius: 5, cursor: 'pointer' }}>
                                        <div style={{ ...styles.BlockRow }}>
                                            <div style={{ ...styles.Block, width: 30 }}>
                                                <FontAwesomeIcon style={{ width: 16, height: 16, marginTop: 2, color: global.theme_medium_gray }} icon={lang.order_status_icons[item.status]} />
                                            </div>
                                            <div style={{ ...styles.BlockLeft, width: '100%' }}>
                                                <p style={{ ...styles.TextNormal, marginLeft: 10 }}>{lang.booking}: <b>{item.number}</b></p>
                                            </div>
                                        </div>
                                        <div style={{ ...styles.BlockLeft }}>
                                            <p style={{ ...styles.TextTiny, marginLeft: 40, marginTop: 5 }}>{item.datum}</p>
                                            <p style={{ ...styles.TextTiny, marginLeft: 40, marginTop: 5 }}>{lang.order_items}: {item.count} {lang.pieces}</p>
                                            <p style={{ ...styles.TextTiny, marginLeft: 40, marginTop: 10 }}>{lang.order_status}: {lang.order_status_name[item.status]}</p>
                                        </div>
                                    </div>
                                    :
                                    <div onClick={() => Details(item)} key={item.id} style={{ ...styles.BlockRow, height: boxHeight, alignItems: 'center', marginTop: 10, marginBottom: 10, backgroundColor: lang.order_status_colors[item.status], borderRadius: boxHeight / 2, cursor: 'pointer' }}>
                                        <div style={{ ...styles.BlockRight, width: 40 }}>
                                            <FontAwesomeIcon style={{ width: 16, height: 16, color: global.theme_medium_gray }} icon={lang.order_status_icons[item.status]} />
                                        </div>
                                        <div style={{ ...styles.BlockLeft, width: '40%', marginLeft: 20 }}>
                                            <p style={{ ...styles.TextNormal }}>{lang.booking}: <b>{item.number}</b></p>
                                            <div style={{ ...styles.BlockRow }}>
                                                <div style={{ ...styles.BlockLeft }}>
                                                    <p style={{ ...styles.TextTiny, marginTop: 5 }}>{item.datum}</p>
                                                </div>
                                                <div style={{ ...styles.BlockRight }}>
                                                    <p style={{ ...styles.TextTiny, marginTop: 5 }}>{lang.order_items}: {item.count} {lang.pieces}</p>
                                                </div>

                                            </div>
                                        </div>
                                        <div style={{ ...styles.BlockRight, width: '60%', marginRight: boxHeight / 2 }}>
                                            <p style={{ ...styles.TextTiny }}>{lang.order_status}: {lang.order_status_name[item.status]}</p>
                                        </div>
                                    </div>
                            ))
                        }
                        {pageMax > 1 ?
                            <div style={{ ...styles.BlockRow, paddingTop: 10, marginTop: 20, paddingBottom: 20, borderTop: '1px solid ' + global.theme_light_gray }}>
                                <Pagination count={pageMax} size={'medium'} page={currentPage} onChange={PageChange} showFirstButton showLastButton />
                            </div>
                            : null}

                        <div style={{ ...styles.BlockRow, paddingTop: 40 }}>
                            <div style={{ ...styles.BlockLeft }}>
                            </div>
                            <div style={{ ...styles.BlockRight, width: 60 }}>
                                <IconButton onClick={() => Reload()} style={{ ...styles.BlockRound, backgroundColor: global.theme_white, marginTop: 20 }}>
                                    <FontAwesomeIcon style={{ width: 16, height: 16, color: global.theme_medium_gray }} icon={faRotateLeft} />
                                </IconButton>
                            </div>
                        </div>

                    </div>
                </Paper >
            }


            {showProduct == true ?
                <Product product={product} show_cost={false} user={props.user} booking={false} booking_quantity={0} language={lang} func={ProductResult.bind(this)} />
                : null}

            {showAsk == true ?
                <AskDialog label={lang.order_canceling} question={lang.order_cancel_question} text={lang.order_cancel_text} button_text={lang.yes} button_color={global.theme_red} cancel={lang.no} func={AskDialogResult.bind(this)} />
                : null}

            {showReklamacia == true ?
                <ReklamaciaDialog user={props.user} language={lang} func={ReklamaciaDialogPress.bind(this)} />
                : null}

            {showOK == true ?
                <ShowOK func={ShowOKPress.bind(this)} />
                : null}

            {isBusy == true ?
                <Loading></Loading>
                : null}
        </div >
    )
};

