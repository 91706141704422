import React, { useEffect, useState, useRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useMediaQuery } from 'react-responsive'
import { Routes, Route, useNavigate, useParams } from "react-router-dom";
import { Button, TextField, Autocomplete, Box, IconButton, Paper, Dialog, CircularProgress } from '@mui/material';
import { sk } from './globals.js';
import { Debug, Loading, Line, PlusMinus } from './items.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp, faCircleCheck, faEdit, faFolderTree, faHeart, faHome, faPlus, faSearch, faTurnUp, faXmark, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { styles } from './styles.js';
import { style } from '@mui/system';
import { formatDistanceWithOptions } from 'date-fns/fp';

export const Product = (props) => {
    /*
            USAGE:
            <Product user={props.user} language={lang} show_cost={true} booking={true} booking_quantity={quantity} func={ProductResult.bind(this)} />

            const ProductResult = (value) => {                                
            }


    */
    //const navigate = useNavigate();

    const [isBusy, setBusy] = useState(true);
    const [product, setProduct] = useState(false);
    const [bookingQuantity, setBookingQuantity] = useState(0);
    const [redraw, setRedraw] = useState(false);

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    /*
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    */

    const boxWidth = 800;
    const imgWidth = 200;

    let { func } = props;
    var lang = props.language;
    let params = useParams();

    //const logo = require('./react/app/logo.png');

    useEffect(() => {

        setBookingQuantity(props.booking_quantity);

        db_product();
        return () => {
        };

    }, []);

    const db_product = async () => {
        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + 'product', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    language: props.language.lang_id,
                    product_id: props.product.id,
                    salon_id: props.user.id
                })
            })

            const json = await response.json();
            
            if (json.ok > 0) {
                setProduct(json.product);
            }
            setBusy(false);

        } catch (error) {
            Debug(error);
            setBusy(false);
        }
    }

    const db_favourites_update = async (id, status) => {

        var data = {
            salon_id: props.user.id,
            product_id: id,
        }

        try {
            const response = await fetch(
                global.db_url + 'favourites_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data: data,
                    add: status
                })
            })

            const json = await response.json();            

            if (json.ok > 0) {
            }
        } catch (error) {
            Debug(error);
        }
    }


    const Press = (typ, value) => {
        func(typ, value, product, bookingQuantity);
    }

    const PlusMinusPress = (id, quantity) => {
        setBookingQuantity(quantity);
    }

    const FavouritesAdd = () => {
        var tmp = product;
        var status = false;
        status = !tmp.favourites;
        tmp.favourites = status;
        setRedraw(!redraw);
        db_favourites_update(tmp.id, status);
    }

    /* *****************************************************************
    
        RENDER - HLAVNE OKNO
    
    ***************************************************************** */
    return (
        isBusy == false ?
            <Dialog open={true} maxWidth={'lg'} fullScreen={isLarge ? false : true}>
                {isLarge ?
                    <div style={{ ...styles.BlockRow, height: 50, justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ width: '100%' }}>
                        </div>
                        <div style={{ width: 50 }}>
                            <IconButton onClick={() => Press(false, false)} style={{ ...styles.BlockRound, width: 30, height: 30, backgroundColor: global.theme_black, alignSelf: 'flex-end' }}>
                                <FontAwesomeIcon style={{ width: 20, height: 20, color: global.theme_white }} icon={faXmark} />
                            </IconButton>
                        </div>
                    </div>
                    : null}
                <div style={{ ...styles.Block, width: isLarge ? boxWidth : '100%', paddingTop: isLarge ? 0 : 20, paddingBottom: 10 }}>
                    <div style={{ ...isLarge ? styles.BlockRow : style.Block, width: '95%' }}>
                        <div style={{ ...styles.Block, width: isLarge ? '30%' : '100%' }}>
                            <img src={product.image == '' ? global.images + 'app/no-image.jpg' : global.products + '/' + product.image} style={{ width: imgWidth, height: imgWidth }}></img>
                        </div>
                        <div style={{ ...styles.Block, width: isLarge ? '70%' : '100%', height: isLarge ? imgWidth + 20 : undefined, paddingTop: isLarge ? 0 : 20, paddingBottom: isLarge ? 0 : 20 }}>
                            <p style={{ ...styles.TextXXTiny, marginTop: 5, color: global.theme_medium_gray, textAlign: 'center' }}>{product.code}</p>
                            <p style={{ ...styles.TextXLarge, fontWeight: 'bold', textAlign: 'center' }}>{product.label}</p>
                            <p style={{ ...styles.TextSmall, marginTop: 5, textAlign: 'center' }}>{product.note_short}</p>
                            <p style={{ ...styles.TextSmall, marginTop: 5, textAlign: 'center' }}>{product.details}</p>
                            {props.show_cost == true ?
                                <p style={{ ...styles.TextSmall, marginTop: 15, textAlign: 'center', fontWeight: 'bold' }}>{lang.order_price}: {product.price} {lang.money}</p>
                                : null}
                            {props.booking == true ?
                                <div style={{ ...styles.Block, paddingTop: 20, paddingBottom: 10 }}>
                                    <div style={{ ...styles.BlockRowRaw }}>
                                        <p style={{ ...styles.TextTiny, marginTop: 10, marginRight: 5 }}>{lang.order_pieces}</p>
                                        <PlusMinus language={props.language} quantity={bookingQuantity} editable={false} id={product.id} pieces={product.unit} func={PlusMinusPress.bind(this)} />
                                    </div>
                                </div>
                                : null}
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: '95%', paddingTop: 10, paddingBottom: 10, borderTop: '1px solid ' + global.theme_light }}>
                        {/* POPIS PRODUKTU */}
                        <div style={{ margin: 0, fontSize: global.font_small, textAlign: 'left', paddingLeft: isSmall || isMedium ? 0 : 10, paddingRight: isSmall || isMedium ? 0 : 10, color: global.theme_dark, width: '95%' }} dangerouslySetInnerHTML={{ __html: product.note_long }} />
                    </div>
                </div>
                <div style={{ ...styles.Block, paddingTop: 10, paddingBottom: 10 }}>
                    <div style={{ ...styles.BlockRowRaw }}>
                        <p style={{ ...styles.TextTiny, marginTop: 6, marginRight: 5 }}>{product.favourites == true ? lang.favourite : lang.favourite_add}</p>
                        <IconButton onClick={() => FavouritesAdd()} style={{ ...styles.BlockRound, backgroundColor: global.theme_white, width: 30, height: 30 }}>
                            <FontAwesomeIcon style={{ width: 16, height: 16, color: product.favourites == true ? global.theme_red : global.theme_light }} icon={faHeart} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.Block, paddingTop: 10, paddingBottom: 10 }}>
                    <Button onClick={() => Press(false, false)} style={{ ...styles.ButtonBack, margin: 10 }}>{lang.close}</Button>
                </div>

            </Dialog >
            :
            <Loading center={true}></Loading>
    )
};

