import React, { useEffect, useState, useRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useMediaQuery } from 'react-responsive'
import { Routes, Route, useNavigate, useParams } from "react-router-dom";
import { Button, TextField, Box, IconButton, Paper, Dialog, CircularProgress } from '@mui/material';
import { sk } from './globals.js';
import { Debug, Loading, ShowOK, PlusMinus, AskDialog } from './items.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faChevronUp, faCircleCheck, faEdit, faFolderTree, faHeart, faHome, faPlus, faSearch, faTriangleExclamation, faTurnUp, faXmark, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { styles } from './styles.js';
import { Product } from './product.js';

export const BookingOrder = (props) => {
    /*
            USAGE:
            <BookingOrder language={lang} func={BookingOrderResult.bind(this)} />

            const BookingOrderResult = (value) => {                                
            }


    */
    //const navigate = useNavigate();

    // Detail polozky
    const [selectedItem, setSelectedItem] = useState([]);
    const [showSelected, setShowSelected] = useState(false);

    const [isBusy, setBusy] = useState(false);
    const [isSending, setSending] = useState(false);
    const [sended, setSended] = useState(false);
    const [sendError, setSendError] = useState(false);
    const [items, setItems] = useState([]);
    const [note, setNote] = useState('');
    const [minOrder, setMinOrder] = useState(0);
    const [sum, setSum] = useState(0);
    const [quantity, setQuantity] = useState(0);
    const [showAsk, setShowAsk] = useState(false);
    const [redraw, setRedraw] = useState(false);

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    /*
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    */

    const boxWidth = global.max_width;
    const boxHeight = 60;

    let { func } = props;
    var lang = props.language;
    let params = useParams();

    //const logo = require('./react/app/logo.png');

    useEffect(() => {

        db_booking();
        return () => {
        };

    }, []);

    const db_booking = async () => {
        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + 'booking', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    language: props.language.lang_id,
                    salon_id: props.user.id
                })
            })

            const json = await response.json();

            if (json.ok > 0) {

                setMinOrder(parseInt(json.price_min));

                var tmp = json.products
                tmp = tmp.filter(x => x.quantity > 0);

                // sort
                tmp.sort((a, b) => {
                    if (a.label < b.label) {
                        return -1;
                    } else {
                        return 1;
                    }
                })
                // reindexing
                tmp = tmp.map(function (item, index) {
                    item.index = index;
                    return item;
                });

                setItems(tmp);


                Redraw(tmp);
            }
            setBusy(false);

        } catch (error) {
            Debug(error);
            setBusy(false);
        }
    }


    const db_booking_update = async (id, quantity, cost) => {

        var data = {
            salon_id: props.user.id,
            product_id: id,
            quantity: quantity,
            sended: false,
            booking_id: 0,
            quantity_original: quantity,
            cost: cost
        }

        try {
            const response = await fetch(
                global.db_url + 'booking_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data: data
                })
            })

            const json = await response.json();
            
            if (json.ok > 0) {
            }
        } catch (error) {
            Debug(error);
        }
    }


    const db_booking_send = async () => {
        if (isSending == false) {

            setSending(true);
            try {
                const response = await fetch(
                    global.db_url + 'booking_send', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        language: props.language.lang_id,
                        salon_id: props.user.id,
                        note: note
                    })
                })

                const json = await response.json();

                if (json.ok > 0) {
                    setSendError(false);
                    setSended(true);
                } else {
                    setSendError(true);
                }
                setSending(false);
            } catch (error) {
                Debug(error);
                setSending(false);
            }
        }
    }


    const Redraw = (data) => {
        var sum = 0.00;
        var quantity = 0;

        data.forEach(item => {
            let cost = parseFloat(item.price) / parseInt(item.unit);
            let q = parseInt(item.quantity);
            let cost_sum = cost * q;
            sum = sum + cost_sum;

            item.cost = cost.toFixed(2);
            item.cost_sum = cost_sum.toFixed(2);

            quantity = quantity + q;
        });

        setSum(sum.toFixed(2));
        setQuantity(quantity);
    }

    const Press = (typ) => {
        func(typ);
    }

    const AskSend = (status) => {
        // ODOSLAT OBJEDNAVKU
        if (status == true) {
            // LEN AK JE VYSKA OBJEDNAVKY VIAC AKO MINIMUM
            setShowAsk(true);
        }
    }


    const Send = () => {
        // ODOSLAT OBJEDNAVKU
        db_booking_send();
    }

    const ShowDetails = (item) => {
        setSelectedItem(item);
        setShowSelected(true);
    }

    const PlusMinusPress = (id, quantity) => {

        var item = items.find(x => x.id == id);
        if (item != '') {
            let cost = parseFloat(item.price) / parseInt(item.unit);
            let q = parseInt(quantity);
            let cost_sum = cost * q;

            item.cost = cost.toFixed(2);
            item.cost_sum = cost_sum.toFixed(2);
            item.quantity = quantity;

            Redraw(items);
            setRedraw(!redraw);
        }

        db_booking_update(id, quantity, item.price);

    }


    const AskDialogResult = (value) => {
        // ODOSLAT OBJEDNAVKU? 
        setShowAsk(false);
        if (value == true) {
            Send();
        }
    }


    const ProductResult = (typ, item, product_, quantity_) => {
        var tmp = items.find(x => x.id == product_.id);
        var old = 0;
        if (tmp != '') {
            old = tmp.quantity;
            tmp.quantity = quantity_;
            tmp.favourites = product_.favourites;
        }
        Redraw(items);
        if (old != quantity_) {
            db_booking_update(product_.id, quantity_, tmp.price);
        }
        setShowSelected(false);
    }


    /* *****************************************************************
    
        RENDER - HLAVNE OKNO
    
    ***************************************************************** */
    return (

        sended == true ?
            <Paper elevation={isSmall ? 0 : 3} style={{ ...styles.Block, maxWidth: boxWidth, backgroundColor: global.theme_white, border: '1px solid ' + global.theme_light_gray }}>
                <div style={{ ...styles.Block, width: '95%', marginTop: 50, marginBottom: 30 }}>
                    <div style={{ ...styles.BlockRound, width: 50, height: 50 }}>
                        <FontAwesomeIcon style={{ width: 35, height: 35, color: global.theme_white }} icon={faCheck} />
                    </div>
                    <p style={{ ...styles.TextLarge, marginTop: 30, textAlign: 'center' }}>{lang.order_sended}</p>
                    <div style={{ ...styles.Block, width: '50%', paddingTop: 40 }}>
                        <Button onClick={() => Press(true)} style={{ ...styles.ButtonBack, margin: 10 }}>{lang.close}</Button>
                    </div>
                </div>
            </Paper>
            :
            <Paper elevation={isSmall ? 0 : 3} style={{ ...styles.Block, maxWidth: boxWidth, backgroundColor: global.theme_white, border: isSmall ? '0px' : '1px solid ' + global.theme_light_gray }}>
                <div style={{ ...styles.BlockRow, width: '95%', marginTop: 20, marginBottom: 20 }}>
                    <div style={{ ...styles.BlockLeft }}>
                        <p style={{ ...styles.TextXXLarge, color: global.theme_dialog_label }}>{lang.booking_products}</p>
                    </div>
                    <div style={{ ...styles.BlockRight, width: 50 }}>
                        <IconButton onClick={() => Press(false)} style={{ ...styles.BlockRound, width: 30, height: 30, backgroundColor: global.theme_black, alignSelf: 'flex-end' }}>
                            <FontAwesomeIcon style={{ width: 20, height: 20, color: global.theme_white }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>

                <div style={{ ...styles.BlockLeft, width: '95%', paddingTop: 20, paddingBottom: 20 }}>
                    <p style={{ ...styles.TextSmall, color: global.theme_black }}>{lang.order_quantity}: {quantity}</p>
                    <p style={{ ...styles.TextSmall, color: global.theme_black }}>{lang.order_sum}: {sum} {lang.money}</p>
                </div>
                {items.length > 0 ?
                    <div style={{ ...styles.Block, width: isLarge ? '98%' : '100%', paddingTop: 20, paddingBottom: 20 }}>
                        <p style={{ ...styles.TextTiny, color: global.theme_dark_gray }}>{lang.product_list}</p>

                        {isSmall ? null :
                            <div style={{ ...styles.BlockRow, marginTop: 10, backgroundColor: global.theme_white }}>
                                <div style={{ ...styles.BlockLeft, width: '50%' }}>
                                    <p style={{ ...styles.TextTiny, marginLeft: boxHeight + 10, color: global.theme_medium_gray }}>{lang.product}</p>
                                </div>
                                <div style={{ ...styles.BlockRight, width: '50%' }}>
                                    <p style={{ ...styles.TextTiny, marginRight: boxHeight / 2, color: global.theme_medium_gray }}>{lang.order_product_sum}</p>
                                </div>
                            </div>
                        }

                        {/* ZOZNAM */}
                        {
                            items.map(item => (
                                isSmall ?
                                    <Paper key={item.id} elevation={0} style={{ ...styles.Block, marginTop: 10, marginBottom: 10, backgroundColor: global.theme_lighter, borderRadius: 5 }}>
                                        <div style={{ ...styles.BlockRow, paddingTop: 10 }}>
                                            <div onClick={() => ShowDetails(item)} style={{ ...styles.BlockLeft, width: boxHeight, cursor: 'pointer' }}>
                                                <img src={item.image == '' ? global.images + 'app/no-image.jpg' : global.products + '/' + item.image} style={{ width: boxHeight - 10, height: boxHeight - 10, borderRadius: (boxHeight - 10) / 2, marginLeft: 5 }}></img>
                                            </div>
                                            <div onClick={() => ShowDetails(item)} style={{ ...styles.BlockLeft, justifyContent: 'center', cursor: 'pointer' }}>
                                                <p style={{ ...styles.TextSmall, textAlign: 'left', fontWeight: 'normal', color: global.theme_black, marginLeft: 10 }}>{item.label}</p>
                                                <p style={{ ...styles.TextXXTiny, textAlign: 'left', color: global.theme_dark_gray, marginLeft: 10, marginTop: 2 }}>{item.code} {item.details}</p>
                                            </div>
                                        </div>
                                        <div style={{ ...styles.BlockRow, width: '95%', paddingTop: 20, paddingBottom: 10 }}>
                                            <div style={{ ...styles.BlockLeft, width: '30%', justifyContent: 'center' }}>
                                                <p style={{ ...styles.TextTiny, textAlign: 'left', color: global.theme_black, marginTop: 13 }}>{item.cost} {lang.money}</p>
                                            </div>
                                            <div style={{ ...styles.Block, width: '40%' }}>
                                                <PlusMinus language={props.language} quantity={item.quantity} editable={false} id={item.id} delay={true} pieces={item.unit} func={PlusMinusPress.bind(this)} />
                                            </div>
                                            <div style={{ ...styles.BlockRight, width: '30%', justifyContent: 'center' }}>
                                                <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', color: global.theme_black, marginTop: 13 }}>{item.cost_sum} {lang.money}</p>
                                            </div>
                                        </div>

                                    </Paper>
                                    :
                                    <Paper key={item.id} elevation={0} style={{ ...styles.BlockLeft, height: boxHeight, marginTop: 10, marginBottom: 10, backgroundColor: global.theme_lighter, borderRadius: boxHeight / 2 }}>
                                        <div style={{ ...styles.BlockRow }}>
                                            <div onClick={() => ShowDetails(item)} style={{ ...styles.Block, width: boxHeight + 0, height: boxHeight, cursor: 'pointer' }}>
                                                <img src={item.image == '' ? global.images + 'app/no-image.jpg' : global.products + '/' + item.image} style={{ width: boxHeight - 10, height: boxHeight - 10, borderRadius: (boxHeight - 10) / 2, marginLeft: 5 }}></img>
                                            </div>
                                            <div style={{ ...styles.BlockRow }}>
                                                <div onClick={() => ShowDetails(item)} style={{ ...styles.BlockLeft, width: '57%', justifyContent: 'center', height: boxHeight, cursor: 'pointer' }}>
                                                    <p style={{ ...styles.TextSmall, textAlign: 'left', fontWeight: 'normal', color: global.theme_black, marginLeft: 10 }}>{item.label}</p>
                                                    <p style={{ ...styles.TextXXTiny, textAlign: 'left', color: global.theme_dark_gray, marginLeft: 10, marginTop: 2 }}>{item.code} {item.details}</p>
                                                </div>
                                                <div style={{ ...styles.BlockRight, width: 80, justifyContent: 'center', height: boxHeight }}>
                                                    <p style={{ ...styles.TextTiny, textAlign: 'left', color: global.theme_black, marginRight: 10 }}>{item.cost} {lang.money}</p>
                                                </div>
                                                <div style={{ ...styles.Block, width: 130, height: boxHeight }}>
                                                    <PlusMinus language={props.language} quantity={item.quantity} editable={false} id={item.id} delay={true} pieces={item.unit} func={PlusMinusPress.bind(this)} />
                                                </div>
                                                <div style={{ ...styles.BlockRight, width: 80, justifyContent: 'center', height: boxHeight }}>
                                                    <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', color: global.theme_black, marginLeft: 10 }}>{item.cost_sum} {lang.money}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Paper>
                            ))
                        }
                        <div style={{ ...styles.Block, paddingTop: 10, paddingBottom: 10 }}>
                            <div style={{ ...styles.BlockRight }}>
                                <p style={{ ...styles.TextLarge, marginRight: 10 }}>{lang.order_total}: <b>{sum} {lang.money}</b></p>
                                <p style={{ ...styles.TextTiny, marginRight: 10, color: global.theme_medium_gray }}>{lang.with_dph}</p>
                            </div>
                        </div>
                    </div>
                    :
                    <div style={{ ...styles.Block, width: isLarge ? '98%' : '100%', paddingTop: 20, paddingBottom: 20 }}>
                        {isBusy == false ?
                            <p style={{ ...styles.TextNormal, fontWeight: 'bold', color: global.theme_black }}>{lang.order_no_products}!</p>
                            : null}
                    </div>
                }

                {
                    isBusy == false ?
                        <div style={{ ...styles.Block, paddingTop: 10, paddingBottom: 10 }}>
                            <div style={{ ...styles.BlockLeft, width: '90%' }}>
                                <p style={{ ...styles.TextTiny, fontWeight: 'bold', color: global.theme_black, marginBottom: 5 }}>{lang.order_note}!</p>
                            </div>
                            <TextField
                                rows={4}
                                multiline={true}
                                variant="outlined"
                                label={''}
                                value={note}
                                size={'small'}
                                style={{ width: '90%' }}
                                inputProps={{ maxLength: 1000 }}
                                onInput={e => setNote(e.target.value)}
                            />
                        </div>
                        : null
                }

                {
                    isBusy == false && items.length > 0 ?
                        <div style={{ ...styles.Block, paddingTop: 10, paddingBottom: 10 }}>
                            {sum >= minOrder ? false :
                                <div style={{ ...styles.BlockRowRaw }}>
                                    <FontAwesomeIcon style={{ width: 22, height: 22, color: global.theme_red, marginRight: 10 }} icon={faTriangleExclamation} />
                                    <p style={{ ...styles.TextSmall, fontWeight: 'bold', marginTop: 4 }}>{lang.order_min_price}: {minOrder} {lang.money}</p>
                                </div>
                            }
                        </div>
                        : null
                }

                {
                    sendError == true ?
                        <div style={{ ...styles.Block, paddingTop: 10, paddingBottom: 10 }}>
                            <div style={{ ...styles.BlockRowRaw }}>
                                <p style={{ ...styles.TextSmall, color: global.theme_red, fontWeight: 'bold', marginTop: 4 }}>{lang.order_error}!</p>
                            </div>
                        </div>
                        : null
                }

                {isBusy == false ?
                    <div style={{ ...isSmall ? styles.Block : styles.BlockRow, paddingTop: 10, paddingBottom: 10 }}>
                        <div style={{ ...isSmall ? styles.Block : styles.BlockLeft, width: isSmall ? '100%' : '50%' }}>
                            <Button onClick={() => Press(false)} style={{ ...styles.ButtonBack, margin: 10 }}>{lang.back}</Button>
                        </div>
                        <div style={{ ...isSmall ? styles.Block : styles.BlockRight, width: isSmall ? '100%' : '50%' }}>
                            <Button disabled={sum >= minOrder ? false : true} onClick={() => AskSend(sum >= minOrder ? true : false)} style={{ ...styles.ButtonSave, backgroundColor: sum >= minOrder ? global.theme_green : global.theme_light_green, width: 200, margin: 10 }}>{lang.order_send}</Button>
                        </div>
                    </div>
                    : null}

                {showSelected == true ?
                    <Product product={selectedItem} show_cost={true} user={props.user} booking={true} booking_quantity={selectedItem.quantity} language={lang} func={ProductResult.bind(this)} />
                    : null}

                {showAsk == true ?
                    <AskDialog label={lang.booking} question={lang.order_send + '?'} text={lang.order_send_text} button_text={lang.send} button_color={global.theme_green} func={AskDialogResult.bind(this)} />
                    : null}

                {isBusy == true || isSending == true ?
                    <Loading></Loading>
                    : null}

            </Paper >

    )
};

