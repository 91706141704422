// **********************************************************************

import { faBan, faBookOpen, faCheck, faCogs, faHourglass, faListCheck, faTruck, faXmark } from "@fortawesome/free-solid-svg-icons";

global.testing = false;

// **********************************************************************
// DATABAZA - LINK
// **********************************************************************
global.web = 'https://app.esthedermsalon.com';
global.db_url = global.web + '/app_admin/';
global.images = global.web + '/public/images/';
global.pdf = global.web + '/public/pdf/';
global.excel = global.web + '/public/excel/';
global.www = 'app.esthedermsalon.com';
global.products = 'https://www.kozmeticke-salony.sk';
global.ads = 'https://www.kozmeticke-salony.sk';

global.local_user = 'app_esthedermsalon_user';
global.local_login = 'app_esthedermsalon_login';
global.local_unique_id = 'app_esthedermsalon_uid';
global.local_settings = 'app_esthedermsalon_settings';
global.local_language = 'app_esthedermsalon_language';
global.local_unique_token = 'app_esthedermsalon_token';

// **********************************************************************

global.debugging = global.testing;
global.version = '1.0';
global.items_max = 20; // pagination - max.items na stránke
global.tabsize = 40;
global.max_width = 800;

// **********************************************************************

// TYPY UCTOV
global.account_typ_disable = 0;
global.account_typ_admin = 1;
global.account_typ_salon = 2;
global.account_typ_client = 3;
global.account_typ_public = 4;
global.account_typ_agent = 5;
global.account_typ_agent_admin = 6;
global.account_typ_salon_test = 7;

// **********************************************************************

// STATUS OBJEDNAVOK TOVARU NA SKLAD
global.booking_sended = 0;
global.booking_inproccess = 1;
global.booking_onway = 2;
global.booking_cancel_request = 3;
global.booking_finish = 5;
global.booking_cancel = 6;


// **********************************************************************
// COLORS
// **********************************************************************
global.theme_back = '#F4F5FA';
global.theme_white = '#ffffff';
global.theme_black = '#000000';
global.theme_black_opacity = '#000000bb';

global.theme_light = '#DAC7B8';
global.theme_medium = '#B29B89';
global.theme_light_blue = '#B8C8DA';
global.theme_lighter_blue = '#DEE6F0';
global.theme_light_red = '#FF9C98';
global.theme_light_green = '#B9DAB8';
global.theme_lighter = '#E7DAD0';
global.theme_dark = '#7B614D';
global.theme_darker = '#34271D';
global.theme_dark_blue = '#4D617B';
global.theme_dark_red = '#A12E28';
global.theme_dark_green = '#4D7B4D';
global.theme_blue = '#2089DC';
global.theme_red = 'red';
global.theme_green = 'green';

global.theme_yellow = '#F4B549';
global.theme_badge = '#A12E28';
global.theme_placeholder = '#cccccc';
global.theme_light_violet = '#FFAAFF';
global.theme_none = '#00000000';

global.theme_gray = '#eeeeee';
global.theme_grayer = '#f7f7f7';
global.theme_xxgray = '#aaaaaa';
global.theme_dark_gray = '#666666';
global.theme_medium_gray = '#888888';
global.theme_light_gray = '#dedede';
global.theme_menu = '#000000';
global.theme_menu_selected = '#aa0000';
global.theme_menu_background_selected = '#ffeeee';
global.theme_submenu = '#aaaaaa';
global.theme_categorymenu = '#ffffff';

global.theme_selected = '#4D617B';
global.theme_orange = 'orange';
global.theme_violet = 'violet';
global.theme_yellow = 'yellow';
global.theme_brown = 'brown';

global.theme_button = '#36666E';
global.theme_button_buy = '#F44336';
global.theme_button_link = '#209FB6';
global.theme_box_label = '#222222';
global.theme_box_label_over = '#AA3333';

global.theme_menu_categories = '#F44336';
global.theme_new_products = '#f0f0f0';

global.theme_footer_text = '#AAAAAA';
global.theme_footer_text_high = '#CCCCCC';
global.theme_footer = '#222222';

global.theme_product_new = '#4D7B4D';
global.theme_logo_background = '#B6B6E9';
global.theme_alert = '#AA0000';

// DIALOG
global.theme_dialog_back = 'linear-gradient(to right,#E6D2BF,#FCF4ED)';
global.theme_dialog_label = '#000000';

global.theme_gradient = 'linear-gradient(to right,#FFFFFF,#D9C6B9)';
global.theme_gradient_dark = 'linear-gradient(#7B614D,#DAC7B8)';
global.theme_menu_top_gradient = 'linear-gradient(to right,#E7DAD0,#EEEEEE)';
global.theme_gradient_dark_medium = 'linear-gradient(to right,#7B614D,#B29B89)';
global.theme_gradient_dark_dark = 'linear-gradient(to right,#7B614D,#7B614D)';
global.theme_gradient_gray_gray = 'linear-gradient(to right,#DDDDDD,#EFEFEF)';
global.theme_gradient_gray = 'linear-gradient(to right,#eeeeee,#eeeeee)';

// **********************************************************************
// DISPLAY SIZE
// **********************************************************************
global.device_small = 600;
global.device_medium = 992;
global.screen_max = 950;

// **********************************************************************
// FONT SIZE
// **********************************************************************
global.font_xxtiny = 12;
global.font_tiny = 14;
global.font_small = 16;
global.font_normal = 18;
global.font_large = 20;
global.font_xlarge = 24;
global.font_xxlarge = 28;
global.font_label = 28;
global.font_dialog_label = 24; // dialogove boxy - label
global.font_menu = 18; // menu - lava strana
global.font_textlabel = 13; // label pre TextField <p></p>
global.font_categorymenu = 14;
global.font_products_grid = 16;
global.font_banner_label = 36;
global.font_banner_text = 18;
global.font_banner_label_small = 18;
global.font_banner_text_small = 14;
global.font_prices = 22;
global.font_blog = 20;
// **********************************************************************
// RADIUS
// **********************************************************************
global.radius = 5;

// **********************************************************************
// MENU INDEX
// **********************************************************************
global.menu_padding = 40;
global.menu_button_width = 130;

global.menu_main = 0;
global.menu_width = 280;

global.menu_action = 1004;
global.menu_subaction = 9377;

global.creator = 'Datadream s.r.o.';
global.creator_web = 'https://datadream.sk';

// **********************************************************************
// IMAGE TYPS - upload image
// **********************************************************************
global.image_logo = 0;
global.image_reklamacia = 1;

// **********************************************************************
// TYPY FARIEB
// **********************************************************************
global.colors = [
    { id: 0, color: '#000000' },
    { id: 1, color: '#444444' },
    { id: 2, color: '#777777' },
    { id: 3, color: '#AAAAAA' },
    { id: 4, color: '#CCCCCC' },
    { id: 5, color: '#E3E5F0' },
    { id: 6, color: '#F4F5FA' },
    { id: 7, color: '#FFFFFF' },

    { id: 13, color: '#0000AA' },
    { id: 8, color: '#0000FF' },
    { id: 14, color: '#509DF4' },
    { id: 15, color: '#ADCCEF' },
    { id: 10, color: '#00FFFF' },
    { id: 12, color: '#8957F4' },
    { id: 9, color: '#FF00FF' },
    { id: 26, color: '#FFAAFF' },


    { id: 11, color: '#FF0000' },
    { id: 21, color: '#F44336' },
    { id: 25, color: '#D8532B' },
    { id: 24, color: '#F77B72' },
    { id: 16, color: '#FFDDDD' },
    { id: 12, color: '#FFAA77' },
    { id: 17, color: '#FFFF00' },
    { id: 18, color: '#FFFF77' },

    { id: 19, color: '#004400' },
    { id: 20, color: '#4D7B4D' },
    { id: 23, color: '#00AA00' },
    { id: 22, color: '#00FF00' },
    { id: 17, color: '#C0E0B8' },
]



// **********************************************************************
// SK - LANGUAGE
// **********************************************************************
export const sk = {
    lang_id: 0,

    title: 'Esthederm salon App',
    description: 'Esthederm Salon App',
    home: 'Domov',
    web: 'app.esthedermsalon.com',
    state: 'Slovenská republika',
    language: 'Jazyk',

    // **********************************************************************
    // STRUKTURA MENU
    // **********************************************************************
    menuitems: [
        {
            id: '1',
            enabled: true,
            name: 'Obchod',
            label: 'Správa tovaru',
            icon: faBookOpen,
            children: [
                {
                    id: '11',
                    enabled: true,
                    name: 'Nová objednávka',
                    label: 'Nová objednávka tovaru na sklad',
                },
                {
                    id: '12',
                    enabled: true,
                    name: 'História',
                    label: 'História objednávok tovaru na sklad',
                },

            ],
        },
        {
            id: '2',
            enabled: true,
            name: 'Nastavenia',
            label: 'Nastavenia webovej aplikácie',
            icon: faCogs,
            children: [
                {
                    id: '21',
                    enabled: true,
                    name: 'Môj účet',
                    label: 'Nastavenia účtu',
                },
            ],
        },
    ],

    months: ['Január', 'Február', 'Marec', 'Apríl', 'Máj', 'Jún', 'Júl', 'August', 'September', 'Október', 'November', 'December'],
    months_short: ['Jan.', 'Feb.', 'Mar.', 'Apr.', 'Máj.', 'Jún.', 'Júl.', 'Aug.', 'Sep.', 'Okt.', 'Nov.', 'Dec.'],
    days: ['Nedeľa', 'Pondelok', 'Utorok', 'Streda', 'Štvrtok', 'Piatok', 'Sobota'],
    days_short: ['Ned.', 'Pon.', 'Uto.', 'Str.', 'Štv.', 'Pia.', 'Sob.'],
    today: 'Dnes',

    degree: '°C',
    login: 'Prihlásenie',
    loginname: 'Prihlasovacie meno (e-mail)',
    login_data: 'Prihlasovacie údaje',
    username: 'Prihlasovacie meno',
    password: 'Heslo',
    password_last: 'Pôvodné heslo',
    password_last_error: 'Nesprávne pôvodné heslo',
    password_: 'Heslo (minimálna dĺžka 8 znakov)',
    password_new: 'Zadajte nové heslo (minimálne 8 znakov)',
    password_new_again: 'Zopakujte nové heslo',
    passwords_not_equal: 'Zadané heslá sa nezhodujú',
    password_ok: 'Heslo bolo úspešne zmenené',
    password_error: 'Pri zmene hesla nastala chyba. Skúste požiadavku opakovať.',
    password_min_len: 'Minimálna dĺžka hesla je 8 znakov',
    password_change: 'Zmeniť heslo',
    login_button: 'Prihlásiť sa',
    lost_password_button: 'Zabudli ste heslo?',
    login_error: 'Nesprávne prihlasovacie údaje',
    lost_password: 'Zabudnuté heslo',
    lost_password_error: 'Zadané heslo sa nenachádza v našom systéme',
    password_changed: 'Zmena hesla prebehla úspešne',

    register: 'Nová registrácia',
    register_button: 'Registrovať sa',
    register_btn: 'Registrovať',
    register_login: 'Prihlasovacie údaje do systému',
    register_ok: 'Registrácia je úspešne dokončená.',
    register_error: 'Pri registrácii nastala chyba. Skúste požiadavku opakovať.',
    forgot_password_email1: 'Zadajte Vašu e-mailovu adresu.',
    forgot_password_email2: 'My vám zašleme odkaz pre zmenu hesla',
    forgot_email_error: 'Zadaná e-mailová adresa už je registrovaná v našom systéme',

    required_data: 'Údaje označené * sú povinné',

    verify: 'Overiť',
    verification_code: 'Overovací kód',
    verification_code_enter: 'Overovací kód sme odoslali na Vašu e-mailovú adresu. Zadajte kód z Vášho e-mailu',
    verification_code_error: 'Nesprávny kód',
    verification_code_resend: 'Nedostali ste overovací kód? Odoslať kód.',

    user_data: 'Základné údaje',
    firm_data: 'Firemné údaje',
    contact_data: 'Kontaktné údaje',

    address: 'Adresa',
    company: 'Názov firmy',
    name: 'Meno',
    surname: 'Priezvisko',
    name_surname: 'Meno a priezvisko',
    street: 'Ulica',
    street_number: 'číslo',
    psc: 'PSČ',
    town: 'Mesto',
    email: 'E-mail',
    email_error: 'Nesprávny formát e-mailovej adresy',
    mobil: 'Mobil',
    country: 'Štát',
    logo: 'Logo',
    note: 'Poznámka',
    ico: 'IČO',
    dic: 'DIČ',
    ic_dph: 'IČ-DPH',
    dph: 'DPH',
    with_dph: 'cena s dph',
    birth_date: 'Dátum narodenia',
    birth_date_holder: 'd. m. r',


    required: 'Povinný údaj',
    username_min_len: 'Minimálna dĺžka prihlasovacieho mena musí byť 8 znakov',
    password_min_len: 'Minimálna dĺžka hesla musí byť 8 znakov',
    email_checking: 'Prebieha kontrola e-mailovej adresy',
    username_checking: 'Prebieha kontrola prihlasovacieho mena',
    password_exists: 'Zadaná e-mailová adresa už je zaregistrovaná. Použite inú adresu.',
    username_exists: 'Zadané prihlasovacie meno už používa iný účet. Použite iné meno',
    logout: 'Odhlásiť sa',
    logout_: 'Odhlásenie',
    logout_question: 'Chcete sa odhlásiť z aplikácie?',
    loged_user: 'Prihlásený užívateľ',

    pin: 'Bezpečnostný pin (6 miestny)',
    db_error: 'Pri zápise nastala chyba. Skúste požiadavku opakovať',
    send_error: 'Pri odosielaní požiadavky nastala chyba. Skúste požiadavku opakovať',


    active: 'Aktívny',
    inactive: 'Neaktívny',
    label: 'Názov',
    number: 'Číslo',
    new: 'Nový',
    add: 'Pridať',
    ok: 'OK',
    cancel: 'Zrušiť',
    save: 'Uložiť',
    save_changes: 'Uložiť zmeny',
    edit: 'Upraviť',
    search: 'Hľadať',
    send: 'Odoslať',
    send_request: 'Odoslať požiadavku',
    saving: 'Prebieha ukladanie ...',
    subscribe: 'Odoberať',
    userdata: 'Údaje užívateľa',
    change_logins: 'Zmena prihlasovacích údajov',
    new_username: 'Nové prihlasovacie meno',
    new_password: 'Nové heslo',
    username_exists: 'Zadané prihlasovacie meno už používa iný účet.',
    error: 'Chyba',
    finish: 'Dokončiť',
    continue: 'Pokračovať',
    back: 'Späť',
    close: 'Zavrieť',
    delete: 'Vymazať',
    choose: 'Vybrať',
    price: 'Cena',
    money: '€',
    date: 'Dátum',
    pieces: 'ks',
    search_text: 'Hľadaný reťazec',
    copyright: 'copyright',
    agent: 'Obchodný zástupca',
    agent_choose: 'Pred pokračovaním je potrebné zvoliť obchodného zástupcu Institut Esthederm',
    yes: 'Áno',
    no: 'Nie',
    help: 'Potrebujete poradiť?',
    helper: 'Nápoveda',
    help_label: 'Potrebujete poradiť s aplikáciou?',
    help_text: 'Napíšte nám a my Vám ochotne poradíme',
    help_mobil: 'hotline (mobil) +421 944 411 144',
    help_input: 'Text správy (Nemusíte zadávať meno, ani kontaktné údaje. Systém údaje doplní pri odosielaní.)',
    help_input_mobil: 'Text správy',
    help_error: 'Pri odosielaní požiadavky nastala chyba. Skúste požiadavku opakovať',

    // POLOZKY - DIALOG
    item_new: 'Nová položka',
    item_edit: 'Úprava položky',
    items_list: 'Zoznam položiek',
    item_show_enabled: 'Zobrazovať len aktívne položky',
    item_inactive: 'Položka je neaktívna',
    item_active: 'Aktívna',
    items_list_search_none: 'Žiadna položka sa nezhoduje s hľadaným výrazom!',

    // WELCOME - REGISTER
    welcome_register: 'Sprievodca registráciou',
    welcome_country: 'Krajina prevádzkovania kozmetického salónu',
    welcome_money: '€',
    welcome_phone: '+421',
    welcome_phone_helper: 'medzinárodný formát',
    welcome_contact: 'Kontaktná osoba',
    welcome_contact_text: 'Kontaktné údaje salóna',

    salon_address: 'Adresa salónu',
    salon_address_text: 'Názov a adresa prevádzkovania kozmetického salónu',
    salon_name: 'Názov salónu',

    firm_data: 'Fakturačné údaje',
    firm_data_text: 'Firemné údaje sú použité ako obchodné podmienky pre e-shop.',
    firm_name: 'Názov firmy',

    summaring: 'Zhrnutie',
    register_gdpr: 'Súhlasím so spracovaním osobných údajov',
    product_list: 'Zoznam produktov',

    // VYPNUTY UCET
    disabled_label: 'Pozor',
    disabled_info: 'Váš účet bol deaktivovaný administrátorom.',
    disabled_text: 'kontaktujte nás na emailovej adrese: info@esthedermsalon.com',
    disabled_button: 'Zavrieť',

    // NASTAVENIA
    sett_basic: 'Základné údaje',
    sett_firm: 'Firemné údaje',
    sett_eshop: 'E-shop',
    sett_orders: 'Objednávky',
    sett_system: 'Systémové nastavenia',
    sett_user: 'Kontaktná osoba',
    sett_agent: 'Obchodný zástupca',

    // SEKCIE
    sections: 'Kategórie',
    section_action: 'Akcia',
    section_cabin: 'Kabína',

    // STATUS
    order_status_name: ['Čaká na prijatie', 'V príprave', 'Odoslaná', 'Žiadosť o zrušenie', '', 'Ukončená', 'Zrušená'],
    order_status_icons: [faHourglass, faListCheck, faTruck, faBan, , faCheck, faXmark],
    order_status_colors: [global.theme_light_red, global.theme_light_red, global.theme_lighter, global.theme_gray, global.theme_lighter, global.theme_lighter, global.theme_gray],

    // BOOKING
    booking: 'Objednávka',
    booking_list: 'Zoznam objednávok',
    booking_products: 'Objednané produkty',
    order_price: 'Nákupná cena',
    order_preview: 'Náhľad objednávky',
    order_pieces: 'Objednať (ks)',
    order_sum: 'Suma za objednávku',
    order_quantity: 'Počet objednaných kusov',
    order_send: 'Odoslať objednávku',
    order_send_text: 'Objednávka bude odoslaná obchodnému zástupcovi',
    order_min_price: 'Minimálna hodnota objednávky musí byť',
    order_no_products: 'Objednávka je prázdna',
    order_note: 'Poznámka k objednávke',
    order_sended: 'Objednávka bola úspešne odoslaná',
    order_error: 'Pri odosielaní nastala chyba, skúste požiadavku opakovať',
    order_product_sum: 'Suma',
    order_total: 'Celková suma',
    order_status: 'Stav objednávky',
    order_items: 'Počet položiek',
    order_cancel: 'Zrušit objednávku',
    order_canceling: 'Zrušenie objednávky',
    order_cancel_question: 'Chcete zrušiť objednávku produktov na sklad?',
    order_cancel_text: 'Žiadosť o zrušenie objednávky bude odoslaná obchodnému zástupcovi.',
    order_reklamacia: 'Reklamácia',
    order_reklamacia_text: 'Text reklamácie',
    order_reklamacia_image: 'Priložiť obrázok',
    order_reklamacia_send_text: 'Reklamácia bude odoslaná obchodnému zástupcovi.',
    order_reklamacia_sended: 'Reklamácia bola úspešne odoslaná',
    order_reklamacia_error: 'Pri odosielaní nastala chyba, skúste požiadavku opakovať',
    order_reklamovat: 'Reklamovať',

    // PRODUKT
    product: 'Produkt',
    products: 'Produkty',
    favourites: 'Obľúbené produkty',
    favourite: 'Obľúbený produkt',
    favourite_add: 'Pridať k obľúbeným',

    newsletter: 'newsletter',
    newsletter_text: 'novinky Institut Esthederm',

    gdpr: 'Ochrana osobných údajov',
    gdpr_: 'GDPR',    
}


// **********************************************************************
// **********************************************************************
// CZ - LANGUAGE
// **********************************************************************
// **********************************************************************
export const cz = {
    lang_id: 1,

    title: 'Esthederm salon App',
    description: 'Esthederm Salon App',
    home: 'Domů',
    web: 'app.esthedermsalon.com',

    state: 'Česká republika',
    language: 'Jazyk',
    // **********************************************************************
    // STRUKTURA MENU
    // **********************************************************************
    menuitems: [
        {
            id: '1',
            enabled: true,
            name: 'Obchod',
            label: 'Správa zboží',
            icon: faBookOpen,
            children: [
                {
                    id: '11',
                    enabled: true,
                    name: 'Nová objednávka',
                    label: 'Nová objednávka zboží na sklad',
                },
                {
                    id: '12',
                    enabled: true,
                    name: 'Historie',
                    label: 'Historie objednávek zboží na sklad',
                },

            ],
        },
        {
            id: '2',
            enabled: true,
            name: 'Nastavení',
            label: 'Nastavení webové aplikace',
            icon: faCogs,
            children: [
                {
                    id: '21',
                    enabled: true,
                    name: 'Můj účet',
                    label: 'Nastavení účtu',
                },
            ],
        },
    ],

    months: ['Január', 'Február', 'Marec', 'Apríl', 'Máj', 'Jún', 'Júl', 'August', 'September', 'Október', 'November', 'December'],
    months_short: ['Jan.', 'Feb.', 'Mar.', 'Apr.', 'Máj.', 'Jún.', 'Júl.', 'Aug.', 'Sep.', 'Okt.', 'Nov.', 'Dec.'],
    days: ['Nedeľa', 'Pondelok', 'Utorok', 'Streda', 'Štvrtok', 'Piatok', 'Sobota'],
    days_short: ['Ned.', 'Pon.', 'Uto.', 'Str.', 'Štv.', 'Pia.', 'Sob.'],
    today: 'Dnes',

    degree: '°C',
    login: 'Přihlášení',
    loginname: 'Přihlašovací jméno (e-mail)',
    login_data: 'Přihlašovací údaje',
    username: 'Přihlašovací jméno',
    password: 'Heslo',
    password_: 'Heslo (minimální délka 8 znaků)',
    password_last: 'Původní heslo',
    password_last_error: 'Nesprávné původní heslo',
    password_new: 'Zadejte nové heslo (minimálně 8 znaků)',
    password_new_again: 'Zopakujte nové heslo',
    passwords_not_equal: 'Zadaná hesla se neshodují',
    password_ok: 'Heslo bylo úspěšně změněno',
    password_error: 'Při změně hesla nastala chyba. Zkuste požadavek opakovat.',
    password_min_len: 'Minimální délka hesla je 8 znaků',
    password_change: 'Změnit heslo',
    login_button: 'Přihlásit se',
    lost_password_button: 'Zapomněli jste heslo?',
    login_error: 'Nesprávné přihlašovací údaje',
    lost_password: 'Zapomenuté heslo',
    lost_password_error: 'Zadané heslo se nenachází v našem systému',
    password_changed: 'Změna hesla proběhla úspěšně',

    register: 'Nová registrace',
    register_button: 'Registrovat se',
    register_btn: 'Registrovat',
    register_login: 'Přihlašovací údaje do systému',
    register_ok: 'Registrace je úspěšně dokončena.',
    register_error: 'Při registraci nastala chyba. Zkuste požadavek opakovat.',
    forgot_password_email1: 'Zadejte Vaši e-mailovou adresu.',
    forgot_password_email2: 'My vám zašleme odkaz pro změnu hesla',
    forgot_email_error: 'Zadaná e-mailová adresa již je registrována v našem systému',

    required_data: 'Údaje označené * jsou povinné',

    verify: 'Ověřit',
    verification_code: 'Ověřovací kód',
    verification_code_enter: 'Ověřovací kód jsme odeslali na Vaši e-mailovou adresu. Zadejte kód z Vašeho e-mailu',
    verification_code_error: 'Nesprávný kód',
    verification_code_resend: 'Nedostali jste ověřovací kód? Odeslat kód.',

    user_data: 'Základní údaje',
    firm_data: 'Firemní údaje',
    contact_data: 'Kontaktní údaje',

    address: 'Adresa',
    company: 'Název firmy',
    name: 'Jméno',
    surname: 'Příjmení',
    name_surname: 'Jméno a příjmení',
    street: 'Ulice',
    street_number: 'číslo',
    psc: 'PSČ',
    town: 'Město',
    email: 'E-mail',
    email_error: 'Nesprávný formát e-mailové adresy',
    mobil: 'Mobil',
    country: 'Stát',
    logo: 'Logo',
    note: 'Poznámka',
    ico: 'IČO',
    dic: 'DIČ',
    ic_dph: 'IČ-DPH',
    dph: 'DPH',
    with_dph: 'cena s dph',
    birth_date: 'Datum narození',
    birth_date_holder: 'd. m. r',


    required: 'Povinný údaj',
    username_min_len: 'Minimální délka přihlašovacího jména musí být 8 znaků',
    password_min_len: 'Minimální délka hesla musí být 8 znaků',
    email_checking: 'Probíhá kontrola e-mailové adresy',
    username_checking: 'Probíhá kontrola přihlašovacího jména',
    password_exists: 'Zadaná e-mailová adresa je již zaregistrována. Použijte jinou adresu.',
    username_exists: 'Zadané přihlašovací jméno již používá jiný účet. Použijte jiné jméno',
    logout: 'Odhlásit se',
    logout_: 'Odhlášení',
    logout_question: 'Chcete se odhlásit z aplikace?',
    loged_user: 'Přihlášený uživatel',

    pin: 'Bezpečnostní pin (6 místný)',
    db_error: 'Při zápisu nastala chyba. Zkuste požadavek opakovat',
    send_error: 'Při odesílání požadavku nastala chyba. Zkuste požadavek opakovat',


    active: 'Aktivní',
    inactive: 'Neaktivní',
    label: 'Název',
    number: 'Číslo',
    new: 'Nový',
    add: 'Přidat',
    ok: 'OK',
    cancel: 'Zrušit',
    save: 'Uložit',
    save_changes: 'Uložit změny',
    edit: 'Upravit',
    search: 'Hledat',
    send: 'Odeslat',
    send_request: 'Odeslat požadavek',
    saving: 'Probíhá ukládání ...',
    subscribe: 'Odebírat',
    userdata: 'Údaje uživatele',
    change_logins: 'Změna přihlašovacích údajů',
    new_username: 'Nové přihlašovací jméno',
    new_password: 'Nové heslo',
    username_exists: 'Zadané přihlašovací jméno již používá jiný účet.',
    error: 'Chyba',
    finish: 'Dokončit',
    continue: 'Pokračovat',
    back: 'Zpět',
    close: 'Zavřít',
    delete: 'Vymazat',
    choose: 'Vybrat',
    price: 'Cena',
    money: 'Kč',
    date: 'Datum',
    pieces: 'ks',
    search_text: 'Hledaný řetězec',
    copyright: 'copyright',
    agent: 'Obchodní zástupce',
    agent_choose: 'Před pokračováním je třeba zvolit obchodního zástupce Institut Esthederm',
    yes: 'Ano',
    no: 'Nie',
    help: 'Potřebujete poradit?',
    helper: 'Nápověda',
    help_label: 'Potřebujete poradit s aplikací?',
    help_text: 'Napište nám a my Vám ochotně poradíme.',
    help_mobil: 'hotline (mobil) +421 944 411 144',
    help_input: 'Text zprávy (Nemusíte zadávat jméno, ani kontaktní údaje. Systém údaje doplní při odesílání.)',
    help_input_mobil: 'Text zprávy',
    help_error: 'Při odesílání nastala chyba, zkuste požadavek opakovat',

    // POLOZKY - DIALOG
    item_new: 'Nová položka',
    item_edit: 'Úprava položky',
    items_list: 'Seznam položek',
    item_show_enabled: 'Zobrazovat pouze aktivní položky',
    item_inactive: 'Položka je neaktivní',
    item_active: 'Aktivní',
    items_list_search_none: 'Žádná položka se neshoduje s hledaným výrazem!',

    // WELCOME - REGISTER
    welcome_register: 'Průvodce registrací',
    welcome_country: 'Země provozování kosmetického salonu',
    welcome_money: 'Kč',
    welcome_phone: '+420',
    welcome_phone_helper: 'mezinárodní formát',
    welcome_contact: 'Kontaktní osoba',
    welcome_contact_text: 'Kontaktní údaje salonu',

    salon_address: 'Adresa salonu',
    salon_address_text: 'Název a adresa provozování kosmetického salonu',
    salon_name: 'Název salonu',

    firm_data: 'Fakturační údaje',
    firm_data_text: 'Firemní údaje jsou použity jako obchodní podmínky pro e-shop.',
    firm_name: 'Název firmy',

    summaring: 'Shrnutí',
    register_gdpr: 'Souhlasím se zpracováním osobních údajů',

    product_list: 'Seznam produktů',

    // VYPNUTY UCET
    disabled_label: 'Pozor',
    disabled_info: 'Váš účet byl deaktivován administrátorem.',
    disabled_text: 'kontaktujte nás na emailové adrese: info@esthedermsalon.com',
    disabled_button: 'Zavřít',

    // NASTAVENIA
    sett_basic: 'Základní údaje',
    sett_firm: 'Firemní údaje',
    sett_eshop: 'E-shop',
    sett_orders: 'Objednávky',
    sett_system: 'Systémová nastavení',
    sett_user: 'Kontaktní osoba',
    sett_agent: 'Obchodní zástupce',

    // SEKCIE
    sections: 'Kategorie',
    section_action: 'Akce',
    section_cabin: 'Kabina',

    // BOOKING
    booking: 'Objednávka',
    booking_list: 'Zoznam objednávok',
    booking_products: 'Objednané produkty',
    order_price: 'Nákupní cena',
    order_preview: 'Náhled objednávky',
    order_pieces: 'Objednat (ks)',
    order_sum: 'Částka za objednávku',
    order_quantity: 'Počet objednaných kusů',
    order_send: 'Odeslat objednávku',
    order_send_text: 'Objednávka bude odeslána obchodnímu zástupci',
    order_min_price: 'Minimální hodnota objednávky musí být',
    order_no_products: 'Objednávka je prázdná',
    order_note: 'Poznámka k objednávce',
    order_sended: 'Objednávka byla úspěšně odeslána',
    order_error: 'Při odesílání nastala chyba, zkuste požadavek opakovat',
    order_product_sum: 'Suma',
    order_total: 'Celková částka',
    order_status: 'Stav objednávky',
    order_items: 'Počet položek',
    order_cancel: 'Zrušit objednávku',
    order_canceling: 'Zrušení objednávky',
    order_cancel_question: 'Chcete zrušit objednávku produktů na sklad?',
    order_cancel_text: 'Žádost o zrušení objednávky bude odeslána obchodnímu zástupci.',
    order_reklamacia: 'Reklamace',
    order_reklamacia_text: 'Text reklamace',
    order_reklamacia_image: 'Přiložit obrázek',
    order_reklamacia_send_text: 'Reklamace bude odeslána obchodnímu zástupci.',
    order_reklamacia_sended: 'Reklamace byla úspěšně odeslána',
    order_reklamacia_error: 'Při odesílání nastala chyba, zkuste požadavek opakovat',
    order_reklamovat: 'Reklamovat',


    // STATUS
    order_status_name: ['Čeká na přijetí', 'V přípravě', 'Odeslaná', 'Žádost o zrušení', '', 'Ukončena', 'Zrušeno'],
    order_status_icons: [faHourglass, faListCheck, faTruck, faBan, , faCheck, faXmark],
    order_status_colors: [global.theme_light_red, global.theme_light_red, global.theme_lighter, global.theme_gray, global.theme_lighter, global.theme_lighter, global.theme_gray],

    // PRODUKT
    product: 'Produkt',
    products: 'Produkty',
    favourites: 'Oblíbené produkty',
    favourite: 'Oblíbený produkt',
    favourite_add: 'Přidat k oblíbeným',

    newsletter: 'newsletter',
    newsletter_text: 'novinky Institut Esthederm',

    gdpr: 'Ochrana osobních údajů',    
    gdpr_: 'GDPR',    
}



