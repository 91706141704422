import React, { useEffect, useState, useRef } from 'react';
import { Routes, Route, Link, useNavigate, Navigate, useLocation, createRoutesFromChildren } from "react-router-dom";
import { useMediaQuery } from 'react-responsive'
import { sk, cz } from './globals.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faList, faAngleLeft, faAngleRight, faClose, faEdit, faPaperPlane, faPhone, faCheck, faXmark, faCircleDot, faDotCircle, faCircle, faBackward, faHome, faUser, faSearch, faRightFromBracket, faAngleDown, faTurnUp, faArrowTurnRight, faFloppyDisk, faImage, faArrowDown, faArrowUp, faFolderTree, faCircleDown, faChevronDown, faChevronRight, faChevronUp, faCircleCheck, faXmarkCircle, faArrowLeft, faSquare, faPrint, faChevronLeft, faBars, faHeart, faInfoCircle, faWalking, faWarning } from '@fortawesome/free-solid-svg-icons'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/system';
import { faEnvelope, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'
import { Drawer, Slide } from '@mui/material';
//import Slider from "react-slick";
import { Badge } from '@mui/material';
import { Alert, Autocomplete, Button, IconButton, MenuItem, Select, TextField, Paper } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { InputLabel } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import LoadingButton from '@mui/lab/LoadingButton';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme2-6.css";
import { styled } from '@mui/material/styles';
import { styles } from './styles.js';
import Tooltip from '@mui/material/Tooltip';
import Fab from '@mui/material/Fab';
import LinesEllipsis from 'react-lines-ellipsis'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Pagination from '@mui/material/Pagination';
import Chip from '@mui/material/Chip';
import AlertTitle from '@mui/material/AlertTitle';
import Collapse from '@mui/material/Collapse';
import Fade from '@mui/material/Fade';
import { Rules } from './rules.js';



export const Init = () => {
    //localStorage.removeItem('settings');
}

/*
export const SaveLanguage = (lang) => {
    localStorage.setItem(global.local_language, lang);
}


export const LoadLanguage = () => {
    var lang = localStorage.getItem(global.local_language);
    if (lang != null) {
        var result = sk;

        if (lang == 0) {
            result = sk;
        }
        if (lang == 1) {
            result = cz;
        }
        return result;
    } else {
        return sk;
    }
}
*/

export const GetLanguage = (lang) => {
    var result = sk;
    if (lang == 0) {
        result = sk;
    }
    if (lang == 1) {
        result = cz;
    }
    return result;
}

// ********************************************************
export const SaveToken = (data) => {
    if (data == null) {
        localStorage.removeItem(global.local_unique_token);
    } else {
        localStorage.setItem(global.local_unique_token, data);
    }
}

export const LoadToken = () => {
    var data = localStorage.getItem(global.local_unique_token);

    if (data != null) {
        return (data);
    } else {
        return null;
    }
}

// ********************************************************
export const Debug = (label, data) => {
    if (global.debugging == true) {
        var dt = data == undefined ? '' : data;
        if (dt == '') {
            console.log(label);
        } else {
            console.log(label, dt);
        }
    }
}

export function ValidateEmail(mail) {
    // kontrola správnosti e-mailovej adresy
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/.test(mail)) {
        return (true)
    }
    return (false)
};


export const Menu = (props) => {
    /*
            MENU - na lavej strane

            USAGE:
            <Menu func={MenuPress.bind(this)} />

            const MenuPress = (value) => {
                Debug(value)
            }

    */

    const [index, setIndex] = useState(0);
    const [childrenIndex, setChildrenIndex] = useState(0);
    const [openAcc, setOpenAcc] = useState(-1);
    const [menu, setMenu] = useState([]);


    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    //const logo = require('./react/app/logo_white.png');

    const boxHeight = 25;

    var lang = props.lang;
    let { func } = props;
    const navigate = useNavigate();


    useEffect(() => {

        setMenu(lang.menuitems);
        if (props.menu_id == 0) {
            setChildrenIndex(-1);
            setOpenAcc(-1);
        }
        return () => {
        };

    }, [props.menu_id, props.lang]);

    const Press = (type, id, item) => {
        func(type, parseInt(id));
    }

    const Click = (id, children_id, item) => {

        if (id == index && children_id == 0) {
            setIndex(0);
            setChildrenIndex(0);

            Press(0, 0, 0);
        } else {
            setIndex(id);
            setChildrenIndex(children_id);

            Press(id, children_id, item)
        }
    }

    const SetOpenAcc = (id) => {
        if (openAcc == id) {
            setOpenAcc(-1);
        } else {
            setOpenAcc(id);
        }
    }

    const GoHome = () => {
        setChildrenIndex(-1);
        setOpenAcc(-1);
        Press(0, 0, 0);
    }

    const Submenu = (props) => {
        var children = props.item;

        return (
            <div style={{ width: '95%' }}>
                {
                    /* ------------------------------------------------
                        SUBMENU - CHILDRENS
                    ------------------------------------------------ */
                }
                {children != null ?
                    children.map(item => {
                        return (
                            item.enabled == true ?
                                <Button key={item.id} onClick={() => Click(props.menu_id, item.id, item)} style={{ ...styles.ButtonMenu, width: global.menu_width - 20, background: childrenIndex == item.id ? global.theme_gradient : global.theme_dark }}>
                                    <FontAwesomeIcon style={{ width: childrenIndex == item.id ? 15 : 8, marginRight: 10, marginLeft: 15, color: childrenIndex == item.id ? global.theme_light : global.theme_medium }} icon={childrenIndex == item.id ? faChevronRight : faCircle} />
                                    <div style={{ ...styles.BlockLeft }}>
                                        <p style={{ ...styles.TextTiny, color: childrenIndex == item.id ? global.theme_dark : global.theme_lighter, fontWeight: childrenIndex == item.id ? 'bold' : 'normal' }}>
                                            {item.name}
                                        </p>
                                    </div>
                                </Button >
                                : null
                        )
                    })
                    : null
                }

            </div >
        )
    }

    return (
        <Paper elevation={10} style={{ ...styles.BlockMenu, height: props.height }}>

            <div style={{ ...styles.Block, height: isSmall ? 50 : 70, backgroundColor: global.theme_darker }}>
                <img onClick={() => GoHome()} src={global.images + 'app/logo_label.png'} style={{ maxWidth: 250, cursor: 'pointer' }}></img>
            </div>


            {/* LOGO */}
            <div style={{ ...styles.Block, paddingTop: 20, paddingBottom: 20, backgroundColor: global.theme_light, width: global.menu_width }}>
                <div style={{ ...styles.Block }}>
                    <img onClick={() => GoHome()} src={global.images + 'app/photo_circle.png'} style={{ maxWidth: isSmall ? 80 : 160, cursor: 'pointer' }}></img>
                    <p style={{ ...styles.TextNormal, marginTop: 15, color: global.theme_black, textAlign: 'center' }}>{props.company}</p>
                    <p style={{ ...styles.TextTiny, marginTop: 3, color: global.theme_dark, textAlign: 'center' }}>{props.email}</p>
                </div>
            </div>

            {/* HOME BUTTON */}
            <Button onClick={() => GoHome()} style={{ ...styles.ButtonMenu, marginBottom: 10, marginTop: 20 }}>
                <FontAwesomeIcon style={{ width: 20, height: 20, marginRight: 10, marginLeft: 0, color: global.theme_light_gray }} icon={faHome} />
                <div style={{ ...styles.BlockLeft }}>
                    <p style={{ ...styles.TextMenu, color: global.theme_white, marginLeft: 5 }}>
                        {lang.home}
                    </p>
                </div>
            </Button >

            {/* MENU */
                menu.map(item => (
                    <Accordion key={item.id} elevation={0} expanded={openAcc == item.id ? true : false} onChange={() => SetOpenAcc(item.id)} style={{ ...styles.BlockLeft, width: '100%', backgroundColor: global.theme_dark }}>
                        <AccordionSummary expandIcon={<FontAwesomeIcon style={{ width: 14, height: 14, color: global.theme_medium }} icon={faChevronUp} />}>
                            <div style={{ ...styles.Block, width: 30, height: boxHeight }}>
                                <FontAwesomeIcon style={{ width: 16, height: 16, color: global.theme_white }} icon={item.icon} />
                            </div>
                            <div style={{ ...styles.BlockLeft, justifyContent: 'center', height: boxHeight, width: global.menu_width - 125 }}>
                                <p style={{ ...styles.TextMenu, color: global.theme_white, marginLeft: 10, textAlign: 'left', letterSpacing: 0.4 }}>{item.name}</p>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails style={{ ...styles.BlockLeft, width: '100%', margin: 0, padding: 0 }}>
                            <Submenu item={item.children} menu_id={item.id} />
                        </AccordionDetails>
                    </Accordion>
                ))
            }
        </Paper>
    )

};



export const MenuTop = (props) => {
    /*
            MENU - horná lišta

            USAGE:
            <MenuTop func={MenuTopPress.bind(this)} />

            const MenuTopPress = (value) => {
                Debug(value)
            }

    */
    const [isOpen, setOpen] = useState(false);


    const [loading, setLoading] = React.useState(false);
    const [products, setProducts] = React.useState([]);
    const [err, setErr] = React.useState('');
    const [showLogout, setShowLogout] = useState(false);

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const navigate = useNavigate();
    const location = useLocation();

    //const logo = require('./react/app/logo.png');

    let { func } = props;
    var lang = props.lang;

    const BarHeight = 70;
    const BarHeightSmall = 50;

    useEffect(() => {

        return () => {
        };

    }, [props.menu_id, props.label, props.lang]);

    const Press = (type, value, item) => {
        func(type, value);
    }

    const Logout = () => {
        setShowLogout(true);
    }

    const AskLogoutResult = (value) => {
        if (value == true) {
            var today = new Date();
            var data = { logged: false, date: today };
            SaveToken(null);
            navigate('/login');
        } else {
            setShowLogout(false);
        }
    }

    const User = () => {
        Press(999, null);
    }

    const OpenMenu = () => {
        setOpen(true);
    }

    const CloseMenu = () => {
        setOpen(false);
    }

    const ChangeMenu = (id) => {
        setOpen(false);

    }

    const MenuPress = (id, children_id, item) => {
        setOpen(false);
        func(id, children_id, item);
    }

    return (
        <div style={{ ...styles.BlockRow, background: global.theme_menu_top_gradient, height: isSmall ? BarHeightSmall : BarHeight, borderBottom: '1px solid ' + global.theme_light_gray }}>
            {isSmall ?
                <div style={{ ...styles.BlockRowRaw, width: 100, justifyContent: 'flex-start', alignItems: 'center', height: isSmall ? BarHeightSmall : BarHeight, paddingLeft: 10 }}>
                    <IconButton onClick={() => OpenMenu()} style={{ ...styles.Block, height: 40, width: 40 }}>
                        <FontAwesomeIcon style={{ width: 20, height: 20, color: global.theme_black }} icon={faBars} />
                    </IconButton>
                    <IconButton onClick={() => Press(0)} style={{ ...styles.Block, height: 40, width: 40 }}>
                        <FontAwesomeIcon style={{ width: 20, height: 20, color: global.theme_dark }} icon={faHome} />
                    </IconButton>

                    <Drawer
                        anchor={'left'}
                        open={isOpen}
                        onClose={CloseMenu}
                    >
                        <div style={{ ...styles.BlockCenter, backgroundColor: global.theme_darker, width: global.menu_width, height: '100%' }}>
                            <div style={{ ...styles.BlockLeft }}>
                                <IconButton onClick={() => CloseMenu()} style={{ ...styles.Block, height: 40, width: 40 }}>
                                    <FontAwesomeIcon style={{ color: global.theme_white }} icon={faXmark} />
                                </IconButton>
                            </div>
                            <Menu lang={props.lang} typ={props.typ} company={props.company} height={props.height} email={props.email} func={MenuPress.bind(this)} />
                        </div>
                    </Drawer>
                </div>
                :
                <div style={{ ...styles.BlockLeft, width: '50%', justifyContent: 'center', alignItems: 'flex-start', height: BarHeight, paddingLeft: 10 }}>
                    {props.label != null ?
                        <p style={{ ...styles.TextNormal, fontWeight: 'bold', color: global.theme_dark_gray }}>{props.label}</p>
                        :
                        null
                    }
                </div>
            }

            <div style={{ ...styles.BlockRight, width: isSmall ? '100%' : '50%', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', height: isSmall ? BarHeightSmall : BarHeight, paddingRight: 10 }}>
                <div style={{ display: 'flex' }}>
                    <Button onClick={() => User()} style={{ textTransform: 'none', fontWeight: 'normal', margin: 0 }} >
                        {isSmall ? null :
                            <FontAwesomeIcon style={{ width: 20, height: 20, marginRight: 10, color: global.theme_dark_blue }} icon={faUser} />
                        }
                        <div>
                            <p style={{ ...styles.TextSmall, color: global.theme_dark_gray }}>{props.name} {props.surname}</p>
                        </div>
                    </Button>
                    <IconButton onClick={() => Logout()} style={{ width: 35, height: 35, borderRadius: 0, marginLeft: 10 }}>
                        <FontAwesomeIcon style={{ width: 16, height: 16, color: global.theme_black }} icon={faRightFromBracket} />
                    </IconButton>
                </div>
            </div>

            {showLogout == true ?
                <AskLogout lang={lang} func={AskLogoutResult.bind(this)} />
                : null}

        </div>
    )

};



export const Loading = (props) => {
    /*
        USAGE: 
                <Loading />
    */


    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });


    const logo = require('./react/app/logo_small.png');

    return (
        <Backdrop sx={{ color: '#000000', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true} onClick={null} style={{ backgroundColor: '#00000000' }}>
            <div style={{ ...styles.Block, marginLeft: isSmall ? 0 : props.center != undefined && props.center == true ? 0 : global.menu_width - 40, width: 80, height: 80, backgroundColor: global.theme_white, borderRadius: 40 }}>
                <img src={logo} style={{ width: 50, height: 50 }}></img>
                <CircularProgress style={{ position: 'absolute', color: global.theme_light }} size={70} />
            </div>
        </Backdrop>);
}



export const PlusMinus = (props) => {
    /*

            USAGE:
            <PlusMinus quantity={quantity} editable={false} delay={true} id={item.id} pieces={1} func={PlusMinusPress.bind(this)} />

            const PlusMinusPress = (id,quantity) => {                
            }

            delay - opozdenie - true / false
            pieces - kolko kusov je v baleni
    */


    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const [quantity, setQuantity] = useState(1);

    var lang = props.language;
    let { func } = props;
    const timer = useRef();


    useEffect(() => {
        setQuantity(parseFloat(props.quantity));

        return () => {
        };

    }, [props.quantity]);

    const Press = (quantity) => {
        func(props.id, quantity);
    }

    const ChangeQuantity = (value) => {
        var old = parseInt(quantity);
        var q = old;
        var pieces = props.pieces == undefined ? 1 : props.pieces;
        q = q + parseInt((value * parseInt(pieces)));
        if (q < 0) {
            q = 0;
        }
        setQuantity(q);

        if (props.delay == true) {
            if (old != q) {
                // opozdovacia rutina
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                    Press(q);
                }, 750);
            }
        } else {
            // bez opozdenia
            Press(q);
        }
    }

    const EnterQuantity = (text) => {
        if (/^\d+$/.test(text)) {
            setQuantity(text);
        }
        if (text.trim() == '') {
            //setQuantity(text);
            text = '1';
        }
        if (parseInt(text) < 1) {
            text = '1';
            setQuantity(text);
        }
        Press(parseInt(text));
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'row', width: props.editable ? 150 : 125, justifyContent: 'flex-end' }}>
            <div style={{ display: 'flex', flexDirection: 'row', height: 40, justifyContent: 'center', alignItems: 'center', borderRadius: 4 }}>
                <IconButton onClick={() => ChangeQuantity(-1)} style={{ margin: 0, width: 35, height: 35, backgroundColor: global.theme_medium, marginRight: 5 }}>
                    <FontAwesomeIcon style={{ width: 14, height: 14, marginRight: 0, color: global.theme_white }} icon={faMinus} />
                </IconButton>
                <div style={{ ...styles.Block, height: 40, width: props.editable ? 65 : 40 }}>
                    {props.editable == true ?
                        <TextField
                            style={{ margin: 0, width: 65, border: '0px', textAlign: 'right' }}
                            variant="outlined"
                            label={''}
                            value={quantity}
                            size={'small'}
                            onInput={e => EnterQuantity(e.target.value)}
                        />
                        :
                        <div style={{ ...styles.BlockRowRaw, marginTop: 1 }}>
                            <p style={{ ...styles.TextSmall, color: quantity == 0 ? global.theme_medium : global.theme_black }}>{quantity}</p>
                            <p style={{ ...styles.TextXXTiny, color: quantity == 0 ? global.theme_medium : global.theme_black, marginLeft: 3, marginTop: 3 }}>{lang.pieces}</p>
                        </div>
                    }
                </div>
                <IconButton onClick={() => ChangeQuantity(1)} style={{ margin: 0, width: 35, height: 35, backgroundColor: global.theme_medium, marginLeft: 5 }}>
                    <FontAwesomeIcon style={{ width: 14, height: 14, marginRight: 0, color: global.theme_white }} icon={faPlus} />
                </IconButton>
            </div>
        </div>
    )

};


export const UserData = (props) => {
    /*

            USAGE:
            <UserData user={user} func={UserDataPress.bind(this)} />

            const UserDataPress = (value) => {
                Debug(value)
            }

    */


    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const [isBusy, setBusy] = useState(false);
    const [name, setName] = useState('');
    const [nameErr, setNameErr] = useState('');
    const [surname, setSurname] = useState('');
    const [surnameErr, setSurnameErr] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [passwordLast, setPasswordLast] = useState('');
    const [error, setError] = useState('');

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    var lang = props.language;
    let { func } = props;

    useEffect(() => {
        setName(props.user.name);
        setSurname(props.user.surname);
        setUsername(props.user.username);

        return () => {
        };

    }, []);

    const Press = (id, data) => {
        func(id, data);
    }

    const db_update = async () => {

        if (isBusy == false) {
            setBusy(true);

            var data = {
                name: name,
                surname: surname,
            }

            if (username.trim() != '') {
                data.username = username;
                data.email = username;
            }

            if (password.trim() != '') {
                data.password = password.trim();
                data.password_last = passwordLast.trim();
            } else {
                data.password = '';
                data.password_last = '';
            }

            try {
                const response = await fetch(
                    global.db_url + 'login_update', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        id: props.user.id,
                        data: data
                    })
                })

                const json = await response.json();
                setBusy(false);

                if (json.ok > 0) {
                    if (password.trim() != '' && json.password_change == false) {
                        setError(lang.password_last_error);
                    } else {
                        data.id = props.user.id;
                        data.typ = props.user.typ;
                        data.enabled = props.user.enabled;
                        data.company_id = props.user.company_id;
                        Press(true, data);
                    }
                }

            } catch (error) {
                Debug(error);
                setBusy(false);
            }
        }
    };

    const db_check_email = async () => {

        if (isBusy == false) {
            setBusy(true);
            try {
                const response = await fetch(
                    global.db_url + 'check_email', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        id: props.user.id,
                        username: username
                    })
                })

                const json = await response.json();
                setBusy(false);

                if (json.ok > 0) {
                    if (json.count > 0) {
                        setError(lang.password_exists);
                    } else {
                        db_update();
                    }
                }

            } catch (error) {
                Debug(error);
                setBusy(false);
            }
        }
    };


    const Save = () => {
        var error = '';

        setNameErr('');
        setSurnameErr('');
        setError('');

        if (name.trim() == '') {
            setNameErr(lang.required);
            error = lang.required;
        }

        if (surname.trim() == '') {
            setSurnameErr(lang.required);
            error = lang.required;
        }

        if (ValidateEmail(username) == false) {
            error = lang.email_error;
        }

        if (error != '') {
            setError(error);
        } else {
            db_check_email();
        }

    }


    return (
        <Dialog open={true} maxWidth={'lg'} fullScreen={isSmall ? true : false} >
            <div style={{ ...styles.BlockRow, background: global.theme_dialog_back, height: 50, justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ width: '100%', paddingLeft: 10 }}>
                    <p style={{ ...styles.TextLarge, color: global.theme_dialog_label }}>{lang.userdata}</p>
                </div>
                <div style={{ width: 50 }}>
                    <IconButton onClick={() => Press(false, false)} style={{ ...styles.BlockRound, width: 30, height: 30, backgroundColor: global.theme_black, alignSelf: 'flex-end' }}>
                        <FontAwesomeIcon style={{ width: 20, height: 20, color: global.theme_white }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>

            <div style={{ ...styles.Block, minWidth: 250, width: isSmall ? '100%' : 750, padding: isSmall ? 0 : 20, paddingTop: 20, paddingBottom: 20 }}>
                {/* MENO, PRIEZVISKO */}
                <div style={{ ...isSmall ? styles.Block : styles.BlockRow, width: isSmall ? '90%' : undefined }}>
                    <div style={{ ...styles.BlockLeft, width: isSmall ? '100%' : '50%', marginRight: isSmall ? 0 : 10 }}>
                        <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>{lang.name}</p>
                        <TextField error={nameErr == '' ? false : true} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" size={'small'} value={name} onInput={e => setName(e.target.value)} />
                    </div>
                    <div style={{ ...styles.BlockLeft, width: isSmall ? '100%' : '50%', marginLeft: isSmall ? 0 : 10, marginTop: isSmall ? 20 : 0 }}>
                        <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>{lang.surname}</p>
                        <TextField error={surnameErr == '' ? false : true} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" size={'small'} value={surname} onInput={e => setSurname(e.target.value)} />
                    </div>
                </div>

                {/* USERNAME, PASSWORD */}
                <div style={{ ...styles.Block, marginTop: 30 }}>
                    <p style={{ ...styles.TextNormal }}>{lang.change_logins}</p>
                </div>
                <div style={{ ...isSmall ? styles.Block : styles.BlockRow, marginTop: 30, width: isSmall ? '90%' : undefined }}>
                    <div style={{ ...styles.BlockLeft, width: isSmall ? '100%' : '50%', marginRight: isSmall ? 0 : 10 }}>
                        <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>{lang.email}</p>
                        <TextField style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" size={'small'} value={username} onInput={e => setUsername(e.target.value)} />
                    </div>
                    <div style={{ ...styles.BlockLeft, width: isSmall ? '100%' : '50%', marginLeft: isSmall ? 0 : 10, marginTop: isSmall ? 20 : 0 }}>
                        <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>{lang.password_last}</p>
                        <TextField type={'password'} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" size={'small'} value={passwordLast} onInput={e => setPasswordLast(e.target.value)} />
                    </div>
                    <div style={{ ...styles.BlockLeft, width: isSmall ? '100%' : '50%', marginLeft: isSmall ? 0 : 10, marginTop: isSmall ? 20 : 0 }}>
                        <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>{lang.new_password}</p>
                        <TextField type={'password'} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" size={'small'} value={password} onInput={e => setPassword(e.target.value)} />
                    </div>
                </div>

            </div>

            <div style={{ ...styles.Block, height: isSmall ? undefined : 110, marginTop: isSmall ? 20 : 0 }}>
                <div style={{ height: isSmall ? undefined : 50 }}>
                    {isBusy == true ?
                        <CircularProgress color="inherit" size={20} />
                        : error != '' ?
                            <Alert severity="error">
                                {error}
                            </Alert>
                            : null
                    }
                </div>
                <div style={{ ...isSmall ? styles.Block : styles.BlockRowRaw }}>
                    <Button onClick={() => Save()} style={{ ...styles.ButtonSave, margin: 10 }}>{lang.save}</Button>
                    <Button onClick={() => Press(false, false)} style={{ ...styles.ButtonBack, margin: 10 }}>{lang.cancel}</Button>
                </div>
            </div>
        </Dialog >
    )

};


export const UniqueID = () => {
    let s4 = () => { return Math.floor((1 + Math.random() * 0x10000)).toString(16).substring(1); }
    return (Date.now() + '-' + s4() + s4() + s4() + '-' + s4() + s4() + s4());
}


export const GetPriceRound = (price_) => {
    // VRATI zaokrúhlenú cenu
    var tmp = parseFloat(price_).toFixed(2);
    return tmp;
}

export const GetPrice = (price_) => {
    // VRATI CENU NAFORMATOVANU 0,00
    var tmp = GetPriceRound(price_).toString();;
    return tmp.replace('.', ',');
}

export const GetCost = (b2b, cost, coeficient) => {
    // VYPOCET CENY S A BEZ DPH - podla toho ci je zákazník B2B
    if (b2b == true) {
        // B2B
        return cost;
    }
    if (b2b == false) {
        // KONCOVY ZAKAZNIK
        return (cost * coeficient);
    }
}

export const GetSume = () => {

    return 0;
}


export const ChooseImage = (props) => {
    /*      VYBER JEDNEHO OBRAZKU NA UPLOAD

            USAGE:
            
            <ChooseImage folder={productID.toString()} type={global.image_product} label={lang.product_main_image} note={'600x600 px'} image_name={imageName} image={image} func={ChooseImagePress.bind(this)} />

            const ChooseImagePress = (value) => {                
            }

    */
    const [isBusy, setBusy] = useState(false);
    const [file, setFile] = useState(null); // celá adresa k obrázku
    const [fileName, setFileName] = useState(null); // len názov obrázku
    const [type, setType] = useState(0);

    const [showImage, setShowImage] = useState(false);

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const inputRef = useRef(null);
    let { func } = props;
    var lang = sk;

    useEffect(() => {

        setFile(props.image);
        setFileName(props.image_name);
        setType(props.type);

        return () => {
        };

    }, []);


    const Press = (img) => {
        func(img);
    }

    const image_upload = async (file) => {
        // UPLOAD IMAGE TO SERVER
        setBusy(true);
        const data = new FormData();
        data.append('typ', type);
        data.append("name", "image");
        if (props.folder != undefined) {
            data.append("folder", props.folder);
        }
        data.append("file_attachment", file);
        try {
            const response = await fetch(
                global.db_url + 'upload_image', {
                method: 'POST',
                body: data
            }
            );
            const json = await response.json();

            if (json.ok > 0) {
                // ALL OK   
                setFile(global.web + '/' + json.file);
                setFileName(json.filename);
                Press(json.filename);
            }
            inputRef.current.value = null;
            setBusy(false);
            return;

        } catch (error) {
            setBusy(false);
            console.log(error);
        }
    };

    const image_delete = async (image) => {
        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + 'delete_image', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    image: image,
                    typ: type,
                    folder: props.folder
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setFile(null);
                inputRef.current.value = null;
                Press(null);
            }
            setBusy(false);

        } catch (error) {
            Debug(error);
            setBusy(false);
        }
    };

    function handleChange(e) {
        var img = URL.createObjectURL(e.target.files[0]);
        var file = e.target.files[0];
        setFile(img);
        image_upload(file);
    }

    const ClearImage = () => {
        image_delete(fileName);
    }

    return (
        <div style={{ ...styles.Block, width: file == null ? 130 : 260 }}>
            <p style={{ ...styles.TextTiny, fontWeight: 'bold', marginBottom: 10 }}>{props.label}</p>
            <div style={{ ...styles.BlockRowRaw, width: '100%' }}>
                {file != null ?
                    <div style={{ ...styles.Block, width: 120, height: 120, marginTop: 4, marginRight: 10, borderRadius: 10, backgroundColor: global.theme_gray }}>
                        <Tooltip title={lang.delete}>
                            <IconButton onClick={() => ClearImage()} style={{ ...styles.BlockRound, width: 20, height: 20, backgroundColor: global.theme_dark_gray, alignSelf: 'flex-end' }}>
                                <FontAwesomeIcon style={{ width: 16, height: 16, color: global.theme_white }} icon={faXmark} />
                            </IconButton>
                        </Tooltip>
                        <div style={{ ...styles.Block, height: '100%' }}>
                            {isBusy == true ?
                                <CircularProgress color="inherit" size={20} />
                                :
                                <img onClick={() => setShowImage(true)} src={file} style={{ maxWidth: 90, maxHeight: 90, aspectRatio: 1.0, cursor: 'pointer' }} />
                            }
                        </div>
                    </div>
                    : null}
                <div style={{ ...styles.Block, width: 130, height: 150 }}>
                    <label htmlFor="filePicker" style={{ ...styles.Block, width: 120, height: 120, background: global.theme_white, justifyContent: 'center', alignItems: 'center', cursor: 'pointer', border: '2px solid ' + global.theme_dark_gray, borderRadius: 10 }}>
                        <p style={{ ...styles.TextTiny, marginBottom: 10, color: global.theme_dark_gray }}>{props.note}</p>
                        <FontAwesomeIcon style={{ width: 40, height: 40, color: global.theme_dark_gray }} icon={faImage} />
                        <p style={{ ...styles.TextTiny, marginTop: 5, color: global.theme_dark_gray }}>{lang.choose_image}</p>
                    </label>
                    <input ref={inputRef} name={'myimage'} onChange={handleChange} id="filePicker" style={{ visibility: "hidden" }} type={"file"}></input>
                </div>

            </div>

            {showImage == true ?
                <Dialog open={true} maxWidth={'lg'} onBackdropClick={() => setShowImage(false)} >
                    <div style={{ ...styles.BlockRow, backgroundColor: global.theme_dark, height: 60, justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ width: '100%', paddingLeft: 10 }}>
                            <p style={{ ...styles.TextLarge, color: global.theme_white }}>{lang.image_preview}</p>
                        </div>
                        <div style={{ width: 50 }}>
                            <IconButton onClick={() => setShowImage(false)} style={{ ...styles.BlockRound, width: 36, height: 36, backgroundColor: global.theme_light, alignSelf: 'flex-end' }}>
                                <FontAwesomeIcon style={{ width: 20, height: 20, color: global.theme_white }} icon={faXmark} />
                            </IconButton>
                        </div>
                    </div>
                    <img src={file} style={{ maxWidth: 600, maxHeight: 600, aspectRatio: 1.0 }} />
                </Dialog >
                : null}
        </div>
    );
}


export const Spacer = (props) => {
    /*
            USAGE:
            <Spacer height={50} />
    */

    return (
        <div style={{ ...styles.Block, height: props.height == undefined ? 20 : parseInt(props.height) }}>
            <p></p>
        </div >
    );
}




export const Template = (props) => {
    /*
            USAGE:
            <BonusInfo lang={lang} language={language} func={BonusInfoPress.bind(this)} />

            const BonusInfoPress = (value) => {
                Debug(value)
            }
            */
    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState('');

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    let { func } = props;
    var lang = props.language;

    useEffect(() => {

        return () => {
        };

    }, []);


    const Press = (typ, value) => {
        func(typ, value);
    }

    const Save = () => {
        Press(0, 0);
    }

    return (
        <Dialog open={true} maxWidth={'lg'}>
            <div style={{ ...styles.BlockRow, backgroundColor: global.theme_dark, height: 50, justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ width: '100%', paddingLeft: 10 }}>
                    <p style={{ ...styles.TextLarge, color: global.theme_white }}>{lang.userdata}</p>
                </div>
                <div style={{ width: 50 }}>
                    <IconButton onClick={() => Press(false, false)} style={{ ...styles.BlockRound, width: 30, height: 30, backgroundColor: global.theme_light, alignSelf: 'flex-end' }}>
                        <FontAwesomeIcon style={{ width: 20, height: 20, color: global.theme_white }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>

            <div style={{ ...styles.Block, width: 600 }}>
                <p>Body</p>
            </div>

            <div style={{ ...styles.Block, width: 600, height: 110 }}>
                <div style={{ height: 50 }}>
                    {isBusy == true ?
                        <CircularProgress color="inherit" size={20} />
                        : error != '' ?
                            <Alert severity="error">
                                {error}
                            </Alert>
                            : null
                    }
                </div>
                <div style={{ ...styles.BlockRow, width: undefined }}>
                    <Button onClick={() => Save()} style={{ ...styles.ButtonGreen, margin: 10 }}>{lang.save}</Button>
                    <Button onClick={() => Press(false, false)} style={{ ...styles.ButtonBack, margin: 10 }}>{lang.cancel}</Button>
                </div>
            </div>
        </Dialog >
    );
}


export const Today = () => {
    var today = new Date();

    var d = String(today.getDate()).padStart(2, '0');
    var m = String(today.getMonth() + 1).padStart(2, '0');
    var y = String(today.getFullYear());
    today = y + '-' + m + '-' + d;

    return today;
}

export const GetDate = (str) => {
    var today = new Date(str);

    var d = String(today.getDate()).padStart(2, '0');
    var m = String(today.getMonth() + 1);
    var y = String(today.getFullYear());
    today = d + '. ' + m + '. ' + y;

    return today;
}

export const GoHome = () => {
    window.scrollTo(0, 0);
}

export const SelectColor = (props) => {
    /*
            USAGE:
            <SelectColor func={SelectColorPress.bind(this)} />

            const SelectColorPress = (value) => {                
            }

            */
    const [color, setColor] = useState('#000000');
    const [showOpen, setShowOpen] = useState(0);

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    let { func } = props;
    var lang = sk;

    useEffect(() => {
        setColor(props.default);

        return () => {
        };

    }, []);


    const Press = (item) => {
        setColor(item);
        setShowOpen(false);
        func(item);
    }

    return (
        <div style={{ ...styles.BlockLeft }}>
            <Button onClick={() => setShowOpen(true)} style={{ ...styles.Button, width: 180, backgroundColor: global.theme_light_gray, color: global.theme_black, textTransform: 'none', border: '1px solid ' + global.theme_dark_gray }}>
                <div style={{ ...styles.Block, width: 40 }}>
                    <FontAwesomeIcon style={{ width: 30, height: 30, color: color, marginRight: 10 }} icon={faSquare} />
                </div>
                <div style={{ ...styles.BlockLeft, width: '60%' }}>
                    {props.label}
                </div>
                <div style={{ width: 20 }}>
                    <FontAwesomeIcon style={{ marginLeft: 10, width: 16, height: 16, color: global.theme_light }} icon={faChevronRight} />
                </div>
            </Button>
            {showOpen == true ?
                <Dialog open={true} maxWidth={'lg'} onBackdropClick={() => Press(true)}>
                    <div style={{ ...styles.BlockRow, width: 400, backgroundColor: global.theme_dark, height: 60, justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ width: '100%', paddingLeft: 10 }}>
                            <p style={{ ...styles.TextLarge, color: global.theme_white }}>{lang.choose_color}</p>
                        </div>
                        <div style={{ width: 50 }}>
                            <IconButton onClick={() => setShowOpen(false)} style={{ ...styles.BlockRound, width: 36, height: 36, backgroundColor: global.theme_light, alignSelf: 'flex-end' }}>
                                <FontAwesomeIcon style={{ width: 20, height: 20, color: global.theme_white }} icon={faXmark} />
                            </IconButton>
                        </div>
                    </div>
                    <div style={{ ...styles.BlockRow, width: 400, flexWrap: 'wrap' }}>
                        {global.colors.map(item => (
                            <IconButton onClick={() => Press(item.color)} style={{ backgroundColor: item.color, width: 40, height: 40, borderRadius: 0, border: '1px solid black', borderRadius: 20, margin: 5 }}>

                            </IconButton>
                        ))}
                    </div>
                </Dialog>
                : null}
        </div >
    );
}

export const ShowOK = (props) => {
    /*
            USAGE:
            const [showOK, setShowOK] = useState(false);
            setShowOK(true);

            {showOK == true ?
                <ShowOK func={ShowOKPress.bind(this)} />
                : null}

            const ShowOKPress = (value) => {                
                setShowOK(false);
            }

            */

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    let { func } = props;
    var lang = sk;

    useEffect(() => {
        const timer = setTimeout(() => {
            Press(false);
        }, 1000);

        return () => {
        };

    }, []);

    const Press = (item) => {
        func(item);
    }

    return (
        <Backdrop sx={{ color: '#000000', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true} onClick={null} style={{ backgroundColor: '#00000000' }}>
            <Paper elevation={3} style={{ ...styles.Block, marginLeft: isSmall ? 0 : global.menu_width - 40, width: 80, height: 80, backgroundColor: global.theme_white, borderRadius: 40 }}>
                <FontAwesomeIcon style={{ width: 40, height: 40, color: global.theme_green }} icon={faCheck} />
            </Paper>
        </Backdrop>
    );
}


export const CustomerSearch = (props) => {
    /*
            Hladat zákazníka
 
            USAGE:
            <CustomerSearch func={CustomerSearchPress.bind(this)} />
 
            const CustomerSearchPress = (value) => {
            }
 
    */
    const [isBusy, setBusy] = useState(false);

    const [search, setSearch] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [items, setItems] = React.useState([]);
    const [err, setErr] = React.useState('');


    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const navigate = useNavigate();
    const location = useLocation();


    let { func } = props;
    var lang = sk;

    const BarHeight = 70;

    useEffect(() => {

        return () => {
        };

    }, []);

    const Press = (items, str) => {
        func(items, str);
    }

    const db_search = async (txt, _quick) => {
        // SEARCH DURING TYPING
        setLoading(true);
        try {
            const response = await fetch(
                global.db_url + 'customer_searching', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    company_id: props.companyID,
                    search: txt,
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setItems(json.items);
                if (_quick == false) {
                    Press(json.arr, txt);
                }
            }
            setLoading(false);
        } catch (error) {
            Debug(error);
            setLoading(false);
        }

    }


    const SearchItem = (value) => {
        // zobrazenie vybratého produktu
        if (value.id == undefined) {
            // jedna sa o hladany výraz
            if (search.length > 2) {
                // zobraziť výsledok

                var txt = value.trim();
                txt = txt.replaceAll('/', '');
                txt = txt.replaceAll('+', '');
                txt = txt.replaceAll('#', '');
                txt = txt.replaceAll(' ', '+');

                db_search(txt, false);
            }
        } else {
            // bol označený konkrétny produkt
            // zobraziť produkt
            Press([value.id], search);
        }
    }

    const Search = (txt_) => {
        var txt = txt_.trim();
        setSearch(txt);
        if (txt.length > 2) {
            var search = txt.trim();
            search = search.replaceAll('/', '');
            search = search.replaceAll('+', '');
            search = search.replaceAll('#', '');
            search = search.replaceAll(' ', '+');

            setErr('');
            db_search(search, true);
        }
    }

    return (

        <div style={{ ...styles.Block, justifyContent: 'flex-end', alignItems: 'center' }}>
            <Autocomplete
                freeSolo
                disableClearable
                style={{ width: '100%', maxWidth: 700, backgroundColor: global.theme_gray }}
                options={items}
                getOptionLabel={(option) => option.keys ? option.keys : ""}
                loading={loading}
                size={'small'}
                onChange={(event, newValue) => { SearchItem(newValue); }}
                onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                        // Prevent's default 'Enter' behavior.
                        if (search.length < 3) {
                            event.defaultMuiPrevented = true;
                            setErr(lang.search_error);
                        } else {
                            setErr('');
                            SearchItem(search);
                        }

                    }
                }}
                renderOption={(props, option) => (
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                        <p style={{ ...styles.TextTiny }}>
                            {option.name} {option.surname} - ({option.town})
                        </p>
                    </Box>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={err == '' ? lang.search : err}
                        onInput={e => Search(e.target.value)}
                        error={err == '' ? false : true}
                        style={{ backgroundColor: global.theme_white }}
                        InputProps={{
                            ...params.InputProps,
                            type: 'search',
                            startAdornment: <InputAdornment position="start"><FontAwesomeIcon style={{ width: 16, height: 16, color: global.theme_medium_gray }} icon={faSearch} /></InputAdornment>,
                            endAdornment: (
                                <React.Fragment>
                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                    />
                )}
            />
        </div>
    )
}


export const GetSlovom = (value) => {
    // vstup 0.0, 123.50, ....
    // vráti slovom od  0 - 999 000
    // aj centy 
    var numbers_1 = ['nula', 'jeden', 'dva', 'tri', 'štyri', 'päť', 'šesť', 'sedem', 'osem', 'deväť'];
    var numbers_2 = ['nula', 'jeden', 'dve', 'tri', 'štyri', 'päť', 'šesť', 'sedem', 'osem', 'deväť'];
    var numbers_3 = ['desať', 'jedennásť', 'dvanásť', 'trinásť', 'štrnásť', 'pätnásť', 'šestnásť', 'sedemnásť', 'osemnásť', 'devätnásť'];
    var numbers_4 = ['', 'desať', 'dsať', 'dsať', 'dsať', 'desiat', 'desiat', 'desiat', 'desiat', 'desiat'];

    var str_ = value.toString().replaceAll(',', '.');
    var arr = str_.split('.');

    var result = '';
    var result2 = '';
    if (arr.length > 0) {
        var str = arr[0];
        var eur = parseInt(str);

        var n = 0;

        while (n < str.length) {
            var chr = str.charAt(str.length - 1 - n);
            var val = parseInt(chr);
            if (n == 0) {
                // jdnotky 1 - 9
                if (val > 0) {
                    result = numbers_1[val];
                }
            }
            if (n == 1) {
                // desiatky
                if (val == 1) {
                    // 10 - 19
                    var sub_val = parseInt(str.charAt(str.length - n));
                    result = numbers_3[sub_val];
                } else {
                    // 20 - 99
                    if (val > 1) {
                        result = numbers_1[val] + numbers_4[val] + result;
                    }
                }
            }
            if (n == 2) {
                // stovky 100 - 900
                if (val > 0) {
                    result = numbers_2[val] + 'sto' + result;
                }
            }
            // ------------------------------------------------------------
            // 1 000 - 100 000
            // ------------------------------------------------------------
            if (n == 3) {
                // tisíce 1 - 9
                if (val > 0) {
                    result2 = numbers_2[val];
                }
            }
            if (n == 4) {
                // desaťtisíce 10 000 - 90 000
                if (val == 1) {
                    // 10 - 19
                    var sub_val = parseInt(str.charAt(str.length - n));
                    result2 = numbers_3[sub_val];
                } else {
                    // 20 - 99
                    if (val > 1) {
                        result2 = numbers_1[val] + numbers_4[val] + result2;
                    }
                }
            }
            if (n == 5) {
                // stotisíce 100 000 - 900 000
                if (val > 0) {
                    result2 = numbers_2[val] + 'sto' + result2;
                }
            }
            n++;
        }
    }
    if (result2 != '') {
        result2 = result2 + 'tisíc';
    }
    /* **********************************************************************
        CENTY
    ********************************************************************** */
    var centy = '';
    if (arr.length > 1) {
        var str = arr[1];

        if (str.length == 1) {
            str = str + '0';
        }
        var cent = parseInt(str);

        var n = 0;
        while (n < str.length) {
            var chr = str.charAt(str.length - 1 - n);
            var val = parseInt(chr);

            if (n == 0) {
                // jdnotky 1 - 9
                if (val > 0) {
                    centy = numbers_1[val];
                }
            }
            if (n == 1) {
                // desiatky
                if (val == 1) {
                    // 10 - 19
                    var sub_val = parseInt(str.charAt(str.length - n));
                    centy = numbers_3[sub_val];
                } else {
                    // 20 - 99
                    if (val > 1) {
                        centy = numbers_1[val] + numbers_4[val] + centy;
                    }
                }
            }
            n++;
        }
    }
    result = result2 + result;
    if (result == '') {
        result = numbers_1[0];
    }

    var eur_text = 'eur';
    if (eur == 0) {
        eur_text = 'eur';
    }
    if (eur == 1) {
        eur_text = 'euro';
    }
    if (eur > 1 && eur < 5) {
        eur_text = 'eura';
    }
    if (eur > 4) {
        eur_text = 'eur';
    }
    result = result + ' ' + eur_text;

    if (centy != '') {
        var cent_text = 'cent';
        if (cent == 1) {
            cent_text = 'cent';
        }
        if (cent > 1 && cent < 5) {
            cent_text = 'centy';
        }
        if (cent > 4) {
            cent_text = 'centov';
        }
        result = result + ' a ' + centy + ' ' + cent_text;
    }
    return result;
}



export const AskDialog = (props) => {
    /*
            USAGE:
            <AskDialog label='' question='' text='' button_text='' button_color='' cancel='zrušiť' func={AskDialogResult.bind(this)} />

            const AskDialogResult = (value) => {   
                if(value == true){
                }
            }

            */
    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState('');

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    let { func } = props;
    var lang = sk;

    useEffect(() => {

        return () => {
        };

    }, []);


    const Press = (typ) => {
        func(typ);
    }


    return (
        <Dialog open={true} maxWidth={'lg'} style={{ marginLeft: isSmall ? 0 : global.menu_width - 40 }}>
            <div style={{ ...styles.BlockRow, background: global.theme_dialog_back, height: 50, justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ width: '100%', paddingLeft: 10 }}>
                    <p style={{ ...styles.TextLarge, color: global.theme_dialog_label }}>{props.label}</p>
                </div>
                <div style={{ width: 50 }}>
                    <IconButton onClick={() => Press(false, false)} style={{ ...styles.BlockRound, width: 30, height: 30, backgroundColor: global.theme_black, alignSelf: 'flex-end' }}>
                        <FontAwesomeIcon style={{ width: 20, height: 20, color: global.theme_white }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>

            <div style={{ ...styles.Block, width: isSmall ? '100%' : 600, paddingTop: 30, paddingBottom: 30 }}>
                <p style={{ ...styles.TextNormal, fontWeight: 'bold', textAlign: 'center' }}>{props.question}</p>
                <p style={{ ...styles.TextTiny, marginTop: 10, textAlign: 'center' }}>{props.text}</p>
            </div>

            <div style={{ ...styles.Block, paddingBottom: 10 }}>
                <div style={{ ...isSmall ? styles.Block : styles.BlockRowRaw }}>
                    <Button onClick={() => Press(true)} style={{ ...styles.ButtonGreen, backgroundColor: props.button_color, margin: 10 }}>{props.button_text}</Button>
                    <Button onClick={() => Press(false)} style={{ ...styles.ButtonBack, margin: 10 }}>{props.cancel == undefined ? lang.cancel : props.cancel}</Button>
                </div>
            </div>
        </Dialog >
    );
}


export const AskLogout = (props) => {
    /*
            USAGE:
            <AskLogout func={AskLogoutResult.bind(this)} />

            const AskLogoutResult = (value) => {                
            }

            */
    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState('');

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    let { func } = props;
    var lang = props.lang;

    useEffect(() => {

        return () => {
        };

    }, []);


    const Press = (typ) => {
        func(typ);
    }


    return (
        <Dialog open={true} maxWidth={'lg'}>
            <div style={{ ...styles.BlockRow, background: global.theme_dialog_back, height: 50, justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ width: '100%', paddingLeft: 10 }}>
                    <p style={{ ...styles.TextLarge, color: global.theme_dialog_label }}>{lang.logout_}</p>
                </div>
                <div style={{ width: 50 }}>
                    <IconButton onClick={() => Press(false, false)} style={{ ...styles.BlockRound, width: 30, height: 30, backgroundColor: global.theme_black, alignSelf: 'flex-end' }}>
                        <FontAwesomeIcon style={{ width: 20, height: 20, color: global.theme_white }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>

            <div style={{ ...styles.Block, width: isSmall ? 300 : 600, paddingTop: 30, paddingBottom: 30 }}>
                <p style={{ ...styles.TextNormal, fontWeight: 'bold' }}>{lang.logout_question}</p>
            </div>

            <div style={{ ...styles.Block, paddingBottom: 10 }}>
                <div style={{ ...isSmall ? styles.Block : styles.BlockRowRaw }}>
                    <Button onClick={() => Press(true)} style={{ ...styles.ButtonGreen, backgroundColor: global.theme_dark_red, margin: 10 }}>{lang.logout}</Button>
                    <Button onClick={() => Press(false)} style={{ ...styles.ButtonBack, margin: 10 }}>{lang.cancel}</Button>
                </div>
            </div>
        </Dialog >
    );
}

export const Languages = (props) => {
    /*
            USAGE:
            <Languages language={lang} func={LanguagesResult.bind(this)} />

            const LanguagesResult = (value) => {                
                setLanguage(value);
            }

            */
    const [language, setLanguage] = useState(0);

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    let { func } = props;

    useEffect(() => {

        if (props.language == sk) {
            setLanguage(0);
        }
        if (props.language == cz) {
            setLanguage(1);
        }


        return () => {
        };

    }, []);


    const Press = (value) => {

        if (value == sk) {
            setLanguage(0);
        }
        if (value == cz) {
            setLanguage(1);
        }
        func(value);
    }

    return (
        <div style={{ ...styles.Block }}>
            {props.size == undefined || props.size == 'small' ?
                <div style={{ ...styles.BlockRowRaw }}>
                    <IconButton onClick={() => Press(sk)} style={{ margin: 5, opacity: language == 0 ? 1 : 0.25 }}>
                        <img src={global.images + 'app/flag_sk.png'} width={40} height={40} />
                    </IconButton>
                    <IconButton onClick={() => Press(cz)} style={{ margin: 5, opacity: language == 1 ? 1 : 0.25 }}>
                        <img src={global.images + 'app/flag_cz.png'} width={40} height={40} />
                    </IconButton>
                </div>
                :
                <div style={{ ...styles.BlockRowRaw }}>
                    <IconButton onClick={() => Press(sk)} style={{ ...styles.Block, width: 80, height: 80, backgroundColor: language == 0 ? global.theme_light : undefined, border: '1px solid ' + global.theme_light, margin: 5, opacity: language == 0 ? 1 : 0.25, borderRadius: 20 }}>
                        <img src={global.images + 'app/flag_sk.png'} width={40} height={40} />
                    </IconButton>
                    <IconButton onClick={() => Press(cz)} style={{ ...styles.Block, width: 80, height: 80, backgroundColor: language == 1 ? global.theme_light : undefined, border: '1px solid ' + global.theme_light, margin: 5, opacity: language == 1 ? 1 : 0.25, borderRadius: 20 }}>
                        <img src={global.images + 'app/flag_cz.png'} width={40} height={40} />
                    </IconButton>
                </div>
            }
        </div>
    );
}

export const Empty = (props) => {
    // PRAZDNA STRÁNKA - ZOBRAZOVANA POCAS RENDEROVANIA - isBusy?
    return (
        <div style={{ ...styles.Block, backgroundColor: global.theme_back }}>
        </div>
    );
}


export const SalonData = (props) => {
    /* WELCOME - UVODNA REGISTRACIA SALONA

            USAGE:
            <SalonData user={user} func={SalonDataResult.bind(this)} />

            const SalonDataResult = (typ, value) => {                
            }

    */


    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const [index, setIndex] = useState(4);
    const [language, setLanguage] = useState(props.language);

    const [isBusy, setBusy] = useState(false);

    // ZAKLADNE UDAJE
    const [name, setName] = useState('');
    const [nameErr, setNameErr] = useState('');
    const [surname, setSurname] = useState('');
    const [surnameErr, setSurnameErr] = useState('');
    const [mobil, setMobil] = useState('');
    const [mobilErr, setMobilErr] = useState('');

    // SALON
    const [salonName, setSalonName] = useState('');
    const [salonNameErr, setSalonNameErr] = useState('');
    const [salonStreet, setSalonStreet] = useState('');
    const [salonStreetErr, setSalonStreetErr] = useState('');
    const [salonStreetNumber, setSalonStreetNumber] = useState('');
    const [salonPSC, setSalonPSC] = useState('');
    const [salonPSCErr, setSalonPSCErr] = useState('');
    const [salonTown, setSalonTown] = useState('');
    const [salonTownErr, setSalonTownErr] = useState('');

    // FIREMNE UDAJE
    const [companyName, setCompanyName] = useState('');
    const [companyNameErr, setCompanyNameErr] = useState('');
    const [companyStreet, setCompanyStreet] = useState('');
    const [companyStreetErr, setCompanyStreetErr] = useState('');
    const [companyStreetNumber, setCompanyStreetNumber] = useState('');
    const [companyPSC, setCompanyPSC] = useState('');
    const [companyPSCErr, setCompanyPSCErr] = useState('');
    const [companyTown, setCompanyTown] = useState('');
    const [companyTownErr, setCompanyTownErr] = useState('');
    const [ico, setICO] = useState('');
    const [icoErr, setICOErr] = useState('');
    const [dic, setDIC] = useState('');
    const [dicErr, setDICErr] = useState('');
    const [icDPH, setICDPH] = useState('');

    const [gdpr, setGDPR] = useState(false);
    const [err, setErr] = useState('');

    const [showRules, setShowRules] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const boxHeight = 480;

    var lang = language;
    let { func } = props;

    useEffect(() => {

        //var tmp = LoadUser();

        return () => {
        };

    }, []);

    const Press = (id, data) => {
        func(id, data);
    }

    const db_update = async (data) => {

        if (isBusy == false) {
            setBusy(true);

            try {
                const response = await fetch(
                    global.db_url + 'login_update', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        id: props.user.id,
                        data: data
                    })
                })

                const json = await response.json();
                setBusy(false);

                if (json.ok > 0) {
                    var user = json.user;
                    Press(user);
                }

            } catch (error) {
                Debug(error);
                setBusy(false);
            }
        }
    };


    const LanguagesResult = (value) => {
        setLanguage(value);
    }

    const Back = () => {
        var i = index;
        if (i > 0) {
            i--;
            setIndex(i);
        }
    }

    const Continue = () => {
        var error = '';

        setNameErr('');
        setSurnameErr('');
        setMobilErr('');

        setSalonNameErr('');
        setSalonStreetErr('');
        setSalonPSCErr('');
        setSalonTownErr('');
        setCompanyNameErr('');
        setCompanyStreetErr('');
        setCompanyPSCErr('');
        setCompanyTownErr('');
        setICOErr('');
        setDICErr('');

        var i = index;
        if (i == 0) {
            if (mobil.length < 5) {
                setMobil(lang.welcome_phone);
            }
            i++;
            setIndex(i);
        } else if (i == 1) {
            if (name.trim() == '') {
                error = lang.required;
                setNameErr(error);
            }

            if (surname.trim() == '') {
                error = lang.required;
                setSurnameErr(error);
            }

            if (mobil.trim() == '') {
                error = lang.required;
                setMobilErr(error);
            } else if (mobil.length < 8) {
                error = lang.required;
                setMobilErr(error);
            }

            if (error == '') {
                i++;
                setIndex(i);
            }
        } else if (i == 2) {

            if (salonName.trim() == '') {
                error = lang.required;
                setSalonNameErr(error);
            }

            if (salonStreet.trim() == '') {
                error = lang.required;
                setSalonStreetErr(error);
            }

            if (salonPSC.trim() == '') {
                error = lang.required;
                setSalonPSCErr(error);
            }

            if (salonTown.trim() == '') {
                error = lang.required;
                setSalonTownErr(error);
            }
            if (error == '') {
                i++;
                setIndex(i);
            }
        } else if (i == 3) {

            if (companyName.trim() == '') {
                error = lang.required;
                setCompanyNameErr(error);
            }

            if (companyStreet.trim() == '') {
                error = lang.required;
                setCompanyStreetErr(error);
            }

            if (companyPSC.trim() == '') {
                error = lang.required;
                setCompanyPSCErr(error);
            }

            if (companyTown.trim() == '') {
                error = lang.required;
                setCompanyTownErr(error);
            }

            if (ico.trim() == '') {
                error = lang.required;
                setICOErr(error);
            }

            if (dic.trim() == '') {
                error = lang.required;
                setDICErr(error);
            }
            if (error == '') {
                i++;
                setIndex(i);
            }
        } else if (i == 4) {
            if (gdpr == true) {
                Save();
            }
        }

    }

    const Save = () => {

        var data = props.user;
        data.name = name;
        data.surname = surname;
        data.mobil = mobil;
        data.company = companyName;
        data.street = companyStreet;
        data.street_number = companyStreetNumber;
        data.psc = companyPSC;
        data.town = companyTown;
        data.ico = ico;
        data.dic = dic;
        data.ic_dph = icDPH;
        data.credits = 0;
        data.salon_name = salonName;
        data.salon_street = salonStreet;
        data.salon_street_number = salonStreetNumber;
        data.salon_psc = salonPSC;
        data.salon_town = salonTown;
        data.rating = 0;
        data.discount = 0;
        data.language = lang.lang_id;
        data.alias = '';
        data.brand_id = 1;
        data.photo = null;
        data.agent_id = 0;
        data.account = '';
        data.eshop = false;
        data.eshop_ready = false;
        data.gdpr = true;
        data.email = props.user.username;
        data.password = '';
        db_update(data);
    }

    const RulesResult = () => {
        setShowRules(false)
    }

    return (
        <Dialog open={true} fullScreen={isSmall ? true : false} maxWidth={'lg'}>
            {isSmall && index > 0 ? null :
                <div style={{ ...styles.Block, background: global.theme_dialog_back, height: 50, justifyContent: 'center' }}>
                    <p style={{ ...styles.TextSmall, color: global.theme_dialog_label }}>{lang.welcome_register}</p>
                </div>
            }
            <div style={{ ...isSmall ? styles.Block : styles.BlockRow, width: isSmall ? '100%' : 800 }}>
                {isSmall ? null :
                    <div style={{ ...styles.BlockLeft, width: 250, backgroundColor: global.theme_dark, height: boxHeight + 110 }}>
                        <div style={{ ...styles.Block, paddingTop: 10, paddingBottom: 30 }}>
                            <img src={global.images + 'app/logo_white.png'} width={120} />
                        </div>
                        <Paper elevation={index == 0 ? 3 : 0} style={{ ...styles.BlockRow, background: index == 0 ? global.theme_gradient_dark_medium : global.theme_gradient_dark_dark, height: 60, alignItems: 'center', borderRadius: 0, marginTop: 0, borderTopLeftRadius: index == 0 ? 30 : 0, borderBottomLeftRadius: index == 0 ? 30 : 0 }}>
                            <div style={{ ...styles.BlockRound, backgroundColor: index == 0 ? global.theme_light_red : global.theme_dark, marginLeft: 10 }}>
                                <p style={{ ...styles.TextSmall, color: index == 0 ? global.theme_black : global.theme_white }}>1.</p>
                            </div>
                            <p style={{ ...styles.TextSmall, color: global.theme_white, marginLeft: 10 }}>{lang.country}</p>
                        </Paper>
                        <Paper elevation={index == 1 ? 3 : 0} style={{ ...styles.BlockRow, background: index == 1 ? global.theme_gradient_dark_medium : global.theme_gradient_dark_dark, height: 60, alignItems: 'center', borderRadius: 0, marginTop: 10, borderTopLeftRadius: index == 1 ? 30 : 0, borderBottomLeftRadius: index == 1 ? 30 : 0 }}>
                            <div style={{ ...styles.BlockRound, backgroundColor: index == 1 ? global.theme_light_red : global.theme_dark, marginLeft: 10 }}>
                                <p style={{ ...styles.TextSmall, color: index == 1 ? global.theme_black : global.theme_white }}>2.</p>
                            </div>
                            <p style={{ ...styles.TextSmall, color: global.theme_white, marginLeft: 10 }}>{lang.welcome_contact}</p>
                        </Paper>
                        <Paper elevation={index == 2 ? 3 : 0} style={{ ...styles.BlockRow, background: index == 2 ? global.theme_gradient_dark_medium : global.theme_gradient_dark_dark, height: 60, alignItems: 'center', borderRadius: 0, marginTop: 10, borderTopLeftRadius: index == 2 ? 30 : 0, borderBottomLeftRadius: index == 2 ? 30 : 0 }}>
                            <div style={{ ...styles.BlockRound, backgroundColor: index == 2 ? global.theme_light_red : global.theme_dark, marginLeft: 10 }}>
                                <p style={{ ...styles.TextSmall, color: index == 2 ? global.theme_black : global.theme_white }}>3.</p>
                            </div>
                            <p style={{ ...styles.TextSmall, color: global.theme_white, marginLeft: 10 }}>{lang.salon_address}</p>
                        </Paper>
                        <Paper elevation={index == 3 ? 3 : 0} style={{ ...styles.BlockRow, background: index == 3 ? global.theme_gradient_dark_medium : global.theme_gradient_dark_dark, height: 60, alignItems: 'center', borderRadius: 0, marginTop: 10, borderTopLeftRadius: index == 3 ? 30 : 0, borderBottomLeftRadius: index == 3 ? 30 : 0 }}>
                            <div style={{ ...styles.BlockRound, backgroundColor: index == 3 ? global.theme_light_red : global.theme_dark, marginLeft: 10 }}>
                                <p style={{ ...styles.TextSmall, color: index == 3 ? global.theme_black : global.theme_white }}>4.</p>
                            </div>
                            <p style={{ ...styles.TextSmall, color: global.theme_white, marginLeft: 10 }}>{lang.firm_data}</p>
                        </Paper>
                        <Paper elevation={index == 4 ? 3 : 0} style={{ ...styles.BlockRow, background: index == 4 ? global.theme_gradient_dark_medium : global.theme_gradient_dark_dark, height: 60, alignItems: 'center', borderRadius: 0, marginTop: 10, borderTopLeftRadius: index == 4 ? 30 : 0, borderBottomLeftRadius: index == 4 ? 30 : 0 }}>
                            <div style={{ ...styles.BlockRound, backgroundColor: index == 4 ? global.theme_light_red : global.theme_dark, marginLeft: 10 }}>
                                <p style={{ ...styles.TextSmall, color: index == 4 ? global.theme_black : global.theme_white }}>5.</p>
                            </div>
                            <p style={{ ...styles.TextSmall, color: global.theme_white, marginLeft: 10 }}>{lang.summaring}</p>
                        </Paper>
                    </div>
                }
                <div style={{ ...styles.BlockLeft, width: isSmall ? '100%' : 650, paddingTop: isSmall ? 20 : 0, paddingBottom: isSmall ? 20 : 0 }}>
                    {/* KRAJINA PODNIKANIA */}
                    {index == 0 ?
                        <div style={{ ...styles.Block, height: isSmall ? undefined : boxHeight, minWidth: 250, width: '100%' }}>
                            <div style={{ ...styles.Block, paddingBottom: 0 }}>
                                <p style={{ ...styles.TextLarge, textAlign: 'center' }}>{lang.welcome_country}</p>
                                <Line color={global.theme_lighter} margin={20} />
                            </div>

                            <Languages size='large' language={lang} func={LanguagesResult.bind(this)} />
                            <p style={{ ...styles.TextSmall, fontWeight: 'normal', marginTop: 20 }}>{lang.state}</p>
                        </div>
                        : null}

                    {/* MENO, PRIEZVISKO, MOBIL */}
                    {index == 1 ?
                        <div style={{ ...styles.Block, height: isSmall ? undefined : boxHeight, minWidth: 250 }}>
                            <div style={{ ...styles.Block, paddingBottom: 0 }}>
                                <p style={{ ...styles.TextLarge, textAlign: 'center' }}>{lang.welcome_contact}</p>
                                <p style={{ ...styles.TextTiny, textAlign: 'center', marginTop: 5 }}>{lang.welcome_contact_text}</p>
                                <Line color={global.theme_lighter} margin={20} />
                            </div>
                            <div style={{ ...isSmall ? styles.Block : styles.BlockRow, width: '95%' }}>
                                <div style={{ ...styles.BlockLeft, width: isSmall ? '100%' : '33%' }}>
                                    <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>{lang.name}</p>
                                    <TextField error={nameErr == '' ? false : true} helperText={nameErr} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" size={'small'} value={name} onInput={e => setName(e.target.value)} />
                                </div>
                                <div style={{ ...styles.BlockLeft, width: isSmall ? '100%' : '33%', marginLeft: isSmall ? 0 : 10, marginTop: isSmall ? 20 : 0 }}>
                                    <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>{lang.surname}</p>
                                    <TextField error={surnameErr == '' ? false : true} helperText={surnameErr} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" size={'small'} value={surname} onInput={e => setSurname(e.target.value)} />
                                </div>
                                <div style={{ ...styles.BlockLeft, width: isSmall ? '100%' : '33%', marginLeft: isSmall ? 0 : 10, marginTop: isSmall ? 20 : 0 }}>
                                    <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>{lang.mobil}</p>
                                    <TextField error={mobilErr == '' ? false : true} helperText={mobilErr} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" size={'small'} value={mobil} onInput={e => setMobil(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        : null}

                    {/* ADRESA SALONA */}
                    {index == 2 ?
                        <div style={{ ...styles.Block, height: isSmall ? undefined : boxHeight, minWidth: 250, width: '100%' }}>
                            <div style={{ ...styles.Block, paddingBottom: 0 }}>
                                <p style={{ ...styles.TextLarge, textAlign: 'center' }}>{lang.salon_address_text}</p>
                                <Line color={global.theme_lighter} margin={20} />
                            </div>
                            <div style={{ ...styles.BlockLeft, width: isSmall ? '95%' : '70%' }}>
                                <div style={{ ...styles.BlockLeft, width: '100%' }}>
                                    <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>{lang.salon_name}</p>
                                    <TextField error={salonNameErr == '' ? false : true} helperText={salonNameErr} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" size={'small'} value={salonName} onInput={e => setSalonName(e.target.value)} />
                                </div>
                            </div>
                            <div style={{ ...styles.BlockRow, width: isSmall ? '95%' : '70%', marginTop: 20 }}>
                                <div style={{ ...styles.BlockLeft, width: '70%', marginRight: isSmall ? 5 : 10 }}>
                                    <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>{lang.street}</p>
                                    <TextField error={salonStreetErr == '' ? false : true} helperText={salonStreetErr} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" size={'small'} value={salonStreet} onInput={e => setSalonStreet(e.target.value)} />
                                </div>
                                <div style={{ ...styles.BlockLeft, width: '30%', marginLeft: isSmall ? 5 : 10 }}>
                                    <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>{lang.street_number}</p>
                                    <TextField style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" size={'small'} value={salonStreetNumber} onInput={e => setSalonStreetNumber(e.target.value)} />
                                </div>
                            </div>
                            <div style={{ ...styles.BlockRow, width: isSmall ? '95%' : '70%', marginTop: 20 }}>
                                <div style={{ ...styles.BlockLeft, width: '30%', marginRight: isSmall ? 5 : 10 }}>
                                    <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>{lang.psc}</p>
                                    <TextField error={salonPSCErr == '' ? false : true} helperText={salonPSCErr} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" size={'small'} value={salonPSC} onInput={e => setSalonPSC(e.target.value)} />
                                </div>
                                <div style={{ ...styles.BlockLeft, width: '70%', marginLeft: isSmall ? 5 : 10 }}>
                                    <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>{lang.town}</p>
                                    <TextField error={salonTownErr == '' ? false : true} helperText={salonTownErr} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" size={'small'} value={salonTown} onInput={e => setSalonTown(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        : null}

                    {/* FIREMNE UDAJE */}
                    {index == 3 ?
                        <div style={{ ...styles.Block, height: isSmall ? undefined : boxHeight, minWidth: 250, width: '100%' }}>
                            <div style={{ ...styles.Block, paddingBottom: 0 }}>
                                <p style={{ ...styles.TextLarge, textAlign: 'center' }}>{lang.firm_data}</p>
                                <Line color={global.theme_lighter} margin={20} />
                            </div>
                            <div style={{ ...styles.BlockLeft, width: isSmall ? '95%' : '70%' }}>
                                <div style={{ ...styles.BlockLeft, width: '100%' }}>
                                    <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>{lang.company}</p>
                                    <TextField error={companyNameErr == '' ? false : true} helperText={companyNameErr} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" size={'small'} value={companyName} onInput={e => setCompanyName(e.target.value)} />
                                </div>
                            </div>
                            <div style={{ ...styles.BlockRow, width: isSmall ? '95%' : '70%', marginTop: 20 }}>
                                <div style={{ ...styles.BlockLeft, width: '70%', marginRight: isSmall ? 5 : 10 }}>
                                    <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>{lang.street}</p>
                                    <TextField error={companyStreetErr == '' ? false : true} helperText={companyStreetErr} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" size={'small'} value={companyStreet} onInput={e => setCompanyStreet(e.target.value)} />
                                </div>
                                <div style={{ ...styles.BlockLeft, width: '30%', marginLeft: isSmall ? 5 : 10 }}>
                                    <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>{lang.street_number}</p>
                                    <TextField style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" size={'small'} value={companyStreetNumber} onInput={e => setCompanyStreetNumber(e.target.value)} />
                                </div>
                            </div>
                            <div style={{ ...styles.BlockRow, width: isSmall ? '95%' : '70%', marginTop: 20 }}>
                                <div style={{ ...styles.BlockLeft, width: '30%', marginRight: isSmall ? 5 : 10 }}>
                                    <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>{lang.psc}</p>
                                    <TextField error={companyPSCErr == '' ? false : true} helperText={companyPSCErr} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" size={'small'} value={companyPSC} onInput={e => setCompanyPSC(e.target.value)} />
                                </div>
                                <div style={{ ...styles.BlockLeft, width: '70%', marginLeft: isSmall ? 5 : 10 }}>
                                    <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>{lang.town}</p>
                                    <TextField error={companyTownErr == '' ? false : true} helperText={companyTownErr} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" size={'small'} value={companyTown} onInput={e => setCompanyTown(e.target.value)} />
                                </div>
                            </div>
                            <div style={{ ...isSmall ? styles.BlockLeft : styles.BlockRow, width: isSmall ? '95%' : '70%', marginTop: 20 }}>
                                <div style={{ ...styles.BlockLeft, width: isSmall ? '100%' : '33%', marginRight: isSmall ? 0 : 10 }}>
                                    <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>{lang.ico}</p>
                                    <TextField error={icoErr == '' ? false : true} helperText={icoErr} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" size={'small'} value={ico} onInput={e => setICO(e.target.value)} />
                                </div>
                                <div style={{ ...styles.BlockLeft, width: isSmall ? '100%' : '33%', marginRight: isSmall ? 0 : 10, marginTop: isSmall ? 20 : 0 }}>
                                    <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>{lang.dic}</p>
                                    <TextField error={dicErr == '' ? false : true} helperText={dicErr} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" size={'small'} value={dic} onInput={e => setDIC(e.target.value)} />
                                </div>
                                {language.lang_id == 0 ?
                                    <div style={{ ...styles.BlockLeft, width: isSmall ? '100%' : '33%', marginTop: isSmall ? 20 : 0 }}>
                                        <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>{lang.ic_dph}</p>
                                        <TextField style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" size={'small'} value={icDPH} onInput={e => setICDPH(e.target.value)} />
                                    </div>
                                    : null}
                            </div>

                        </div>
                        : null}

                    {/* ZHRNUTIE */}
                    {index == 4 ?
                        <div style={{ ...styles.Block, height: isSmall ? undefined : boxHeight, minWidth: 250, width: '100%' }}>
                            <div style={{ ...styles.BlockLeft, width: '95%', paddingBottom: 40 }}>
                                <p style={{ ...styles.TextLarge, textAlign: 'center', fontWeight: 'bold' }}>{lang.summaring}</p>
                            </div>
                            <div style={{ ...styles.Block, width: '95%' }}>
                                <div style={{ ...isSmall ? styles.Block : styles.BlockRow }}>
                                    <div style={{ ...styles.BlockLeft, width: isSmall ? '100%' : '30%' }}>
                                        <p style={{ ...styles.TextSmall, fontWeight: 'bold' }}>{lang.welcome_contact}:</p>
                                    </div>
                                    <div style={{ ...styles.BlockLeft, width: isSmall ? '100%' : '70%' }}>
                                        <p style={{ ...styles.TextTiny }}>{name} {surname}</p>
                                        <p style={{ ...styles.TextTiny, marginTop: 3 }}>{lang.mobil.toLowerCase()} {mobil}</p>
                                    </div>
                                </div>
                                <div style={{ ...isSmall ? styles.Block : styles.BlockRow, marginTop: 30 }}>
                                    <div style={{ ...styles.BlockLeft, width: isSmall ? '100%' : '30%' }}>
                                        <p style={{ ...styles.TextSmall, fontWeight: 'bold' }}>{lang.salon_address}:</p>
                                    </div>
                                    <div style={{ ...styles.BlockLeft, width: isSmall ? '100%' : '70%' }}>
                                        <p style={{ ...styles.TextTiny }}>{salonName}</p>
                                        <p style={{ ...styles.TextTiny, marginTop: 3 }}>{salonStreet} {salonStreetNumber}</p>
                                        <p style={{ ...styles.TextTiny, marginTop: 3 }}>{salonPSC} {salonTown}</p>
                                    </div>
                                </div>
                                <div style={{ ...isSmall ? styles.Block : styles.BlockRow, marginTop: 30 }}>
                                    <div style={{ ...styles.BlockLeft, width: isSmall ? '100%' : '30%' }}>
                                        <p style={{ ...styles.TextSmall, fontWeight: 'bold' }}>{lang.firm_data}:</p>
                                    </div>
                                    <div style={{ ...styles.BlockLeft, width: isSmall ? '100%' : '70%' }}>
                                        <p style={{ ...styles.TextTiny }}>{companyName}</p>
                                        <p style={{ ...styles.TextTiny, marginTop: 3 }}>{companyStreet} {companyStreetNumber}</p>
                                        <p style={{ ...styles.TextTiny, marginTop: 3 }}>{companyPSC} {companyTown}</p>
                                        <p style={{ ...styles.TextTiny, marginTop: 15 }}>{lang.ico}: {ico}</p>
                                        <p style={{ ...styles.TextTiny, marginTop: 3 }}>{lang.dic}: {dic}</p>
                                        {icDPH.trim() != '' ?
                                            <p style={{ ...styles.TextTiny, marginTop: 3 }}>{lang.ic_dph}: {icDPH}</p>
                                            : null}
                                    </div>
                                </div>
                                <div style={{ ...styles.BlockLeft, width: '100%' }}>
                                    <FormControlLabel
                                        style={{ textAlign: 'left', margin: 0, marginTop: 20, padding: 0, fontSize: global.font_tiny }}
                                        label={
                                            <p style={{ ...styles.TextTiny }}>{lang.register_gdpr}</p>
                                        }
                                        control={<Checkbox checked={gdpr} size={'small'} onChange={() => setGDPR(!gdpr)} />}
                                    />
                                    <Button onClick={() => setShowRules(true)} style={{ ...styles.ButtonLink, marginLeft:36, color: global.theme_blue, marginTop:10 }}>{lang.gdpr}</Button>

                                </div>
                            </div>
                        </div>
                        : null}


                    <div style={{ ...styles.Block, height: isSmall ? undefined : 110, marginTop: isSmall ? 20 : 0 }}>
                        <div style={{ height: isSmall ? undefined : 50 }}>
                            {isBusy == true ?
                                <CircularProgress color="inherit" size={20} />
                                : err != '' ?
                                    <Alert severity="error">
                                        {err}
                                    </Alert>
                                    : null
                            }
                        </div>
                        <div style={{ ...isSmall ? styles.Block : styles.BlockRight }}>
                            <div style={{ ...styles.BlockRowRaw }}>
                                {index > 0 ?
                                    <Button onClick={() => Back()} style={{ ...styles.ButtonPrev, margin: 10, width: isSmall ? 130 : 150 }}>
                                        <FontAwesomeIcon style={{ width: 16, height: 16, color: global.theme_black, marginRight: 10 }} icon={faChevronLeft} />
                                        {lang.back}
                                    </Button>
                                    : null}

                                <Button onClick={() => Continue()} style={{ ...styles.ButtonNext, backgroundColor: index < 4 ? global.theme_green : gdpr == false ? global.theme_light_green : global.theme_green, margin: 10, width: isSmall ? 130 : 150 }}>
                                    {index < 4 ? lang.continue : lang.save}
                                    {index < 4 ?
                                        <FontAwesomeIcon style={{ width: 16, height: 16, color: global.theme_white, marginLeft: 10 }} icon={faChevronRight} />
                                        : null}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {showRules == true ?
                <Rules title={lang.gdpr} lang={lang} typ={1} salon_id={2} language={language} func={RulesResult.bind(this)} />
                : null}
        </Dialog >
    )
};

export const Line = (props) => {
    /*
        <Line color={} margin={} width={}/>
    */
    return (
        <div style={{ width: props.width != undefined ? props.width : 100, height: 2, backgroundColor: props.color != undefined ? props.color : global.theme_light, marginTop: props.margin == undefined ? 10 : props.margin, marginBottom: props.margin == undefined ? 10 : props.margin }} />
    )
}


export const AlertDialog = (props) => {
    /*
            USAGE:
            <AlertDialog label='' info='' text='' button_text='' func={AlertDialogResult.bind(this)} />

            const AlertDialogResult = (value) => {   
                if(value == true){
                }
            }

            */
    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState('');

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    let { func } = props;
    var lang = sk;

    useEffect(() => {

        return () => {
        };

    }, []);


    const Press = (typ) => {
        func(typ);
    }


    return (
        <Dialog open={true} maxWidth={'lg'}>
            <div style={{ ...styles.BlockRow, background: global.theme_dialog_back, height: 50, justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ width: '100%', paddingLeft: 10 }}>
                    <p style={{ ...styles.TextLarge, color: global.theme_dialog_label }}>{props.label}</p>
                </div>
                <div style={{ width: 50 }}>
                    <IconButton onClick={() => Press(false, false)} style={{ ...styles.BlockRound, width: 30, height: 30, backgroundColor: global.theme_black, alignSelf: 'flex-end' }}>
                        <FontAwesomeIcon style={{ width: 20, height: 20, color: global.theme_white }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>

            <div style={{ ...styles.Block, width: isSmall ? '100%' : 600, paddingTop: 30, paddingBottom: 30 }}>
                <p style={{ ...styles.TextNormal, fontWeight: 'bold', textAlign: 'center' }}>{props.info}</p>
                <p style={{ ...styles.TextTiny, marginTop: 10, textAlign: 'center' }}>{props.text}</p>
            </div>

            <div style={{ ...styles.Block, paddingBottom: 10 }}>
                <Button onClick={() => Press(false)} style={{ ...styles.ButtonBack, margin: 10 }}>{props.button_text}</Button>
            </div>
        </Dialog >
    );
}

export const SalonSettings = (props) => {
    /* ZAKLADNE NASTAVENIA SALONA

            USAGE:
            <SalonSettings user={user} func={SalonSettingsResult.bind(this)} />

            const SalonSettingsResult = (typ, value) => {                
            }

    */


    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const [index, setIndex] = useState(0);
    const [language, setLanguage] = useState(props.language);

    const [isBusy, setBusy] = useState(false);

    // ZAKLADNE UDAJE
    const [name, setName] = useState('');
    const [nameErr, setNameErr] = useState('');
    const [surname, setSurname] = useState('');
    const [surnameErr, setSurnameErr] = useState('');
    const [mobil, setMobil] = useState('');
    const [mobilErr, setMobilErr] = useState('');

    // SALON
    const [salonName, setSalonName] = useState('');
    const [salonNameErr, setSalonNameErr] = useState('');
    const [salonStreet, setSalonStreet] = useState('');
    const [salonStreetErr, setSalonStreetErr] = useState('');
    const [salonStreetNumber, setSalonStreetNumber] = useState('');
    const [salonPSC, setSalonPSC] = useState('');
    const [salonPSCErr, setSalonPSCErr] = useState('');
    const [salonTown, setSalonTown] = useState('');
    const [salonTownErr, setSalonTownErr] = useState('');

    // FIREMNE UDAJE
    const [companyName, setCompanyName] = useState('');
    const [companyNameErr, setCompanyNameErr] = useState('');
    const [companyStreet, setCompanyStreet] = useState('');
    const [companyStreetErr, setCompanyStreetErr] = useState('');
    const [companyStreetNumber, setCompanyStreetNumber] = useState('');
    const [companyPSC, setCompanyPSC] = useState('');
    const [companyPSCErr, setCompanyPSCErr] = useState('');
    const [companyTown, setCompanyTown] = useState('');
    const [companyTownErr, setCompanyTownErr] = useState('');
    const [ico, setICO] = useState('');
    const [icoErr, setICOErr] = useState('');
    const [dic, setDIC] = useState('');
    const [dicErr, setDICErr] = useState('');
    const [icDPH, setICDPH] = useState('');

    // OBCHODNY ZASTUPCA
    const [agents, setAgents] = useState([]);
    const [agentID, setAgentID] = useState(0);

    const [err, setErr] = useState('');
    const [showOK, setShowOK] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const boxHeight = 480;

    var lang = language;
    let { func } = props;

    useEffect(() => {

        if (props.user != null) {
            setName(props.user.name == null ? '' : props.user.name);
            setSurname(props.user.surname == null ? '' : props.user.surname);
            setMobil(props.user.mobil == null ? '' : props.user.mobil);
            setSalonName(props.user.salon_name == null ? '' : props.user.salon_name);
            setSalonStreet(props.user.salon_street == null ? '' : props.user.salon_street);
            setSalonStreetNumber(props.user.salon_street_number == null ? '' : props.user.salon_street_number);
            setSalonPSC(props.user.salon_psc == null ? '' : props.user.salon_psc);
            setSalonTown(props.user.salon_town == null ? '' : props.user.salon_town);
            setCompanyName(props.user.company == null ? '' : props.user.company);
            setCompanyStreet(props.user.street == null ? '' : props.user.street);
            setCompanyStreetNumber(props.user.street_number == null ? '' : props.user.street_number);
            setCompanyPSC(props.user.psc == null ? '' : props.user.psc);
            setCompanyTown(props.user.town == null ? '' : props.user.town);
            setICO(props.user.ico == null ? '' : props.user.ico);
            setDIC(props.user.dic == null ? '' : props.user.dic);
            setICDPH(props.user.ic_dph == null ? '' : props.user.ic_dph);
            setAgentID(props.user.agent_id);
        }

        db_settings();

        return () => {
        };

    }, []);

    const Press = (id, data) => {
        func(id, data);
    }

    const db_settings = async () => {

        if (isBusy == false) {
            setBusy(true);

            try {
                const response = await fetch(
                    global.db_url + 'settings', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        user_id: props.user.id,
                    })
                })

                const json = await response.json();

                if (json.ok > 0) {
                    setAgents(json.agents);
                }
                setBusy(false);

            } catch (error) {
                Debug(error);
                setBusy(false);
            }
        }
    };


    const db_update = async (data) => {

        if (isBusy == false) {
            setBusy(true);

            try {
                const response = await fetch(
                    global.db_url + 'login_update', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        id: props.user.id,
                        data: data
                    })
                })

                const json = await response.json();
                setBusy(false);

                if (json.ok > 0) {
                    var user = json.user;
                    //SaveUser(user);
                    //SaveLanguage(lang.lang_id);
                    setShowOK(true);

                }

            } catch (error) {
                Debug(error);
                setBusy(false);
            }
        }
    };

    const ShowOKPress = (value) => {
        setShowOK(false);
        Press(true);
    }

    const Back = () => {
        var i = index;
        if (i > 0) {
            i--;
            setIndex(i);
        }
    }

    const Check = () => {
        var error = '';
        var ok = true;

        setNameErr('');
        setSurnameErr('');
        setMobilErr('');

        setSalonNameErr('');
        setSalonStreetErr('');
        setSalonPSCErr('');
        setSalonTownErr('');

        setCompanyNameErr('');
        setCompanyStreetErr('');
        setCompanyPSCErr('');
        setCompanyTownErr('');
        setICOErr('');
        setDICErr('');

        // KONTAKTNA OSOBA
        if (name.trim() == '') {
            error = lang.required;
            setNameErr(error);
        }

        if (surname.trim() == '') {
            error = lang.required;
            setSurnameErr(error);
        }

        if (mobil.trim() == '') {
            error = lang.required;
            setMobilErr(error);
        } else if (mobil.length < 8) {
            error = lang.required;
            setMobilErr(error);
        }
        if (error != '') {
            setIndex(2);
            ok = false;
        }

        error = '';
        // ZAKLADNE UDAJE - SALON
        if (salonName.trim() == '') {
            error = lang.required;
            setSalonNameErr(error);
        }

        if (salonStreet.trim() == '') {
            error = lang.required;
            setSalonStreetErr(error);
        }

        if (salonPSC.trim() == '') {
            error = lang.required;
            setSalonPSCErr(error);
        }

        if (salonTown.trim() == '') {
            error = lang.required;
            setSalonTownErr(error);
        }
        if (error != '') {
            setIndex(0);
            ok = false;
        }

        error = '';
        // FIREMNE UDAJE
        if (companyName.trim() == '') {
            error = lang.required;
            setCompanyNameErr(error);
        }

        if (companyStreet.trim() == '') {
            error = lang.required;
            setCompanyStreetErr(error);
        }

        if (companyPSC.trim() == '') {
            error = lang.required;
            setCompanyPSCErr(error);
        }

        if (companyTown.trim() == '') {
            error = lang.required;
            setCompanyTownErr(error);
        }

        if (ico.trim() == '') {
            error = lang.required;
            setICOErr(error);
        }

        if (dic.trim() == '') {
            error = lang.required;
            setDICErr(error);
        }
        if (error != '') {
            setIndex(1);
            ok = false;
        }

        if (ok == true) {
            Save();
        }
    }

    const Save = () => {
        var data = props.user;
        data.name = name;
        data.surname = surname;
        data.mobil = mobil;
        data.company = companyName;
        data.street = companyStreet;
        data.street_number = companyStreetNumber;
        data.psc = companyPSC;
        data.town = companyTown;
        data.ico = ico;
        data.dic = dic;
        data.ic_dph = icDPH;
        data.salon_name = salonName;
        data.salon_street = salonStreet;
        data.salon_street_number = salonStreetNumber;
        data.salon_psc = salonPSC;
        data.salon_town = salonTown;
        data.password = '';
        data.agent_id = agentID;

        db_update(data);
    }

    const changeAgent = (event) => {
        var value = parseInt(event.target.value);
        setAgentID(value);
    }

    return (
        <Paper elevation={3} style={{ ...styles.Block, maxWidth: global.max_width, marginTop: isSmall ? 0 : 20 }}>
            <div style={{ ...isSmall ? styles.Block : styles.BlockRow, width: isSmall ? '100%' : 800 }}>
                {isSmall ?
                    <div style={{ ...styles.Block, paddingTop: 10, paddingBottom: 10, backgroundColor: global.theme_light }}>
                        <Button onClick={() => setIndex(0)} style={{ ...styles.ButtonRaw, backgroundColor: index == 0 ? global.theme_gray : global.theme_light, color: global.theme_black, width: 200, height: 36, marginTop: 0 }}>{lang.sett_basic}</Button>
                        <Button onClick={() => setIndex(1)} style={{ ...styles.ButtonRaw, backgroundColor: index == 1 ? global.theme_gray : global.theme_light, color: global.theme_black, width: 200, height: 36, marginTop: 0 }}>{lang.sett_firm}</Button>
                        <Button onClick={() => setIndex(2)} style={{ ...styles.ButtonRaw, backgroundColor: index == 2 ? global.theme_gray : global.theme_light, color: global.theme_black, width: 200, height: 36, marginTop: 0 }}>{lang.sett_user}</Button>
                        <Button onClick={() => setIndex(3)} style={{ ...styles.ButtonRaw, backgroundColor: index == 3 ? global.theme_gray : global.theme_light, color: global.theme_black, width: 200, height: 36, marginTop: 0 }}>{lang.sett_eshop}</Button>
                        <Button onClick={() => setIndex(4)} style={{ ...styles.ButtonRaw, backgroundColor: index == 4 ? global.theme_gray : global.theme_light, color: global.theme_black, width: 200, height: 36, marginTop: 0 }}>{lang.sett_system}</Button>
                    </div>
                    :
                    <div style={{ ...styles.BlockLeft, width: '30%', backgroundColor: global.theme_gray, height: boxHeight + 110 }}>
                        <Paper onClick={() => setIndex(0)} elevation={index == 0 ? 1 : 0} style={{ ...styles.BlockRow, background: index == 0 ? global.theme_gradient_gray_gray : global.theme_gradient_gray, height: 60, alignItems: 'center', borderRadius: 0, marginTop: 0, borderTopLeftRadius: index == 0 ? 30 : 0, borderBottomLeftRadius: index == 0 ? 30 : 0, cursor: 'pointer' }}>
                            <div style={{ ...styles.BlockRound, backgroundColor: index == 0 ? global.theme_blue : global.theme_gray, marginLeft: 10 }}>
                                <p style={{ ...styles.TextSmall, color: index == 0 ? global.theme_white : global.theme_black }}>1.</p>
                            </div>
                            <p style={{ ...styles.TextSmall, color: global.theme_black, marginLeft: 10 }}>{lang.sett_basic}</p>
                        </Paper>
                        <Paper onClick={() => setIndex(1)} elevation={index == 1 ? 1 : 0} style={{ ...styles.BlockRow, background: index == 1 ? global.theme_gradient_gray_gray : global.theme_gradient_gray, height: 60, alignItems: 'center', borderRadius: 0, marginTop: 0, borderTopLeftRadius: index == 1 ? 30 : 0, borderBottomLeftRadius: index == 1 ? 30 : 0, cursor: 'pointer', marginTop: 10 }}>
                            <div style={{ ...styles.BlockRound, backgroundColor: index == 1 ? global.theme_blue : global.theme_gray, marginLeft: 10 }}>
                                <p style={{ ...styles.TextSmall, color: index == 1 ? global.theme_white : global.theme_black }}>2.</p>
                            </div>
                            <p style={{ ...styles.TextSmall, color: global.theme_black, marginLeft: 10 }}>{lang.sett_firm}</p>
                        </Paper>
                        <Paper onClick={() => setIndex(2)} elevation={index == 2 ? 1 : 0} style={{ ...styles.BlockRow, background: index == 2 ? global.theme_gradient_gray_gray : global.theme_gradient_gray, height: 60, alignItems: 'center', borderRadius: 0, marginTop: 0, borderTopLeftRadius: index == 2 ? 30 : 0, borderBottomLeftRadius: index == 2 ? 30 : 0, cursor: 'pointer', marginTop: 10 }}>
                            <div style={{ ...styles.BlockRound, backgroundColor: index == 2 ? global.theme_blue : global.theme_gray, marginLeft: 10 }}>
                                <p style={{ ...styles.TextSmall, color: index == 2 ? global.theme_white : global.theme_black }}>3.</p>
                            </div>
                            <p style={{ ...styles.TextSmall, color: global.theme_black, marginLeft: 10 }}>{lang.sett_user}</p>
                        </Paper>
                        <Paper onClick={() => setIndex(3)} elevation={index == 3 ? 1 : 0} style={{ ...styles.BlockRow, background: index == 3 ? global.theme_gradient_gray_gray : global.theme_gradient_gray, height: 60, alignItems: 'center', borderRadius: 0, marginTop: 0, borderTopLeftRadius: index == 3 ? 30 : 0, borderBottomLeftRadius: index == 3 ? 30 : 0, cursor: 'pointer', marginTop: 10 }}>
                            <div style={{ ...styles.BlockRound, backgroundColor: index == 3 ? global.theme_blue : global.theme_gray, marginLeft: 10 }}>
                                <p style={{ ...styles.TextSmall, color: index == 3 ? global.theme_white : global.theme_black }}>4.</p>
                            </div>
                            <p style={{ ...styles.TextSmall, color: global.theme_black, marginLeft: 10 }}>{lang.sett_orders}</p>
                        </Paper>
                        <Paper onClick={() => setIndex(4)} elevation={index == 4 ? 1 : 0} style={{ ...styles.BlockRow, background: index == 4 ? global.theme_gradient_gray_gray : global.theme_gradient_gray, height: 60, alignItems: 'center', borderRadius: 0, marginTop: 0, borderTopLeftRadius: index == 4 ? 30 : 0, borderBottomLeftRadius: index == 4 ? 30 : 0, cursor: 'pointer', marginTop: 10 }}>
                            <div style={{ ...styles.BlockRound, backgroundColor: index == 4 ? global.theme_blue : global.theme_gray, marginLeft: 10 }}>
                                <p style={{ ...styles.TextSmall, color: index == 4 ? global.theme_white : global.theme_black }}>5.</p>
                            </div>
                            <p style={{ ...styles.TextSmall, color: global.theme_black, marginLeft: 10 }}>{lang.sett_system}</p>
                        </Paper>
                    </div>
                }
                <div style={{ ...styles.BlockLeft, width: isSmall ? '100%' : '70%', paddingTop: isSmall ? 20 : 0, paddingBottom: isSmall ? 20 : 0 }}>


                    {/* ADRESA SALONA */}
                    {index == 0 ?
                        <div style={{ ...styles.Block, height: isSmall ? undefined : boxHeight, minWidth: 250, width: '100%' }}>
                            <div style={{ ...styles.Block, paddingBottom: 0 }}>
                                <p style={{ ...styles.TextLarge, textAlign: 'center' }}>{lang.salon_address_text}</p>
                                <Line color={global.theme_lighter} margin={20} />
                            </div>
                            <div style={{ ...styles.BlockLeft, width: isSmall ? '95%' : '70%' }}>
                                <div style={{ ...styles.BlockLeft, width: '100%' }}>
                                    <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>{lang.salon_name}</p>
                                    <TextField error={salonNameErr == '' ? false : true} helperText={salonNameErr} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" size={'small'} value={salonName} onInput={e => setSalonName(e.target.value)} />
                                </div>
                            </div>
                            <div style={{ ...styles.BlockRow, width: isSmall ? '95%' : '70%', marginTop: 20 }}>
                                <div style={{ ...styles.BlockLeft, width: '70%', marginRight: isSmall ? 5 : 10 }}>
                                    <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>{lang.street}</p>
                                    <TextField error={salonStreetErr == '' ? false : true} helperText={salonStreetErr} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" size={'small'} value={salonStreet} onInput={e => setSalonStreet(e.target.value)} />
                                </div>
                                <div style={{ ...styles.BlockLeft, width: '30%', marginLeft: isSmall ? 5 : 10 }}>
                                    <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>{lang.street_number}</p>
                                    <TextField style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" size={'small'} value={salonStreetNumber} onInput={e => setSalonStreetNumber(e.target.value)} />
                                </div>
                            </div>
                            <div style={{ ...styles.BlockRow, width: isSmall ? '95%' : '70%', marginTop: 20 }}>
                                <div style={{ ...styles.BlockLeft, width: '30%', marginRight: isSmall ? 5 : 10 }}>
                                    <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>{lang.psc}</p>
                                    <TextField error={salonPSCErr == '' ? false : true} helperText={salonPSCErr} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" size={'small'} value={salonPSC} onInput={e => setSalonPSC(e.target.value)} />
                                </div>
                                <div style={{ ...styles.BlockLeft, width: '70%', marginLeft: isSmall ? 5 : 10 }}>
                                    <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>{lang.town}</p>
                                    <TextField error={salonTownErr == '' ? false : true} helperText={salonTownErr} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" size={'small'} value={salonTown} onInput={e => setSalonTown(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        : null}

                    {/* FIREMNE UDAJE */}
                    {index == 1 ?
                        <div style={{ ...styles.Block, height: isSmall ? undefined : boxHeight, minWidth: 250, width: '100%' }}>
                            <div style={{ ...styles.Block, paddingBottom: 0 }}>
                                <p style={{ ...styles.TextLarge, textAlign: 'center' }}>{lang.firm_data}</p>
                                <Line color={global.theme_lighter} margin={20} />
                            </div>
                            <div style={{ ...styles.BlockLeft, width: isSmall ? '95%' : '70%' }}>
                                <div style={{ ...styles.BlockLeft, width: '100%' }}>
                                    <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>{lang.company}</p>
                                    <TextField error={companyNameErr == '' ? false : true} helperText={companyNameErr} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" size={'small'} value={companyName} onInput={e => setCompanyName(e.target.value)} />
                                </div>
                            </div>
                            <div style={{ ...styles.BlockRow, width: isSmall ? '95%' : '70%', marginTop: 20 }}>
                                <div style={{ ...styles.BlockLeft, width: '70%', marginRight: isSmall ? 5 : 10 }}>
                                    <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>{lang.street}</p>
                                    <TextField error={companyStreetErr == '' ? false : true} helperText={companyStreetErr} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" size={'small'} value={companyStreet} onInput={e => setCompanyStreet(e.target.value)} />
                                </div>
                                <div style={{ ...styles.BlockLeft, width: '30%', marginLeft: isSmall ? 5 : 10 }}>
                                    <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>{lang.street_number}</p>
                                    <TextField style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" size={'small'} value={companyStreetNumber} onInput={e => setCompanyStreetNumber(e.target.value)} />
                                </div>
                            </div>
                            <div style={{ ...styles.BlockRow, width: isSmall ? '95%' : '70%', marginTop: 20 }}>
                                <div style={{ ...styles.BlockLeft, width: '30%', marginRight: isSmall ? 5 : 10 }}>
                                    <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>{lang.psc}</p>
                                    <TextField error={companyPSCErr == '' ? false : true} helperText={companyPSCErr} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" size={'small'} value={companyPSC} onInput={e => setCompanyPSC(e.target.value)} />
                                </div>
                                <div style={{ ...styles.BlockLeft, width: '70%', marginLeft: isSmall ? 5 : 10 }}>
                                    <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>{lang.town}</p>
                                    <TextField error={companyTownErr == '' ? false : true} helperText={companyTownErr} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" size={'small'} value={companyTown} onInput={e => setCompanyTown(e.target.value)} />
                                </div>
                            </div>
                            <div style={{ ...isSmall ? styles.BlockLeft : styles.BlockRow, width: isSmall ? '95%' : '70%', marginTop: 20 }}>
                                <div style={{ ...styles.BlockLeft, width: isSmall ? '100%' : '33%', marginRight: isSmall ? 0 : 10 }}>
                                    <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>{lang.ico}</p>
                                    <TextField error={icoErr == '' ? false : true} helperText={icoErr} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" size={'small'} value={ico} onInput={e => setICO(e.target.value)} />
                                </div>
                                <div style={{ ...styles.BlockLeft, width: isSmall ? '100%' : '33%', marginRight: isSmall ? 0 : 10, marginTop: isSmall ? 20 : 0 }}>
                                    <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>{lang.dic}</p>
                                    <TextField error={dicErr == '' ? false : true} helperText={dicErr} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" size={'small'} value={dic} onInput={e => setDIC(e.target.value)} />
                                </div>
                                {language.lang_id == 0 ?
                                    <div style={{ ...styles.BlockLeft, width: isSmall ? '100%' : '33%', marginTop: isSmall ? 20 : 0 }}>
                                        <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>{lang.ic_dph}</p>
                                        <TextField style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" size={'small'} value={icDPH} onInput={e => setICDPH(e.target.value)} />
                                    </div>
                                    : null}
                            </div>

                        </div>
                        : null}


                    {/* MENO, PRIEZVISKO, MOBIL */}
                    {index == 2 ?
                        <div style={{ ...styles.Block, height: isSmall ? undefined : boxHeight, minWidth: 250 }}>
                            <div style={{ ...styles.Block, paddingBottom: 0 }}>
                                <p style={{ ...styles.TextLarge, textAlign: 'center' }}>{lang.welcome_contact}</p>
                                <p style={{ ...styles.TextTiny, textAlign: 'center', marginTop: 5 }}>{lang.welcome_contact_text}</p>
                                <Line color={global.theme_lighter} margin={20} />
                            </div>
                            <div style={{ ...isSmall ? styles.Block : styles.BlockRow, width: '95%' }}>
                                <div style={{ ...styles.BlockLeft, width: isSmall ? '100%' : '33%' }}>
                                    <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>{lang.name}</p>
                                    <TextField error={nameErr == '' ? false : true} helperText={nameErr} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" size={'small'} value={name} onInput={e => setName(e.target.value)} />
                                </div>
                                <div style={{ ...styles.BlockLeft, width: isSmall ? '100%' : '33%', marginLeft: isSmall ? 0 : 10, marginTop: isSmall ? 20 : 0 }}>
                                    <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>{lang.surname}</p>
                                    <TextField error={surnameErr == '' ? false : true} helperText={surnameErr} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" size={'small'} value={surname} onInput={e => setSurname(e.target.value)} />
                                </div>
                                <div style={{ ...styles.BlockLeft, width: isSmall ? '100%' : '33%', marginLeft: isSmall ? 0 : 10, marginTop: isSmall ? 20 : 0 }}>
                                    <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>{lang.mobil}</p>
                                    <TextField error={mobilErr == '' ? false : true} helperText={mobilErr} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" size={'small'} value={mobil} onInput={e => setMobil(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        : null}

                    {/* ESHOP, OBJEDNAVKY */}
                    {index == 3 ?
                        <div style={{ ...styles.Block, height: isSmall ? undefined : boxHeight, minWidth: 250 }}>
                            <div style={{ ...styles.Block, paddingBottom: 0 }}>
                                <p style={{ ...styles.TextLarge, textAlign: 'center' }}>{lang.sett_orders}</p>
                                <Line color={global.theme_lighter} margin={20} />
                            </div>
                            <div style={{ ...styles.Block }}>
                                <div style={{ ...styles.Block, width: '95%' }}>
                                    <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>{lang.sett_agent}</p>
                                    {agents.length > 0 ?
                                        <Select
                                            color="primary"
                                            value={agentID}
                                            label={''}
                                            onChange={changeAgent}
                                            style={{ width: 300, textAlign: 'left' }}
                                            size={'small'}
                                        >
                                            {agents.map(item => (
                                                <MenuItem key={item.id} value={item.id}>{item.name} {item.surname} ({item.country})</MenuItem >
                                            ))}
                                        </Select>
                                        : null}
                                </div>
                            </div>
                        </div>
                        : null}

                    {/* SYSTEMOVE NASTAVENIA */}
                    {index == 4 ?
                        <div style={{ ...styles.Block, height: isSmall ? undefined : boxHeight, minWidth: 250 }}>
                            <div style={{ ...styles.Block, paddingBottom: 0 }}>
                                <p style={{ ...styles.TextLarge, textAlign: 'center' }}>{lang.sett_system}</p>
                                <Line color={global.theme_lighter} margin={20} />
                            </div>
                            <div style={{ ...isSmall ? styles.Block : styles.BlockRow, width: '95%' }}>

                            </div>
                        </div>
                        : null}


                    <div style={{ ...styles.Block, height: isSmall ? undefined : 110, marginTop: isSmall ? 20 : 0 }}>
                        <div style={{ height: isSmall ? undefined : 50 }}>
                            {isBusy == true ?
                                <CircularProgress color="inherit" size={20} />
                                : err != '' ?
                                    <Alert severity="error">
                                        {err}
                                    </Alert>
                                    : null
                            }
                        </div>
                        <div style={{ ...isSmall ? styles.Block : styles.BlockRight }}>
                            <div style={{ ...styles.BlockRowRaw }}>
                                <Button onClick={() => Check()} style={{ ...styles.ButtonNext, backgroundColor: global.theme_green, margin: 10, width: isSmall ? 130 : 150 }}>
                                    {lang.save}
                                </Button>
                                <Button onClick={() => Press(false)} style={{ ...styles.ButtonBack, margin: 10, width: isSmall ? 130 : 150 }}>
                                    {lang.close}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {showOK == true ?
                <ShowOK func={ShowOKPress.bind(this)} />
                : null}

        </Paper >
    )
};


export const Sections = (props) => {
    /*

            USAGE:
            <Sections section_id={sectionID} sections={[section1, section2, section3, lang.section_cabin, lang.section_action]} backcolor={global.theme_gray} func={SectionsResult.bind(this)} />

            const SectionsResult = (id) => {                
            }

    */


    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const [selected, setSelected] = useState(0);
    const favorites = 10;

    var lang = sk;
    let { func } = props;

    useEffect(() => {
        setSelected(props.section_id);

        return () => {
        };

    }, [props.section_id]);

    const Press = (id) => {
        setSelected(id);
        func(id);
    }


    return (
        <div style={{ ...styles.Block, paddingTop: 10, paddingBottom: 10, backgroundColor: props.backcolor }}>
            <p style={{ ...styles.TextNormal }}> {selected == favorites ? lang.favourites : lang.sections}</p>
            <div style={{ ...isSmall ? styles.BlockRowRaw : styles.BlockRowRaw, flexWrap: isSmall ? 'wrap' : undefined, width: isSmall ? 300 : '100%', marginTop: 10 }}>
                {props.sections.map((item, index) => (
                    <div key={index} onClick={() => Press(index)} style={{ ...styles.ButtonSection, margin: 3, backgroundColor: selected == index ? global.theme_light_blue : global.theme_white, border: selected == index ? '1px solid ' + global.theme_light_blue : '1px solid ' + global.theme_dark, }}>
                        <p style={{ ...styles.TextTiny, color: selected == index ? global.theme_black : global.theme_dark }}>{item}</p>
                    </div>
                ))}
                {isSmall ?
                    <div style={{ ...styles.ButtonSection, margin: 3 }}>
                        <div onClick={() => Press(favorites)} style={{ ...styles.ButtonSection, width: 36, margin: 0, backgroundColor: selected == favorites ? global.theme_light_blue : global.theme_white, border: selected == favorites ? '1px solid ' + global.theme_light_blue : '1px solid ' + global.theme_dark, }}>
                            <FontAwesomeIcon style={{ width: 16, height: 16, color: selected == favorites ? global.theme_red : global.theme_light }} icon={faHeart} />
                        </div>
                    </div>

                    :
                    <div onClick={() => Press(favorites)} style={{ ...styles.ButtonSection, width: 36, margin: 3, backgroundColor: selected == favorites ? global.theme_light_blue : global.theme_white, border: selected == favorites ? '1px solid ' + global.theme_light_blue : '1px solid ' + global.theme_dark, }}>
                        <FontAwesomeIcon style={{ width: 16, height: 16, color: selected == favorites ? global.theme_red : global.theme_light }} icon={faHeart} />
                    </div>
                }
            </div>
        </div >
    )

};


export const SearchTextfield = (props) => {
    /*
            USAGE:
            <SearchTextfield language={lang} search={search} func={SearchTextfieldResult.bind(this)} />

            const SearchTextfieldResult = (value) => {                                
            }

            */
    const [string, setString] = useState('');

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    let { func } = props;
    var lang = props.language;

    useEffect(() => {
        //setString(props.search);

        return () => {
        };

    }, []);


    const Press = (value) => {
        setString(value);
        func(value);
    }

    return (
        <TextField style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none', borderRadius: 25 }}
            autoCapitalize='none'
            label={''}
            variant="outlined"
            size={'small'}
            value={string}
            onInput={e => Press(e.target.value)}
            placeholder={lang.search}
            InputProps={{
                type: 'search',
                startAdornment: <InputAdornment position="start"><FontAwesomeIcon style={{ width: 16, height: 16, color: global.theme_medium_gray }} icon={faSearch} /></InputAdornment>,
            }}
            sx={{
                '& fieldset': {
                    borderRadius: '20px',
                }
            }}
        />
    );
}


export const AgentChoose = (props) => {
    /*
            VYBER OBCHODNEHO ZASTUPCU

            USAGE:
            <AgentChoose user={user} func={AgentChooseResult.bind(this)} />

            const AgentChooseResult = (value) => {   
            }

            */
    const [isBusy, setBusy] = useState(true);
    const [agents, setAgents] = useState([]);
    const [agentID, setAgentID] = useState(0);

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    let { func } = props;
    var lang = sk;

    useEffect(() => {
        db_agents();

        return () => {
        };

    }, []);


    const db_agents = async () => {

        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + 'agents', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: props.user.id,
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setAgents(json.agents);
            }
            setBusy(false);

        } catch (error) {
            Debug(error);
            setBusy(false);
        }
    };


    const db_agent_update = async () => {

        if (isBusy == false) {
            setBusy(true);

            try {
                const response = await fetch(
                    global.db_url + 'agent_update', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        salon_id: props.user.id,
                        agent_id: agentID
                    })
                })

                const json = await response.json();

                if (json.ok > 0) {
                    func(agentID);
                }
                setBusy(false);

            } catch (error) {
                Debug(error);
                setBusy(false);
            }
        }
    };


    const Save = () => {
        db_agent_update();
    }

    const changeAgent = (event) => {
        var value = parseInt(event.target.value);
        setAgentID(value);
    }

    return (
        <Dialog open={true} maxWidth={'lg'}>
            <div style={{ ...styles.Block, background: global.theme_dialog_back, height: 50, justifyContent: 'center', alignItems: 'center' }}>
                <p style={{ ...styles.TextLarge, color: global.theme_dialog_label }}>{lang.agent}</p>
            </div>

            <div style={{ ...styles.Block, width: isSmall ? '100%' : 500, paddingTop: 20, paddingBottom: 30 }}>
                <div style={{ ...styles.Block, width: '90%' }}>
                    <p style={{ ...styles.TextSmall, textAlign: 'center', fontWeight: 'normal', color: global.theme_dark_gray }}>{lang.agent_choose}</p>
                    <div style={{ ...styles.Block, paddingTop: 30, paddingBottom: 30 }}>
                        <img src={global.images + 'app/logo_ie.png'} width={150} />
                    </div>
                    <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>{lang.sett_agent}</p>
                    {agents.length > 0 ?
                        <Select
                            color="primary"
                            value={agentID}
                            label={''}
                            onChange={changeAgent}
                            style={{ width: 300, textAlign: 'left' }}
                            size={'small'}
                        >
                            <MenuItem value={0}>{lang.choose}</MenuItem >
                            {agents.map(item => (
                                <MenuItem key={item.id} value={item.id}>{item.name} {item.surname} ({item.country})</MenuItem >
                            ))}
                        </Select>
                        : null}
                </div>
            </div>

            <div style={{ ...styles.Block, paddingBottom: 10 }}>
                {agentID > 0 ?
                    <Button onClick={() => Save()} style={{ ...styles.ButtonSave, margin: 10 }}>{lang.save}</Button>
                    : null}
            </div>
        </Dialog >
    );
}


export const ReklamaciaDialog = (props) => {
    /*
            USAGE:
            <ReklamaciaDialog user={props.user} lang={lang} language={language} func={ReklamaciaDialogPress.bind(this)} />

            const ReklamaciaDialogPress = (value) => {
                Debug(value)
            }
            */
    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState('');
    const [showOk, setShowOK] = useState(false);
    const [text, setText] = useState('');
    const [image, setImage] = useState(null);
    const [imageName, setImageName] = useState(null);
    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    let { func } = props;
    var lang = props.language;

    useEffect(() => {

        return () => {
        };

    }, []);


    const Press = (typ) => {
        func(typ);
    }


    const db_send = async () => {
        if (isBusy == false) {

            setBusy(true);
            setError('');
            try {
                const response = await fetch(
                    global.db_url + 'reklamacia_send', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        language: props.language.lang_id,
                        salon_id: props.user.id,
                        text: text,
                        image: imageName
                    })
                })

                const json = await response.json();

                if (json.ok > 0) {
                    if (json.sended == true) {
                        setShowOK(true);
                    } else {
                        setError(lang.order_reklamacia_error);
                    }
                } else {
                    setError(lang.order_reklamacia_error);
                }
                setBusy(false);
            } catch (error) {
                Debug(error);
                setBusy(false);
            }
        }
    }

    const Save = () => {
        db_send();
    }

    const ChooseImagePress = (filename) => {
        setImageName(filename);
    }

    return (
        <Dialog open={true} fullScreen={isSmall ? true : false} maxWidth={'lg'} style={{ marginLeft: isSmall ? 0 : global.menu_width - 40 }}>
            <div style={{ ...styles.BlockRow, background: global.theme_dialog_back, height: 50, justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ width: '100%', paddingLeft: 10 }}>
                    <p style={{ ...styles.TextLarge, color: global.theme_dialog_label }}>{lang.order_reklamacia}</p>
                </div>
                <div style={{ width: 50 }}>
                    <IconButton onClick={() => Press(false, false)} style={{ ...styles.BlockRound, width: 30, height: 30, backgroundColor: global.theme_black, alignSelf: 'flex-end' }}>
                        <FontAwesomeIcon style={{ width: 20, height: 20, color: global.theme_white }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>

            {showOk == false ?
                <div style={{ ...styles.Block, paddingTop: 20, width: isSmall ? '100%' : 600 }}>
                    <div style={{ ...styles.BlockLeft, width: '90%' }}>
                        <p style={{ ...styles.TextSmall, marginBottom: 5 }}>{lang.order_reklamacia_text}</p>
                        <TextField
                            rows={4}
                            multiline={true}
                            variant="outlined"
                            label={''}
                            value={text}
                            size={'small'}
                            style={{ width: '100%' }}
                            inputProps={{ maxLength: 1000 }}
                            onInput={e => setText(e.target.value)}
                        />
                        <div style={{ ...styles.Block, marginTop: 20 }}>
                            <ChooseImage folder={''} type={global.image_reklamacia} label={lang.order_reklamacia_image} note={'foto'} image_name={imageName} image={image} func={ChooseImagePress.bind(this)} />
                        </div>
                    </div>
                </div>
                :
                <div style={{ ...styles.Block, paddingTop: 20, width: isSmall ? '100%' : 600 }}>
                    <div style={{ ...styles.Block, width: '95%', marginTop: 50, marginBottom: 30 }}>
                        <div style={{ ...styles.BlockRound, width: 50, height: 50 }}>
                            <FontAwesomeIcon style={{ width: 35, height: 35, color: global.theme_white }} icon={faCheck} />
                        </div>
                        <p style={{ ...styles.TextLarge, marginTop: 30, textAlign: 'center' }}>{lang.order_reklamacia_sended}</p>
                        <div style={{ ...styles.Block, width: '50%', paddingTop: 40 }}>
                            <Button onClick={() => Press(true)} style={{ ...styles.ButtonBack, margin: 10 }}>{lang.close}</Button>
                        </div>
                    </div>
                </div>
            }

            {showOk == false ?
                <div style={{ ...styles.Block }}>
                    <div style={{ ...styles.Block }}>
                        <p style={{ ...styles.TextSmall, textAlign: 'center' }}>{lang.order_reklamacia_send_text}</p>
                    </div>
                    {error != '' ?
                        <div style={{ ...styles.Block, paddingTop: 10 }}>
                            <p style={{ ...styles.TextSmall, textAlign: 'center', color: global.theme_red, fontWeight: 'bold' }}>{error}</p>
                        </div>
                        : null}
                    <div style={{ ...isSmall ? styles.Block : styles.BlockRow, width: undefined, paddingTop: 10, paddingBottom: 10 }}>
                        <Button disabled={text.trim() == '' ? true : false} onClick={() => Save()} style={{ ...styles.ButtonGreen, backgroundColor: text == '' ? global.theme_light_green : global.theme_green, margin: 10 }}>{lang.send}</Button>
                        <Button onClick={() => Press(false, false)} style={{ ...styles.ButtonBack, margin: 10 }}>{lang.cancel}</Button>
                    </div>
                </div>
                : null}

            {isBusy == true ?
                <Loading center={true}></Loading>
                : null}

        </Dialog >
    );
}


export const BannerImage = (props) => {
    /*
        <BannerImage banner={banner} />
    */
    const [showImage, setShowImage] = useState(false);

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const navigate = useNavigate();

    var lang = sk;


    return (
        <div style={{ ...styles.Block }}>
            {isSmall == true ?
                <img onClick={() => setShowImage(true)} src={global.ads + '/' + props.banner.image} style={{ width: '100%', height: '100%', minHeight: '100%', cursor: 'pointer' }} />
                :
                <img onClick={() => setShowImage(true)} src={global.ads + '/' + props.banner.image} style={{ width: '100%', height: '100%', minHeight: '100%', cursor: 'pointer' }} />
            }

            {showImage == true ?
                <Dialog open={true} maxWidth={'lg'} onBackdropClick={() => setShowImage(false)} >
                    <div style={{ ...styles.BlockRow, background: global.theme_dialog_back, height: 50, justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ width: '100%', paddingLeft: 10 }}>
                            <p style={{ ...styles.TextLarge, color: global.theme_white }}>{lang.image_preview}</p>
                        </div>
                        <div style={{ width: 50 }}>
                            <IconButton onClick={() => setShowImage(false)} style={{ ...styles.BlockRound, width: 36, height: 36, backgroundColor: global.theme_black, alignSelf: 'flex-end' }}>
                                <FontAwesomeIcon style={{ width: 20, height: 20, color: global.theme_white }} icon={faXmark} />
                            </IconButton>
                        </div>
                    </div>
                    <img src={global.ads + '/' + props.full.image} style={{ width: '100%', height: '100%', minHeight: '100%', maxWidth: 1000, maxHeight: 800, aspectRatio: 1.0 }} />
                </Dialog >
                : null}

        </div>
    )
}

export const FormError = (props) => {
    const isSmall = useMediaQuery({ maxWidth: global.device_small });
    const height = 50;

    return (
        props.error != '' ?
            <div style={{ ...styles.Block, height: height, backgroundColor: global.theme_red, borderRadius: 20, marginTop: 20 }}>
                <div style={{ ...styles.BlockRowRaw }}>
                    {isSmall ? null :
                        <FontAwesomeIcon style={{ height: 18, color: global.theme_white }} icon={faWarning} />
                    }
                    <p style={{ ...styles.TextSmall, color: global.theme_white, marginLeft: 10, marginRight: 10, textAlign: isSmall ? 'center' : 'left' }}>{props.error}</p>
                </div>
            </div>
            :
            <div style={{ ...styles.Block, height: height, borderRadius: 20, marginTop: 20 }}></div>

    );
}

export const FormOK = (props) => {
    const isSmall = useMediaQuery({ maxWidth: global.device_small });
    const height = 50;

    return (
        props.text != '' ?

            <div style={{ ...styles.Block, height: height, backgroundColor: global.theme_dark_green, borderRadius: 20, marginTop: 20 }}>
                <div style={{ ...styles.BlockRowRaw }}>
                    {isSmall ? null :
                        <FontAwesomeIcon style={{ height: 18, color: global.theme_white }} icon={faInfoCircle} />
                    }
                    <p style={{ ...styles.TextSmall, color: global.theme_white, marginLeft: 10, marginRight: 10, textAlign: isSmall ? 'center' : 'left' }}>{props.text}</p>
                </div>
            </div>
            :
            <div style={{ ...styles.Block, height: height, borderRadius: 20, marginTop: 20 }}></div>
    );
}

export const DialogHelp = (props) => {
    /*
            USAGE:
            <DialogHelp lang={lang} func={DialogHelpResult.bind(this)} />

            const DialogHelpResult = (value) => {   
            }

            */

    const [showOK, setShowOK] = useState(false);
    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState('');
    const [text, setText] = useState('');

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    let { func } = props;
    var lang = props.lang;

    useEffect(() => {

        return () => {
        };

    }, []);

    const db_send = async () => {
        if (isBusy == false) {
            setBusy(true);
            setError('');
            try {
                const response = await fetch(
                    global.db_url + 'help_send', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        salon_id: props.user.id,
                        text: text
                    })
                })

                const json = await response.json();
                if (json.ok > 0) {
                    if (json.sended == true) {
                        setShowOK(true);
                    } else {
                        setError(lang.help_error);
                    }
                } else {
                    setError(lang.help_error);
                }
                setBusy(false);
            } catch (error) {
                console.log(error);
                setBusy(false);
            }
        }
    }


    const Press = () => {
        func(false);
    }

    const Send = () => {
        setError('');
        if (text.trim() != '') {
            db_send();
        }

    }

    const ShowOKPress = (value) => {
        setShowOK(false);
        Press();
    }

    return (
        <Dialog open={true} fullScreen={isSmall ? true : false} maxWidth={'lg'} style={{ marginLeft: isSmall ? 0 : global.menu_width - 40 }}>
            <div style={{ ...styles.BlockRow, background: global.theme_dialog_back, height: 50, justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ width: '100%', paddingLeft: 10 }}>
                    <p style={{ ...styles.TextLarge, color: global.theme_dialog_label }}>{isSmall ? '' : lang.help_label}</p>
                </div>
                <div style={{ width: 50 }}>
                    <IconButton onClick={() => Press(false, false)} style={{ ...styles.BlockRound, width: 30, height: 30, backgroundColor: global.theme_black, alignSelf: 'flex-end' }}>
                        <FontAwesomeIcon style={{ width: 20, height: 20, color: global.theme_white }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>

            <div style={{ ...styles.Block, width: isSmall ? '100%' : 800, paddingTop: 20, paddingBottom: 10 }}>
                <div style={{ ...styles.Block, width: '95%' }}>
                    <p style={{ ...styles.TextSmall, marginTop: 0, textAlign: 'center', fontWeight: '600' }}>{isSmall ? lang.help : lang.help_text}</p>
                    <p style={{ ...styles.TextSmall, marginTop: 5, textAlign: 'center' }}>{lang.help_mobil}</p>
                    <div style={{ ...styles.Block }}>
                        <p style={{ ...styles.TextTiny, marginTop: 20, textAlign: 'center' }}>{isSmall ? lang.help_input_mobil : lang.help_input}</p>
                        <TextField
                            value={text}
                            onInput={e => setText(e.target.value)}
                            size="small"
                            multiline={true}
                            rows={4}
                            autoCapitalize="off"
                            autoComplete="off"
                            autoCorrect="off"
                            style={{ width: '100%' }}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                }
                            }}
                            InputProps={{
                                style: { backgroundColor: global.theme_white },
                            }}
                            variant="outlined"
                        />
                    </div>

                    <div style={{ ...styles.Block, height: isSmall ? 70 : 50 }}>
                        {isBusy == true ?
                            <CircularProgress size={26}></CircularProgress>
                            :
                            <FormError error={error} />
                        }
                    </div>
                </div>
            </div>

            <div style={{ ...styles.Block, paddingBottom: 10 }}>
                <div style={{ ...isSmall ? styles.Block : styles.BlockRowRaw }}>
                    <Button onClick={() => Send()} style={{ ...styles.ButtonGreen, margin: 10 }}>{lang.send}</Button>
                    <Button onClick={() => Press()} style={{ ...styles.ButtonBack, margin: 10 }}>{lang.close}</Button>
                </div>
            </div>

            {showOK == true ?
                <ShowOK func={ShowOKPress.bind(this)} />
                : null}

        </Dialog >
    );
}

